import React, {useState,useEffect} from 'react'
import { getActivityLanguageById, updateActivity } from '../../services/xevalApi.service';
import MultipleValueInput from '../MultipleValueInput/MultipleValueInput';
import ContainedLoading from '../Loading/ContainedLoading';
import StringTranslation from './ActivityTranslations/StringTranslation';
import FeedbackTranslations from './ActivityTranslations/FeedbackTranslations';
import OptionTranslations from './ActivityTranslations/OptionTranslations';
import TargetTranslations from './ActivityTranslations/TargetTranslations';
import useSortOptionsTargets from '../../hooks/useSortOptionsTargets';
import { CircularProgress } from '@mui/material';
import { StyledActivityCreationXCard, StyledActivityEditionContainer, StyledContainerPreviewSpinner, StyledMarginContent, StyledXCard } from '../../Styled-Components';
import _ from 'lodash';
import { AVAILABLE_TRANSLATION_LANGUAGES_BY_AI } from '../../CONSTANTS';
import useTranslations from '../../hooks/useTranslations';
import CustomTabs from '../CustomTabs/CustomTabs';

const ActivityEditTranslations = ({
    languageSelected, 
    activityInfo,
    setActivityInfo,
    setActivityTemplateTranslation,
    activityTemplateTranslation,
    activityTranslatedByIA,
    setActivityTranslation,
    setIsActivityTranslationUpdated,
    getIATranslations,
    iaLoadingSuggestions,
    setIaLoadingSuggestions,
    setActivityTranslatedByIA, 
    charCount,
    setCharCount
    
}) => {
    const [loading, setLoading] = useState(false);
    //Activity Translated
    const [showIsbn, setShowIsbn] = useState(true)
    const [, sortOptionsAndTargets] = useSortOptionsTargets();
    const { getCardTitle, handleRequestUniqueTranslation } = useTranslations();

    const [activityTemplateTranslationCopy, setActivityTemplateTemplateTranslationCopy] = useState(undefined)
    const [infoTranslationTabSelected, setInfoTranslationTabSelected] = useState('DATA');

    // Get activity lang checked
    useEffect(() => {
        if(AVAILABLE_TRANSLATION_LANGUAGES_BY_AI.includes(languageSelected?.value)) getIATranslations(languageSelected.value);
        // Get Activity Language
        setLoading(true)
        if(activityInfo?.available_languages.length > 0 && languageSelected?.value) {
            getActivityLanguageById(activityInfo.id,languageSelected?.value).then(res => {
                let newData = {...res}
                // newData = sortOptionsAndTargets(newData)
                // newData = sortOptions(newData)
                newData.available_languages = []
                newData["translatable_fields"] = ["name","title","text","options","targets","feedbacks"]

                setActivityTemplateTranslation(newData);
                setActivityTemplateTemplateTranslationCopy(newData);
            }).catch(err =>  
                console.log("Err while getting activity language",err
            )).finally(() => {
                setLoading(false)
            });
        }
    }, [languageSelected]);

    useEffect(() => {
        setActivityTranslation(activityTemplateTranslation)
    }, [activityTemplateTranslation]);
  


    const handleIsbnChange = async (e, keyToEdit, action, value) => {
        setShowIsbn(prevState => (!prevState))

        let newIsbnArray = activityTemplateTranslation?.isbn || []
        let activityData = {...activityInfo}
        
        if (action?.toLowerCase() === 'add') newIsbnArray.push(value)
        if (action?.toLowerCase() === 'remove') newIsbnArray.splice(value, 1)

        activityData.isbn = newIsbnArray

        await updateActivity(activityTemplateTranslation.id, activityData)
            .then(data => {
                const savedIsbn = data?.isbn || []

                setActivityTemplateTranslation(prevState => ({
                    ...prevState,
                    isbn: savedIsbn
                }))

                setActivityInfo(prevState => ({
                    ...prevState,
                    isbn: savedIsbn
                }))
            })
            .catch(error => console.error(error))
            .finally(() => setShowIsbn(prevState => (!prevState)))
    }

    const getTabs = () => {
        const tabs = []
        if(process.env?.REACT_APP_CLIENT === 'MHE') tabs.push('DATA')
        activityInfo?.translatable_fields?.map((translatableField,i) => {
            if(typeof activityInfo[translatableField] === 'string' && activityInfo[translatableField] !== ''){
                tabs.push(getCardTitle(translatableField).toUpperCase())
            }else if (activityInfo[translatableField]?.length > 0){
                tabs.push(getCardTitle(translatableField).toUpperCase())
            }
        })
        return tabs
    }

    const requestNewTranslation = async (translatableField, position = null) => {
        setIaLoadingSuggestions(true)
        await handleRequestUniqueTranslation(translatableField, languageSelected.value, activityInfo, activityTranslatedByIA, position).then((result) => {
            setActivityTranslatedByIA(result)
        }).catch((err) => {
            console.log("Error updating translation")
        }).finally(() => {
            setIaLoadingSuggestions(false)
        });
    }

    if(loading) return (
        <StyledActivityEditionContainer>
            <StyledContainerPreviewSpinner>
                <CircularProgress color='primary' size={'50px'} style={{padding: '10px'}}/>
            </StyledContainerPreviewSpinner>
        </StyledActivityEditionContainer>
    )


    return (
        <StyledActivityEditionContainer>
            <div style={{overflowY: 'auto', height: 'auto'}}>
                <CustomTabs
                    tabs={getTabs()}
                    setTabSelected={setInfoTranslationTabSelected}
                
                />
                {/* SPECIFIC FOR MHE */}
                {process.env?.REACT_APP_CLIENT === 'MHE' && 
                infoTranslationTabSelected === 'DATA' && 
                    <StyledActivityCreationXCard
                        isCollapsable={false}
                        // backgroundHeader={true}
                        isCollapsed={false}
                        title={`Editing language: ${languageSelected.value}`}
                        // title='Data'
                    >
                        <StyledMarginContent>
                            {/* ISBNs */}
                            {activityTemplateTranslation && showIsbn ? (
                                <MultipleValueInput 
                                    labelName='All ISBNs: '
                                    keyToEdit='isbn'
                                    data={activityTemplateTranslation}
                                    editData={handleIsbnChange}
                                />
                            ) : (
                                <ContainedLoading />
                            )}
                        </StyledMarginContent>
                    </StyledActivityCreationXCard>
                }
            {activityTemplateTranslation?.translatable_fields.map((translatableField,index) =>
                <div key={index}>
                    {/* STRINGS FIELDS */}
                    {typeof activityInfo[translatableField] === 'string' &&
                        activityInfo[translatableField] !== null &&
                        (infoTranslationTabSelected === 'NAME' && translatableField === 'name' ||
                        infoTranslationTabSelected === 'HEADER' && translatableField === 'title' ||
                        infoTranslationTabSelected === 'CONTENT' && translatableField === 'text') &&  
                        <StringTranslation
                            setTranslationUpdated={setIsActivityTranslationUpdated} 
                            activityInfo={{...activityInfo}}
                            activityTranslatedByIA={activityTranslatedByIA}
                            activityTranslated={{...activityTemplateTranslation}}
                            setActivityTranslated={setActivityTemplateTranslation}
                            translatableField={translatableField}
                            languageSelected={languageSelected.value}
                            getTitle={getCardTitle}
                            iaLoadingSuggestions={iaLoadingSuggestions}
                            requestNewTranslation={requestNewTranslation}
                            charCount={charCount}
                            setCharCount={setCharCount}
                        />
                    }
                    {/* FEEDBACKS */}
                    {translatableField === 'feedbacks' && 
                    activityInfo[translatableField] !== null &&
                    activityTemplateTranslation[translatableField] !== null &&
                    activityTemplateTranslation[translatableField].length > 0 &&
                    infoTranslationTabSelected === 'GLOBAL FEEDBACK' && 
                        <FeedbackTranslations
                            setTranslationUpdated={setIsActivityTranslationUpdated}     
                            languageSelected= {languageSelected.value}
                            activityInfo={{...activityInfo}}
                            activityTranslated={{...activityTemplateTranslation}}
                            setActivityTranslated={setActivityTemplateTranslation}
                            getTitle={getCardTitle}
                            translatableField={translatableField}
                            activityTranslatedByIA={activityTranslatedByIA}
                            iaLoadingSuggestions={iaLoadingSuggestions}
                            requestNewTranslation={requestNewTranslation}
                        />
                    }
                    {/* OPTIONS AND TARGETS */}
                    {/* OPTIONS */}
                    {translatableField === 'options' &&
                    activityInfo[translatableField] !== null &&
                    activityTemplateTranslation[translatableField] !== null && 
                    activityTemplateTranslation[translatableField].length > 0 &&
                    infoTranslationTabSelected === 'OPTIONS' &&
                        <OptionTranslations
                            setTranslationUpdated={setIsActivityTranslationUpdated}    
                            activityInfo={{...sortOptionsAndTargets(activityInfo)}}
                            activityTranslated={{...sortOptionsAndTargets(activityTemplateTranslation)}}
                            setActivityTranslated={setActivityTemplateTranslation}
                            languageSelected={languageSelected.value}
                            getTitle={getCardTitle}
                            translatableField={translatableField}
                            activityTranslatedByIA={activityTranslatedByIA}
                            iaLoadingSuggestions={iaLoadingSuggestions}
                            requestNewTranslation={requestNewTranslation}
                            charCount={charCount}
                            setCharCount={setCharCount}
                           
                        />
                    }
                    {/* TARGETS */}
                    {translatableField === 'targets' && 
                    activityInfo[translatableField] !== null &&
                    activityTemplateTranslation[translatableField] !== null &&
                    activityTemplateTranslation[translatableField].length > 0 &&
                    infoTranslationTabSelected === 'TARGETS' &&
                        <TargetTranslations
                            setTranslationUpdated={setIsActivityTranslationUpdated}     
                            activityInfo={{...sortOptionsAndTargets(activityInfo)}}
                            activityTranslated={{...sortOptionsAndTargets(activityTemplateTranslation)}}
                            setActivityTranslated={setActivityTemplateTranslation}
                            languageSelected={languageSelected.value}
                            getTitle={getCardTitle}
                            translatableField={translatableField}
                            activityTranslatedByIA={activityTranslatedByIA}
                            iaLoadingSuggestions={iaLoadingSuggestions}
                            requestNewTranslation={requestNewTranslation}
                            charCount={charCount}
                            setCharCount={setCharCount}
                        />
                    }
                    
                </div> 

            )}
        </div>
    </StyledActivityEditionContainer>
    )
}

export default ActivityEditTranslations
