import ACTIVITY_TYPE from '../../default/activities/Activities'

export const SEK_ACTIVITIES_INIT = 
[{"es-ES": [
    {
        "type": "single-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Elige la respuesta correcta",
        "text": "La primera técnica que aplica Hellen proviene de la planificación de la lección. ¿Con qué crees que está relacionada?",
        "options": [
            {       
                "id": 0, "is_right": "FALSE", 
                "title": "Descomponer las lecciones en sus componentes más pequeños",
                "feedback": "Oops, algo falta. La planificación adecuada de las clases permite a los alumnos mantener mejor la atención y la voluntad de aprender", 
            },
            { 
                "id": 0, "is_right": "FALSE", 
                "title": "Planifica de lo específico a lo general",
                "feedback": "Oops, falta algo. Planificar las clases adecuadamente permite a los alumnos mantener mejor la atención y la voluntad de aprender.",
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "Todos ellos",
                "feedback": "La planificación adecuada de las clases permite a los alumnos mantener mejor la atención y la voluntad de aprender.",
            },
        ],   
    },
    {
        "type": "multiple-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Elige las respuestas correctas",
        "text": "Después de trabajar con la Vía Láctea en clase, quieres hacer preguntas de reflexión.",
        "options": [
            {       
                "id": 0, "is_right": "TRUE", 
                "title": "Descomponer las lecciones en sus componentes más pequeños",
                "feedback": "Bien hecho. Estas preguntas permiten a los estudiantes pensar en su propio aprendizaje, reforzarlo y pensar en nuevos intereses, para abrir nuevas oportunidades de aprendizaje.", 
            },
            { 
                "id": 0, "is_right": "FALSE", 
                "title": "¿Qué has aprendido sobre la Vía Láctea?",
                "feedback": "Casi. Recuerda: Estas preguntas permiten a los estudiantes pensar en su propio aprendizaje, reforzarlo y pensar en nuevos intereses, para abrir nuevas oportunidades de aprendizaje.",
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "¿Cómo lo has aprendido?",
                "feedback": "Bien hecho. Estas preguntas permiten a los alumnos pensar en su propio aprendizaje, reforzarlo y pensar en nuevos intereses, para abrir nuevas oportunidades de aprendizaje.",
            },
        ]
    },
    {
        "type": "true-false",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Esta pregunta es verdadera o falsa",
        "text": "¿Podemos utilizar pequeñas frustraciones del alumno para iniciarle en el uso de estrategias de regulación del estrés?",
        "options": [
            {
                "id": 0,
                "feedback": "Es bueno utilizar las pequeñas frustraciones del alumno para iniciarlo en el uso de estrategias de regulación del estrés.",
                "image": "false.svg",
                "is_right": "FALSE",
                "title": "Falso"
            },
            {
                "id": 0,
                "feedback": "Sí, es bueno utilizar pequeñas frustraciones del alumno para iniciarlo en el uso de estrategias de regulación del estrés.",
                "image": "true.svg",
                "is_right": "TRUE",
                "title": "Verdadero"
            }
        ]
    },
    {
        "type": "deduction",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Arrastre las palabras a la posición correcta en la frase.",
        "text": "La mejor manera de entender la evaluación es pensar en su {2} . La evaluación sumativa permite {1} los resultados de los aprendizajes. La evaluación formativa nos ayuda a {3} al alumno en el proceso de enseñanza-aprendizaje.",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE", "title": "validar"},
            { "id": 0, "target_order": 2, "is_right": "TRUE", "title": "finalidad"},
            { "id": 0, "target_order": 3, "is_right": "TRUE", "title": "orientar"},
        ],
        "targets": [
            { "id": 0, "order": 1 },
            { "id": 0, "order": 2 },
            { "id": 0, "order": 3 }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, vuelve a intentarlo para ver la diferencia entre evaluación formativa y sumativa." },
            { "success_percentage": 100, "text": "Bien hecho. Pensar en la finalidad de la evaluación nos ayudará a diseñarla de un modo coherente." }
        ]
    },
    {
        "type": "fill-the-blanks",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Completa los espacios en blanco con las siguientes palabras: calificar, evaluación, profesores",
        "text": "Llamamos {1} al procedimiento de cuantificación y comunicación de los resultados del aprendizaje. La {2} es algo más, es el procedimiento de análisis del aprendizaje para su regulación por parte de los alumnos y de los {3} .",
        "targets": [
            { "accepted_values": ["calificar"], "id": 0, "order": 1 },
            { "accepted_values": ["evaluación"], "id": 0, "order": 2 },
            { "id": 0, "accepted_values": ["profesores"], "order": 3 },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Hacer de la evaluación una herramienta de aprendizaje significa asumir que evaluar no es lo mismo que calificar, y que aprender no es lo mismo que aprobar exámenes." },
            { "success_percentage": 100, "text": "Hacer de la evaluación un instrumento de aprendizaje supone asumir que evaluar no es lo mismo que examinar o calificar, y aprender no es lo mismo que aprobar exámenes.  " }
        ]
    },
    {
        "type": "short-question",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Elige la pregunta correcta a la pregunta planteada",
        "text": "Reutilizar un objeto o material, ya sea para el mismo fin que tenía originalmente o para uno nuevo.",
        "options": [
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "¿Qué es el reciclaje?",
                "feedback": "Debemos empezar por reducir los residuos que generamos y que acaban en los vertederos en la mayoría de los casos, como los plásticos."
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "¿Qué es la reutilización?",
                "feedback": "Como hemos presentado, todo puede tener múltiples usos, sólo tenemos que pensar qué necesitamos y cómo podemos hacerlo con los recursos disponibles a nuestro alrededor"
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "¿Qué es reducir?",
                "feedback": "Debemos empezar por reducir los residuos que generamos y que acaban en los vertederos en la mayoría de los casos, como los plásticos."
            }
        ],
    },
    {
        "type": "opinion-scale",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Estás de acuerdo con...",
        "text": "Las tareas auténticas son aquellas que promueven que los estudiantes construyan sus respuestas y las utilicen para dar respuesta a situaciones del mundo real.",
        "options": [
            {
                "id": 0,
                "is_right": "FALSE",
                "image": "Disagree_Colour.svg",
                "title": "En desacuerdo",
                "order": 2
            },
            {
                "id": 0,
                "is_right": "FALSE",
                "image": "Neutral_Colour.svg",
                "title": "Neutro",
                "order": 3
            },
            {
                "id": 0,
                "is_right": "TRUE",
                "image": "Agree_Colour.svg",
                "title": "De acuerdo",
                "order": 4
            }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "¿No quieres reconsiderarlo? Con las tareas auténticas podremos observar qué han aprendido realmente nuestros alumnos." },
            { "success_percentage": 100, "text": "Así es. De esta forma vamos a poder observar qué han aprendido realmente nuestros alumnos." }
        ]
    },
    {
        "type": "correspondence",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Clasifique los siguientes elementos en dos grupos",
        "text": "¿A qué etapa corresponde cada situación?",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE", "title": "Edad escolar" },
            { "id": 0, "target_order": 1, "is_right": "FALSE", "title": "Adolescencia" },
            { "id": 0, "target_order": 2, "is_right": "TRUE", "title": "Edad escolar" },
            { "id": 0, "target_order": 2, "is_right": "FALSE", "title": "Adolescencia" },
        ],
        "targets": [
            { "id": 0, "order": 1, "title": "¿Cómo puedo ser bueno?" },
            { "id": 0, "order": 2, "title": "Sentimiento de inferioridad" }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, inténtalo de nuevo. Los adolescentes pueden empezar a sentirse confusos o inseguros de sí mismos y de cómo encajan en la sociedad." },
            { "success_percentage": 50, "text": "Algunas buenas respuestas aquí. Los adolescentes pueden empezar a sentirse confusos o inseguros de sí mismos y de cómo encajan en la sociedad." },
            { "success_percentage": 100, "text": "Bien hecho. Los adolescentes pueden empezar a sentirse confusos o inseguros de sí mismos y de cómo encajan en la sociedad." }
        ]
    },
    {
        "type": "order-chronology",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Ordena las imágenes...",
        "text": "Ordena, desde una lógica temporal estos tres tipos de evaluación:",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "is_right": "FALSE" },
            { "id": 0, "target_order": 3, "is_right": "FALSE" }
        ],
        "targets": [
            { "id": 0, "order": 1 },
            { "id": 0, "order": 2 },
            { "id": 0, "order": 3 }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Ohh. Es más habitual realizarlo con otro tipo de secuencia temporal. Inténtalo de nuevo." },
            { "success_percentage": 100, "text": "¡Bien hecho! Esto nos permite conocer qué sabe el alumno, orientar durante el proceso y, finalmente, validar los resultados de aprendizaje." }
        ]
    },
    {
        "type": "word-search",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Busca palabras relacionadas con la evaluación y señálalas. ¿Qué define a la evaluación?",
        "text": "",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["criterios", "rúbricas", "tareas", "desempeño"] },
        ],
        "props": {
            "time": 120, "background_image": { }
        },
        "matrix": {
            "data": [],
            "props": {
                "alphabet": "EN",
                "rules": {
                    "allow_diagonal": false,
                    "allow_horizontal": true,
                    "allow_inverse": false,
                    "allow_vertical": true
                },
                "size": {
                    "height": 10,
                    "width": 10
                }
            },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. ¿Quieres volver a probar?" },
            { "success_percentage": 50, "text": "Mmmm. ¿Quieres volver a probar?" },
            { "success_percentage": 100, "text": "¡Enhorabuena! La evaluación tiene puntos muy importantes a tener en cuenta." }
        ]
    },
    {
        "type": "word-catcher",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Busca estos... ¿Cómo debe de ser la evaluación?",
        "targets": [
            {
                "id": 0,
                "order": 1,
                "accepted_values": ["equilibrada", "diversa"],
                "unaccepted_values": ["injusta", "hostil"]
            },
        ],
        "background": null,
        "props": {
            "time": 120,
            "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
            "word_color": '#eeeeee',
            "speed": { "min": 0.5, "max": 1.5 },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. Vuelve a intentarlo" },
            { "success_percentage": 50, "text": "Mmm. Han faltado algunas palabras. ¡Tú puedes!" },
            { "success_percentage": 100, "text": "Bien hecho. Son conceptos importantes para realizar una buena evaluación." }
        ]
    },
    {
        "type": "seek-and-find",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Indique donde está el corazón en la imagen.",
        "image": "@@@dam:@image@93dc44b7-f18b-4626-b882-e303a02b37d7@84d97db0-5efa-497c-be13-9087e9c1f146@@@",
        "options": [
            {
                "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 120
                }
            },
            {
                "id": 0, "is_right": "TRUE", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 60
                }
            }
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "area": {
                    "x": 518, "y": 478, "radius": null
                }
            }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "text-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Haga clic en las tarjetas que responden a la pregunta siguiente",
        "text": "",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Voluntario" },
            { "id": 0, "is_right": "TRUE", "title": "Tocar un instrumento" },
            { "id": 0, "is_right": "FALSE", "title": "Beber" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. Vuelve a intentarlo" },
            { "success_percentage": 50, "text": "Mmm. Vuelve a intentarlo. ¡Tú puedes!" },
            { "success_percentage": 100, "text": "Bien hecho. ¡Enhorabuena!" }
        ]
    },
    {
        "type": "image-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Haga clic en las tarjetas que responden a la pregunta siguiente",
        "text": "¿Cuál de las siguientes imágenes no tiene nada que ver con las demás?",
        "options": [
            {
                "id": 0, "is_right": "FALSE",
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "is_right": "FALSE",
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "is_right": "TRUE",
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. Vuelve a intentarlo" },
            { "success_percentage": 50, "text": "Mmm. Vuelve a intentarlo. ¡Tú puedes!" },
            { "success_percentage": 100, "text": "Bien hecho. ¡Enhorabuena!" }
        ]
    },
    {
        "type": "card-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Mueve el icono de la parte inferior hacia la tarjeta con tu respuesta a lo siguiente:",
        "text": "Match the moment during the lesson with the objective to use group work.",
        "background": "PIRATES",
        "options": [
            {
                "id": 0,
                "is_right": "FALSE",
                "feedback": "Hmmm vuelve a intentarlo",
                "title": "Antes de la lección"
            },
            {
                "id": 0,
                "is_right": "TRUE",
                "feedback": "Perfecto",
                "title": "Durante la lección"
            },
            {
                "id": 0,
                "is_right": "FALSE",
                "feedback": "Hmmm vuelve a intentarlo",
                "title": "Después de la lección"
            }
        ]
    },
    {
        "type": "image-pairing",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Une la primera fila de imágenes con la imagen correspondiente de la segunda fila.",
        "text": "",
        "options": [
            {
                "id": 0, "target_order": 1, "is_right": "TRUE",
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "target_order": 2, "is_right": "TRUE",
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "target_order": 3, "is_right": "TRUE",
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "order": 2,
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "order": 3,
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "text-pairing",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Une la primera fila de texto con el texto correspondiente de la segunda fila.",
        "text": "",
        "options": [
            {
                "id": 0, "target_order": 1, "is_right": "TRUE",
                "title": "",
            },
            {
                "id": 0, "target_order": 2, "is_right": "TRUE",
                "title": "",
            },
            {
                "id": 0, "target_order": 3, "is_right": "TRUE",
                "title": "",
            },
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "title": "",
            },
            {
                "id": 0, "order": 2,
                "title": "",
            },
            {
                "id": 0, "order": 3,
                "title": "",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": ACTIVITY_TYPE.FLASH_CARDS,
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Swipe Swipe",
        "text": "<p>Swipe right true, swipe left false</p>",
        "image": '',
        "options": [
            {
                "id": 0,
                "text": "First description Card",
                "image": "@@@dam:@image@9655d75c-e087-4273-9c28-cd703bae0720@f16a9d9c-7d41-4c1b-89ae-07c729245046@@@",
                "title": "First card",
                "is_right": "TRUE",
                "target_order": 0,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d75c-e087-4273-9c28-cd703bae0720@f16a9d9c-7d41-4c1b-89ae-07c729245046@@@"
            },
            {
                "id": 0,
                "text": "Second description Card",
                "image": "@@@dam:@image@9655d7b8-da7d-4aea-86b6-7275138c7b3a@383eb435-6179-4e90-869e-8f018b773438@@@",
                "title": "Second card",
                "is_right": "TRUE",
                "target_order": 1,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d7b8-da7d-4aea-86b6-7275138c7b3a@383eb435-6179-4e90-869e-8f018b773438@@@"
            },
            {
                "id": 0,
                "text": "Three description Card",
                "image": "@@@dam:@image@9655d822-8854-4ec8-85ce-c1ec76f178fe@1cb452ba-5066-48bc-be84-197de3b3c5c0@@@",
                "title": "Three Card",
                "is_right": "FALSE",
                "target_order": 2,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d822-8854-4ec8-85ce-c1ec76f178fe@1cb452ba-5066-48bc-be84-197de3b3c5c0@@@"
            },
            {
                "id": 0,
                "text": "Four description Card",
                "image": "@@@dam:@image@9655d86f-710b-4fb9-8550-e99b1ebdf5ea@e8692cf9-3f1d-4697-9165-65bfd4bf3e59@@@",
                "title": "Four Card",
                "is_right": "TRUE",
                "target_order": 3,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d86f-710b-4fb9-8550-e99b1ebdf5ea@e8692cf9-3f1d-4697-9165-65bfd4bf3e59@@@"
            }
        ],
        "feedbacks": [
            {
                "text": "Sorry, your answer is not correct.",
                "success_percentage": 0
            },
            {
                "text": "Well done!",
                "success_percentage": 3
            }
        ]
    },
    {
        "type": ACTIVITY_TYPE.FROGGY_JUMP,
        "status": "DRAFT",
        "level": 1,
        "version": 1,
        "title": "Froggy Jump",
        "text": "<p>Choose the correct answwer</p>",
        "image": "",
        "props": {
            "time": null,
            "background_image": { }
        },

        "units": [],
        "matrix": null,
        "weight": 100,
        "options": [
            {
                "id": 3476,
                "area": null,
                "text": null,
                "image": null,
                "title": "Azul",
                "feedback": null,
                "is_right": "TRUE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3480,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2=4",
                "feedback": null,
                "is_right": "TRUE",
                "image_url": null,
                "target_order": 2
            },
            {
                "id": 3478,
                "area": null,
                "text": null,
                "image": null,
                "title": "Amarillo",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3477,
                "area": null,
                "text": null,
                "image": null,
                "title": "Rojo",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3479,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2=22",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 2
            },
            {
                "id": 3481,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2=2",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 2
            }
        ],
        "targets": [
            {
                "id": 3474,
                "area": {
                    "x": null,
                    "y": null,
                    "radius": null
                },
                "text": null,
                "order": 2,
                "title": "¿Cuánto es 2+2?",
                "image_url": null,
                "accepted_values": null,
                "unaccepted_values": null
            },
            {
                "id": 3473,
                "area": {
                    "x": null,
                    "y": null,
                    "radius": null
                },
                "text": null,
                "order": 1,
                "title": "De qué color es el cielo?",
                "image_url": null,
                "accepted_values": null,
                "unaccepted_values": null
            },
        ],

        "feedbacks": [
            {
                "text": "Frong answer",
                "success_percentage": 0
            },
            {
                "text": "RIBBIT!!",
                "success_percentage": 100
            }
        ],

    }
]
},


{"en-EN": [
    {
        "type": "single-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Choose the right answer",
        "text": "The first technique Hellen applies comes from lesson planning. What do you think it's related to?",
        "options": [
            {       
                "id": 0, "is_right": "FALSE", 
                "title": "Break lessons down into their smallest component parts",
                "feedback": "Oops, something is missing. Planning lessons properly allows students to better maintain attention and a willingness to learn.", 
            },
            { 
                "id": 0, "is_right": "FALSE", 
                "title": "Plan from the specific to the general",
                "feedback": "Oops, something is missing. Planning lessons properly allows students to better maintain attention and a willingness to learn.",
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "All of them",
                "feedback": "Planning lessons properly allows students to better maintain attention and a willingness to learn.",
            },
        ],   
    },
    {
        "type": "multiple-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Choose the correct answers",
        "text": "After working on the Milky Way in class, you want to ask reflection questions.",
        "options": [
            {       
                "id": 0, "is_right": "TRUE", 
                "title": "What have you learned about the Milky Way?",
                "feedback": "Well done. These questions allow students to think about their own learning, to reinforce it and to think about new interests, to open up new learning opportunities.", 
            },
            { 
                "id": 0, "is_right": "FALSE", 
                "title": "How many planets are there in the solar system?",
                "feedback": "Almost. Remember: These questions allow students to think about their own learning, to reinforce it and to think about new interests, to open up new learning opportunities.",
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "How did you learn it?",
                "feedback": "Well done. These questions allow students to think about their own learning, to reinforce it and to think about new interests, to open up new learning opportunities.",
            },
        ]
    },
    {
        "type": "true-false",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "This question is true or false",
        "text": "Can we use small frustrations of the student to initiate him/her in the use of stress regulation strategies?",
        "options": [
            {
                "id": 0,
                "feedback": "Yes, is good to use small frustrations of the student to initiate him/her in the use of stress regulation strategies.",
                "image": "false.svg",
                "is_right": "FALSE",
                "title": "False"
            },
            {
                "id": 0,
                "feedback": "Is good to use small frustrations of the student to initiate him/her in the use of stress regulation strategies. Try again.",
                "image": "true.svg",
                "is_right": "TRUE",
                "title": "True"
            }
        ]
    },
    {
        "type": "deduction",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Drag the words to the correct position in the sentence.",
        "text": "A lot of {1} is needed to ensure that {2} works well, allowing its two formats to capitalize on each other’s {3} .",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE", "title": "planning"},
            { "id": 0, "target_order": 2, "is_right": "TRUE", "title": "hybrid"},
            { "id": 0, "target_order": 3, "is_right": "TRUE", "title": "strengths"},
        ],
        "targets": [
            { "id": 0, "order": 1 },
            { "id": 0, "order": 2 },
            { "id": 0, "order": 3 }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again and think about what hybrid learning needs." },
            { "success_percentage": 100, "text": "Well done. Transformation requires a lot of planning" }
        ]
    },
    {
        "type": "fill-the-blanks",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Fill the blanks with the following words: equal, responsibility, boundaries",
        "text": "The teacher-student relationship is not {1}. Teachers must take {2}  for establishing and maintaining appropriate professional {3} with their students. ",
        "targets": [
            { "accepted_values": ["equal"], "id": 0, "order": 1 },
            { "accepted_values": ["responsability"], "id": 0, "order": 2 },
            { "id": 0, "accepted_values": ["boundaries"], "order": 3 },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Try again. Think about boundaries." },
            { "success_percentage": 50, "text": "Some good answers here. Boundaries shape our relationships with students. They determine when we are available, where and why someone might see us." },
            { "success_percentage": 100, "text": "Well done. Boundaries shape our relationships with students. They determine when we are available, where and why someone might see us." }
        ]
    },
    {
        "type": "short-question",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Choose the correct question to the question posed",
        "text": "Reusing an object or material, either for the same purpose it originally had or for a new one.",
        "options": [
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "What is recycling?",
                "feedback": "We must start by reducing the waste that we generate and that ends up in landfills in the majority of cases, such as plastics."
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "What is reuse?",
                "feedback": "As we have presented, everything can have multiple uses, we just have to think what we need and how we can make it with the available resources around us."
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "What is reduce?",
                "feedback": "We must start by reducing the waste that we generate and that ends up in landfills in the majority of cases, such as plastics."
            }
        ],
    },
    {
        "type": "opinion-scale",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Do you agree with...",
        "text": "Good learners use a varied toolkit of learning methods and attitudes to make them resourceful.",
        "options": [
            {
                "id": 0,
                "is_right": "FALSE",
                "image": "Disagree_Colour.svg",
                "title": "Disagree",
                "order": 2
            },
            {
                "id": 0,
                "is_right": "FALSE",
                "image": "Neutral_Colour.svg",
                "title": "Neutral",
                "order": 3
            },
            {
                "id": 0,
                "is_right": "TRUE",
                "image": "Agree_Colour.svg",
                "title": "Agree",
                "order": 4
            }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. It is about the cognitive aspects of learning. Some other aspects are emotions, strategies and social relationships." },
            { "success_percentage": 100, "text": "Yeah. It is about the cognitive aspects of learning. Some other aspects are emotions, strategies and social relationships." }
        ]
    },
    {
        "type": "correspondence",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Classify the following elements into two groups",
        "text": "Which stage does each situation correspond to?",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE", "title": "School Age" },
            { "id": 0, "target_order": 1, "is_right": "FALSE", "title": "Adolescence" },
            { "id": 0, "target_order": 2, "is_right": "TRUE", "title": "School Age" },
            { "id": 0, "target_order": 2, "is_right": "FALSE", "title": "Adolescence" },
        ],
        "targets": [
            { "id": 0, "order": 1, "title": "How can I be good?" },
            { "id": 0, "order": 2, "title": "Feeling of inferiority" }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again. Teens may begin to feel confused or insecure about themselves and how they fit in to societiy." },
            { "success_percentage": 50, "text": "Some good answers here. Teens may begin to feel confused or insecure about themselves and how they fit in to societiy." },
            { "success_percentage": 100, "text": "Well done. Teens may begin to feel confused or insecure about themselves and how they fit in to societiy." }
        ]
    },
    {
        "type": "order-chronology",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Sort the images to...",
        "text": "Sort the images to get a Creative Commons license that requires reference to the original author and allows derivative works under the same license.",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "is_right": "FALSE" },
            { "id": 0, "target_order": 3, "is_right": "FALSE" }
        ],
        "targets": [
            { "id": 0, "order": 1 },
            { "id": 0, "order": 2 },
            { "id": 0, "order": 3 }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmm. Almost, but the order of the icons must be different." },
            { "success_percentage": 100, "text": "Well done. This is a good license for open educational resources." }
        ]
    },
    {
        "type": "word-search",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Look for these words: materials, language, activity, setting",
        "text": "",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["materials", "language", "activity", "setting"] },
        ],
        "props": {
            "time": 120, "background_image": { }
        },
        "matrix": {
            "data": [],
            "props": {
                "alphabet": "EN",
                "rules": {
                    "allow_diagonal": false,
                    "allow_horizontal": true,
                    "allow_inverse": false,
                    "allow_vertical": true
                },
                "size": {
                    "height": 10,
                    "width": 10
                }
            },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. Awareness of your own bias is the first step in supporting development." },
            { "success_percentage": 50, "text": "Mmm. Awareness of your own bias is the first step in supporting development." },
            { "success_percentage": 100, "text": "Well done. Awareness of your own bias is the first step in supporting development." }
        ]
    },
    {
        "type": "word-catcher",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "What should the evaluation look like?",
        "targets": [
            {
                "id": 0,
                "order": 1,
                "accepted_values": ["balanced", "diverse"],
                "unaccepted_values": ["penalising", "random"]
            },
        ],
        "background": null,
        "props": {
            "time": 120,
            "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
            "word_color": '#eeeeee',
            "speed": { "min": 0.5, "max": 1.5 },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. Try again." },
            { "success_percentage": 50, "text": "Mmm. A few words were missing. You can do it!" },
            { "success_percentage": 100, "text": "Well done. These are important concepts for a good assessment." }
        ]
    },
    {
        "type": "seek-and-find",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Indique donde está el corazón en la imagen.",
        "image": "@@@dam:@image@93dc44b7-f18b-4626-b882-e303a02b37d7@84d97db0-5efa-497c-be13-9087e9c1f146@@@",
        "options": [
            {
                "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 120
                }
            },
            {
                "id": 0, "is_right": "TRUE", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 60
                }
            }
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "area": {
                    "x": 518, "y": 478, "radius": null
                }
            }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "text-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Click on the cards that answer the question below.",
        "text": "Which are the best hobbies to mention when asked in a professional setting?",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Volunteer" },
            { "id": 0, "is_right": "TRUE", "title": "Play musical instrument" },
            { "id": 0, "is_right": "FALSE", "title": "Drink" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "image-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Click on the cards that answer the question below.",
        "text": "Which of the following images has nothing to do with the others?",
        "options": [
            {
                "id": 0, "is_right": "FALSE",
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "is_right": "FALSE",
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "is_right": "TRUE",
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "card-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Move the icon at the bottom towards the card with your answer to the following:",
        "text": "Match the moment during the lesson with the objective to use group work.",
        "background": "PIRATES",
        "options": [
            {
                "id": 0,
                "is_right": "FALSE",
                "feedback": "Hmmm, think about it.",
                "title": "Before starting a lesson"
            },
            {
                "id": 0,
                "is_right": "TRUE",
                "feedback": "Perfect!",
                "title": "During a lesson"
            },
            {
                "id": 0,
                "is_right": "FALSE",
                "feedback": "Hmmm, think about it.",
                "title": "After finishing a lesson"
            }
        ]
    },
    {
        "type": "image-pairing",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Pair up the first row of images with the appropriate image from the second row",
        "text": "",
        "options": [
            {
                "id": 0, "target_order": 1, "is_right": "TRUE",
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "target_order": 2, "is_right": "TRUE",
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "target_order": 3, "is_right": "TRUE",
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "order": 2,
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "order": 3,
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "text-pairing",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Pair up the first row of text with the appropriate image from the second row",
        "text": "",
        "options": [
            {
                "id": 0, "target_order": 1, "is_right": "TRUE",
                "title": "",
            },
            {
                "id": 0, "target_order": 2, "is_right": "TRUE",
                "title": "",
            },
            {
                "id": 0, "target_order": 3, "is_right": "TRUE",
                "title": "",
            },
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "title": "",
            },
            {
                "id": 0, "order": 2,
                "title": "",
            },
            {
                "id": 0, "order": 3,
                "title": "",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": ACTIVITY_TYPE.FLASH_CARDS,
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Swipe Swipe",
        "text": "<p>Swipe right true, swipe left false</p>",
        "image": '',
        "options": [
            {
                "id": 0,
                "text": "First description Card",
                "image": "@@@dam:@image@9655d75c-e087-4273-9c28-cd703bae0720@f16a9d9c-7d41-4c1b-89ae-07c729245046@@@",
                "title": "First card",
                "is_right": "TRUE",
                "target_order": 0,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d75c-e087-4273-9c28-cd703bae0720@f16a9d9c-7d41-4c1b-89ae-07c729245046@@@"
            },
            {
                "id": 0,
                "text": "Second description Card",
                "image": "@@@dam:@image@9655d7b8-da7d-4aea-86b6-7275138c7b3a@383eb435-6179-4e90-869e-8f018b773438@@@",
                "title": "Second card",
                "is_right": "TRUE",
                "target_order": 1,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d7b8-da7d-4aea-86b6-7275138c7b3a@383eb435-6179-4e90-869e-8f018b773438@@@"
            },
            {
                "id": 0,
                "text": "Three description Card",
                "image": "@@@dam:@image@9655d822-8854-4ec8-85ce-c1ec76f178fe@1cb452ba-5066-48bc-be84-197de3b3c5c0@@@",
                "title": "Three Card",
                "is_right": "FALSE",
                "target_order": 2,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d822-8854-4ec8-85ce-c1ec76f178fe@1cb452ba-5066-48bc-be84-197de3b3c5c0@@@"
            },
            {
                "id": 0,
                "text": "Four description Card",
                "image": "@@@dam:@image@9655d86f-710b-4fb9-8550-e99b1ebdf5ea@e8692cf9-3f1d-4697-9165-65bfd4bf3e59@@@",
                "title": "Four Card",
                "is_right": "TRUE",
                "target_order": 3,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d86f-710b-4fb9-8550-e99b1ebdf5ea@e8692cf9-3f1d-4697-9165-65bfd4bf3e59@@@"
            }
        ],
        "feedbacks": [
            {
                "text": "Sorry, your answer is not correct.",
                "success_percentage": 0
            },
            {
                "text": "Well done!",
                "success_percentage": 3
            }
        ]
    },
    {
        "type": ACTIVITY_TYPE.FROGGY_JUMP,
        "status": "DRAFT",
        "level": 1,
        "version": 1,
        "title": "Froggy Jump",
        "text": "<p>Choose the correct answwer</p>",
        "image": "",
        "props": {
            "time": null,
            "background_image": { }
        },

        "units": [],
        "matrix": null,
        "weight": 100,
        "options": [
            {
                "id": 3476,
                "area": null,
                "text": null,
                "image": null,
                "title": "Blue",
                "feedback": null,
                "is_right": "TRUE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3484,
                "area": null,
                "text": null,
                "image": null,
                "title": "XIMDEX is",
                "feedback": null,
                "is_right": "TRUE",
                "image_url": null,
                "target_order": 3
            },
            {
                "id": 3480,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2 is 4",
                "feedback": null,
                "is_right": "TRUE",
                "image_url": null,
                "target_order": 2
            },
            {
                "id": 3478,
                "area": null,
                "text": null,
                "image": null,
                "title": "Yellow",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3483,
                "area": null,
                "text": null,
                "image": null,
                "title": "GOOGLE is",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 3
            },
            {
                "id": 3482,
                "area": null,
                "text": null,
                "image": null,
                "title": "FACEBOOK is",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 3
            },
            {
                "id": 3477,
                "area": null,
                "text": null,
                "image": null,
                "title": "Red",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3479,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2 is 22",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 2
            },
            {
                "id": 3481,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2 is 2",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 2
            }
        ],
        "targets": [
            {
                "id": 3474,
                "area": {
                    "x": null,
                    "y": null,
                    "radius": null
                },
                "text": null,
                "order": 2,
                "title": "How much is 2 + 2?",
                "image_url": null,
                "accepted_values": null,
                "unaccepted_values": null
            },
            {
                "id": 3473,
                "area": {
                    "x": null,
                    "y": null,
                    "radius": null
                },
                "text": null,
                "order": 1,
                "title": "What color is the sky?",
                "image_url": null,
                "accepted_values": null,
                "unaccepted_values": null
            },
            {
                "id": 3475,
                "area": {
                    "x": null,
                    "y": null,
                    "radius": null
                },
                "text": null,
                "order": 3,
                "title": "Which is the best IT company?",
                "image_url": null,
                "accepted_values": null,
                "unaccepted_values": null
            }
        ],

        "feedbacks": [
            {
                "text": "Frong answer",
                "success_percentage": 0
            },
            {
                "text": "RIBBIT!!",
                "success_percentage": 100
            }
        ],

    }
]
},

// Add translations when available
{"uk-UA": [
    {
        "type": "single-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Choose the right answer",
        "text": "The first technique Hellen applies comes from lesson planning. What do you think it's related to?",
        "options": [
            {       
                "id": 0, "is_right": "FALSE", 
                "title": "Break lessons down into their smallest component parts",
                "feedback": "Oops, something is missing. Planning lessons properly allows students to better maintain attention and a willingness to learn.", 
            },
            { 
                "id": 0, "is_right": "FALSE", 
                "title": "Plan from the specific to the general",
                "feedback": "Oops, something is missing. Planning lessons properly allows students to better maintain attention and a willingness to learn.",
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "All of them",
                "feedback": "Planning lessons properly allows students to better maintain attention and a willingness to learn.",
            },
        ],   
    },
    {
        "type": "multiple-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Choose the correct answers",
        "text": "After working on the Milky Way in class, you want to ask reflection questions.",
        "options": [
            {       
                "id": 0, "is_right": "TRUE", 
                "title": "What have you learned about the Milky Way?",
                "feedback": "Well done. These questions allow students to think about their own learning, to reinforce it and to think about new interests, to open up new learning opportunities.", 
            },
            { 
                "id": 0, "is_right": "FALSE", 
                "title": "How many planets are there in the solar system?",
                "feedback": "Almost. Remember: These questions allow students to think about their own learning, to reinforce it and to think about new interests, to open up new learning opportunities.",
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "How did you learn it?",
                "feedback": "Well done. These questions allow students to think about their own learning, to reinforce it and to think about new interests, to open up new learning opportunities.",
            },
        ]
    },
    {
        "type": "true-false",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "This question is true or false",
        "text": "Can we use small frustrations of the student to initiate him/her in the use of stress regulation strategies?",
        "options": [
            {
                "id": 0,
                "feedback": "Yes, is good to use small frustrations of the student to initiate him/her in the use of stress regulation strategies.",
                "image": "false.svg",
                "is_right": "FALSE",
                "title": "False"
            },
            {
                "id": 0,
                "feedback": "Is good to use small frustrations of the student to initiate him/her in the use of stress regulation strategies. Try again.",
                "image": "true.svg",
                "is_right": "TRUE",
                "title": "True"
            }
        ]
    },
    {
        "type": "deduction",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Drag the words to the correct position in the sentence.",
        "text": "A lot of {1} is needed to ensure that {2} works well, allowing its two formats to capitalize on each other’s {3} .",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE", "title": "planning"},
            { "id": 0, "target_order": 2, "is_right": "TRUE", "title": "hybrid"},
            { "id": 0, "target_order": 3, "is_right": "TRUE", "title": "strengths"},
        ],
        "targets": [
            { "id": 0, "order": 1 },
            { "id": 0, "order": 2 },
            { "id": 0, "order": 3 }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again and think about what hybrid learning needs." },
            { "success_percentage": 100, "text": "Well done. Transformation requires a lot of planning" }
        ]
    },
    {
        "type": "fill-the-blanks",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Fill the blanks with the following words: equal, responsibility, boundaries",
        "text": "The teacher-student relationship is not {1}. Teachers must take {2}  for establishing and maintaining appropriate professional {3} with their students. ",
        "targets": [
            { "accepted_values": ["equal"], "id": 0, "order": 1 },
            { "accepted_values": ["responsability"], "id": 0, "order": 2 },
            { "id": 0, "accepted_values": ["boundaries"], "order": 3 },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Try again. Think about boundaries." },
            { "success_percentage": 50, "text": "Some good answers here. Boundaries shape our relationships with students. They determine when we are available, where and why someone might see us." },
            { "success_percentage": 100, "text": "Well done. Boundaries shape our relationships with students. They determine when we are available, where and why someone might see us." }
        ]
    },
    {
        "type": "short-question",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Choose the correct question to the question posed",
        "text": "Reusing an object or material, either for the same purpose it originally had or for a new one.",
        "options": [
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "What is recycling?",
                "feedback": "We must start by reducing the waste that we generate and that ends up in landfills in the majority of cases, such as plastics."
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "What is reuse?",
                "feedback": "As we have presented, everything can have multiple uses, we just have to think what we need and how we can make it with the available resources around us."
            },
            { 
                "id": 0, "is_right": "TRUE", 
                "title": "What is reduce?",
                "feedback": "We must start by reducing the waste that we generate and that ends up in landfills in the majority of cases, such as plastics."
            }
        ],
    },
    {
        "type": "opinion-scale",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Do you agree with...",
        "text": "Good learners use a varied toolkit of learning methods and attitudes to make them resourceful.",
        "options": [
            {
                "id": 0,
                "is_right": "FALSE",
                "image": "Disagree_Colour.svg",
                "title": "Disagree",
                "order": 2
            },
            {
                "id": 0,
                "is_right": "FALSE",
                "image": "Neutral_Colour.svg",
                "title": "Neutral",
                "order": 3
            },
            {
                "id": 0,
                "is_right": "TRUE",
                "image": "Agree_Colour.svg",
                "title": "Agree",
                "order": 4
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. It is about the cognitive aspects of learning. Some other aspects are emotions, strategies and social relationships." },
            { "success_percentage": 100, "text": "Yeah. It is about the cognitive aspects of learning. Some other aspects are emotions, strategies and social relationships." }
        ]
    },
    {
        "type": "correspondence",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Classify the following elements into two groups",
        "text": "Which stage does each situation correspond to?",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE", "title": "School Age" },
            { "id": 0, "target_order": 1, "is_right": "FALSE", "title": "Adolescence" },
            { "id": 0, "target_order": 2, "is_right": "TRUE", "title": "School Age" },
            { "id": 0, "target_order": 2, "is_right": "FALSE", "title": "Adolescence" },
        ],
        "targets": [
            { "id": 0, "order": 1, "title": "How can I be good?" },
            { "id": 0, "order": 2, "title": "Feeling of inferiority" }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again. Teens may begin to feel confused or insecure about themselves and how they fit in to societiy." },
            { "success_percentage": 50, "text": "Some good answers here. Teens may begin to feel confused or insecure about themselves and how they fit in to societiy." },
            { "success_percentage": 100, "text": "Well done. Teens may begin to feel confused or insecure about themselves and how they fit in to societiy." }
        ]
    },
    {
        "type": "order-chronology",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Sort the images to...",
        "text": "Sort the images to get a Creative Commons license that requires reference to the original author and allows derivative works under the same license.",
        "options": [
            { "id": 0, "target_order": 1, "is_right": "TRUE" },
            { "id": 0, "target_order": 2, "is_right": "FALSE" },
            { "id": 0, "target_order": 3, "is_right": "FALSE" }
        ],
        "targets": [
            { "id": 0, "order": 1 },
            { "id": 0, "order": 2 },
            { "id": 0, "order": 3 }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmm. Almost, but the order of the icons must be different." },
            { "success_percentage": 100, "text": "Well done. This is a good license for open educational resources." }
        ]
    },
    {
        "type": "word-search",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Look for these words: materials, language, activity, setting",
        "text": "",
        "targets": [
            { "id": 0, "order": 1, "accepted_values": ["materials", "language", "activity", "setting"] },
        ],
        "props": {
            "time": 120, "background_image": { }
        },
        "matrix": {
            "data": [],
            "props": {
                "alphabet": "EN",
                "rules": {
                    "allow_diagonal": false,
                    "allow_horizontal": true,
                    "allow_inverse": false,
                    "allow_vertical": true
                },
                "size": {
                    "height": 10,
                    "width": 10
                }
            },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. Awareness of your own bias is the first step in supporting development." },
            { "success_percentage": 50, "text": "Mmm. Awareness of your own bias is the first step in supporting development." },
            { "success_percentage": 100, "text": "Well done. Awareness of your own bias is the first step in supporting development." }
        ]
    },
    {
        "type": "word-catcher",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "What should the evaluation look like?",
        "targets": [
            {
                "id": 0,
                "order": 1,
                "accepted_values": ["balanced", "diverse"],
                "unaccepted_values": ["penalising", "random"]
            },
        ],
        "background": null,
        "props": {
            "time": 120,
            "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
            "word_color": '#eeeeee',
            "speed": { "min": 0.5, "max": 1.5 },
        },
        "feedbacks": [
            { "success_percentage": 0, "text": "Oops. Try again." },
            { "success_percentage": 50, "text": "Mmm. A few words were missing. You can do it!" },
            { "success_percentage": 100, "text": "Well done. These are important concepts for a good assessment." }
        ]
    },
    {
        "type": "seek-and-find",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Indique donde está el corazón en la imagen.",
        "image": "@@@dam:@image@93dc44b7-f18b-4626-b882-e303a02b37d7@84d97db0-5efa-497c-be13-9087e9c1f146@@@",
        "options": [
            {
                "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 120
                }
            },
            {
                "id": 0, "is_right": "TRUE", "target_order": 1,
                "area": {
                    "x": null, "y": null, "radius": 60
                }
            }
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "area": {
                    "x": 518, "y": 478, "radius": null
                }
            }
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "text-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Click on the cards that answer the question below.",
        "text": "Which are the best hobbies to mention when asked in a professional setting?",
        "options": [
            { "id": 0, "is_right": "TRUE", "title": "Volunteer" },
            { "id": 0, "is_right": "TRUE", "title": "Play musical instrument" },
            { "id": 0, "is_right": "FALSE", "title": "Drink" },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "image-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Click on the cards that answer the question below.",
        "text": "Which of the following images has nothing to do with the others?",
        "options": [
            {
                "id": 0, "is_right": "FALSE",
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "is_right": "FALSE",
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "is_right": "TRUE",
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "card-choice",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Move the icon at the bottom towards the card with your answer to the following:",
        "text": "Match the moment during the lesson with the objective to use group work.",
        "background": "PIRATES",
        "options": [
            {
                "id": 0,
                "is_right": "FALSE",
                "feedback": "Hmmm, think about it.",
                "title": "Before starting a lesson"
            },
            {
                "id": 0,
                "is_right": "TRUE",
                "feedback": "Perfect!",
                "title": "During a lesson"
            },
            {
                "id": 0,
                "is_right": "FALSE",
                "feedback": "Hmmm, think about it.",
                "title": "After finishing a lesson"
            }
        ]
    },
    {
        "type": "image-pairing",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Pair up the first row of images with the appropriate image from the second row",
        "text": "",
        "options": [
            {
                "id": 0, "target_order": 1, "is_right": "TRUE",
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "target_order": 2, "is_right": "TRUE",
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "target_order": 3, "is_right": "TRUE",
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "image": "@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@00899f5b-0c62-4ce4-85cc-dfa7fd26f0ba@4ab6cdb6-544d-455b-9ea7-33b1cacdf8e8@@@",
            },
            {
                "id": 0, "order": 2,
                "image": "@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@013555b8-a07e-4f35-9c45-7c3e5dcff456@f605536b-9723-4917-8c04-936075e60b8d@@@",
            },
            {
                "id": 0, "order": 3,
                "image": "@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@0171884a-8b5a-4d84-bf5a-ba24d8b0ecd5@e50ceb94-a258-4af8-a42d-f1f36f163721@@@",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": "text-pairing",
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "",
        "text": "",
        "options": [
            {
                "id": 0, "target_order": 1, "is_right": "TRUE",
                "title": "",
            },
            {
                "id": 0, "target_order": 2, "is_right": "TRUE",
                "title": "",
            },
            {
                "id": 0, "target_order": 3, "is_right": "TRUE",
                "title": "",
            },
        ],
        "targets": [
            {
                "id": 0, "order": 1,
                "title": "",
            },
            {
                "id": 0, "order": 2,
                "title": "",
            },
            {
                "id": 0, "order": 3,
                "title": "",
            },
        ],
        "feedbacks": [
            { "success_percentage": 0, "text": "Mmmm, try again." },
            { "success_percentage": 50, "text": "You have completed this activity with a score of at least 50%" },
            { "success_percentage": 100, "text": "Well done!" }
        ]
    },
    {
        "type": ACTIVITY_TYPE.FLASH_CARDS,
        "status": "DRAFT",
        "version": 1,
        "level": 1,
        "title": "Swipe Swipe",
        "text": "<p>Swipe right true, swipe left false</p>",
        "image": '',
        "options": [
            {
                "id": 0,
                "text": "First description Card",
                "image": "@@@dam:@image@9655d75c-e087-4273-9c28-cd703bae0720@f16a9d9c-7d41-4c1b-89ae-07c729245046@@@",
                "title": "First card",
                "is_right": "TRUE",
                "target_order": 0,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d75c-e087-4273-9c28-cd703bae0720@f16a9d9c-7d41-4c1b-89ae-07c729245046@@@"
            },
            {
                "id": 0,
                "text": "Second description Card",
                "image": "@@@dam:@image@9655d7b8-da7d-4aea-86b6-7275138c7b3a@383eb435-6179-4e90-869e-8f018b773438@@@",
                "title": "Second card",
                "is_right": "TRUE",
                "target_order": 1,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d7b8-da7d-4aea-86b6-7275138c7b3a@383eb435-6179-4e90-869e-8f018b773438@@@"
            },
            {
                "id": 0,
                "text": "Three description Card",
                "image": "@@@dam:@image@9655d822-8854-4ec8-85ce-c1ec76f178fe@1cb452ba-5066-48bc-be84-197de3b3c5c0@@@",
                "title": "Three Card",
                "is_right": "FALSE",
                "target_order": 2,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d822-8854-4ec8-85ce-c1ec76f178fe@1cb452ba-5066-48bc-be84-197de3b3c5c0@@@"
            },
            {
                "id": 0,
                "text": "Four description Card",
                "image": "@@@dam:@image@9655d86f-710b-4fb9-8550-e99b1ebdf5ea@e8692cf9-3f1d-4697-9165-65bfd4bf3e59@@@",
                "title": "Four Card",
                "is_right": "TRUE",
                "target_order": 3,
                "image_url": "http://xdamv1.pre-cloud.ximdex.net/api/v1/resource/render/@@@dam:@image@9655d86f-710b-4fb9-8550-e99b1ebdf5ea@e8692cf9-3f1d-4697-9165-65bfd4bf3e59@@@"
            }
        ],
        "feedbacks": [
            {
                "text": "Sorry, your answer is not correct.",
                "success_percentage": 0
            },
            {
                "text": "Well done!",
                "success_percentage": 3
            }
        ]
    },
    {
        "type": ACTIVITY_TYPE.FROGGY_JUMP,
        "status": "DRAFT",
        "level": 1,
        "version": 1,
        "title": "Froggy Jump",
        "text": "<p>Choose the correct answwer</p>",
        "image": "",
        "props": {
            "time": null,
            "background_image": {  }
        },

        "units": [],
        "matrix": null,
        "weight": 100,
        "options": [
            {
                "id": 3476,
                "area": null,
                "text": null,
                "image": null,
                "title": "Blue",
                "feedback": null,
                "is_right": "TRUE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3484,
                "area": null,
                "text": null,
                "image": null,
                "title": "XIMDEX is",
                "feedback": null,
                "is_right": "TRUE",
                "image_url": null,
                "target_order": 3
            },
            {
                "id": 3480,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2 is 4",
                "feedback": null,
                "is_right": "TRUE",
                "image_url": null,
                "target_order": 2
            },
            {
                "id": 3478,
                "area": null,
                "text": null,
                "image": null,
                "title": "Yellow",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3483,
                "area": null,
                "text": null,
                "image": null,
                "title": "GOOGLE is",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 3
            },
            {
                "id": 3482,
                "area": null,
                "text": null,
                "image": null,
                "title": "FACEBOOK is",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 3
            },
            {
                "id": 3477,
                "area": null,
                "text": null,
                "image": null,
                "title": "Red",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 1
            },
            {
                "id": 3479,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2 is 22",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 2
            },
            {
                "id": 3481,
                "area": null,
                "text": null,
                "image": null,
                "title": "2+2 is 2",
                "feedback": null,
                "is_right": "FALSE",
                "image_url": null,
                "target_order": 2
            }
        ],
        "targets": [
            {
                "id": 3474,
                "area": {
                    "x": null,
                    "y": null,
                    "radius": null
                },
                "text": null,
                "order": 2,
                "title": "How much is 2 + 2?",
                "image_url": null,
                "accepted_values": null,
                "unaccepted_values": null
            },
            {
                "id": 3473,
                "area": {
                    "x": null,
                    "y": null,
                    "radius": null
                },
                "text": null,
                "order": 1,
                "title": "What color is the sky?",
                "image_url": null,
                "accepted_values": null,
                "unaccepted_values": null
            },
            {
                "id": 3475,
                "area": {
                    "x": null,
                    "y": null,
                    "radius": null
                },
                "text": null,
                "order": 3,
                "title": "Which is the best IT company?",
                "image_url": null,
                "accepted_values": null,
                "unaccepted_values": null
            }
        ],

        "feedbacks": [
            {
                "text": "Frong answer",
                "success_percentage": 0
            },
            {
                "text": "RIBBIT!!",
                "success_percentage": 100
            }
        ],

    }
]
},

]

export const SEK_ACTIVITIES_GLOBAL_FEEDBACK_MESSAGES = {
    "es-ES": ["Lo sentimos, tu respuesta no es correcta.", "Has completado esta actividad con una puntuación de al menos el ", "¡Muy bien!"],
    "en-EN": ["Sorry, your answer is not correct.", "You have completed this activity with a score of at least ", "Well done!"],
    "uk-UA": ["Вибачте, ваша відповідь неправильна.", "Ви завершили цю діяльність з результатом не менше ", "Добре зроблено!"],
}
