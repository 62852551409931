import React from 'react';
import { XRow, XRowContent, XRowDetails, XRowExtraDetails } from '@ximdex/xui-react/material';
import { useNavigate } from 'react-router-dom';
import {
    StyledActivityId,
    StyledFontAwesomeIcon,
    StyledGreenXButton,
    StyledRedXButton,
    StyledDivCenterY,
    StyledXRow,
} from '../../Styled-Components';
import Pagination from '../Pagination/Pagination';
import { ArchiveRestore, Copy, Pencil, Trash } from 'lucide-react';

const AssessmentsListMap = ({
    assessmentsList,
    paginationMetaInfo,
    setPaginationMetaInfo,
    errorData,
    handleAssessmentStatus,
}) => {
    const navigate = useNavigate();

    return (
        <>
            {assessmentsList?.length > 0 ? ( 
                <>
                    {assessmentsList.map((assessment, index) => (
                        
                        <StyledXRow
                            style={{
                                borderBottom: index === (assessmentsList.length - 1) ? '1px solid #BBBBBB' : '',
                                background: 'rgb(247, 247, 247)',
                                width: '100%',
                                padding: '10px 0'
                            }}
                            key={'row' + index}
                            identifier={assessment?.id}
                            isCollapsable={true}
                            labelButtonCollapsable={`Has ${assessment?.activities?.length} ${assessment?.activities?.length === 1 ? 'activity' : 'activities'}`}
                            controls={[
                            {
                                component:  assessment?.status !== "ARCHIVED" && (
                                    <>
                                        <StyledGreenXButton onClick={() => navigate(`/duplicate-assessment`, {state: {assessment}} )} title='Duplicate assessment.' >
                                            <Copy size={20}/>
                                        </StyledGreenXButton>
                                        <StyledGreenXButton onClick={() => navigate(`/edit-assessment?assessment-id=${assessment.id}`)} title='Edit assessment.' >
                                            <Pencil size={20}/>
                                        </StyledGreenXButton>
                                    </>  
                                )
                            },
                            {
                                component:
                                    <StyledRedXButton 
                                    onClick={() => handleAssessmentStatus(assessment?.id, assessment?.status)}                                             
                                    title={assessment?.status === "ARCHIVED" ? 'Unarchive and change status to active' : 'Archive assessment'} 
                                    >
                                    {assessment?.status === 'ARCHIVED' ? <ArchiveRestore size={20}/> : <Trash size={20}/>}
                                </StyledRedXButton>
                            }
                        ]}
                        >
                            {/* XRow Content */}
                            <XRowContent key={"XRowContent" + index}>
                                <StyledDivCenterY>
                                    <span>Id: {assessment?.id}</span>
                                    <span style={{marginLeft: '1em'}}>{assessment?.title ?? assessment?.name ?? 'Unnamed assessment'}</span>
                                    {assessment?.isbn?.length > 0 && <p style={{ margin: '0 0.5em' }}>ISBN: {assessment?.isbn?.join(', ')}</p>}
                                </StyledDivCenterY>
                            </XRowContent>

                            {/* XRow Details */}
                                {assessment?.activities?.length > 0 ?
                                    <React.Fragment key={'XRowDetails'}>
                                        {assessment.activities.map((activity, activityIndex) => 
                                            <XRowDetails 
                                                key={'XRowDetails' + activityIndex}
                                                controlsDetails={[{
                                                    component:
                                                    <StyledGreenXButton
                                                        onClick={() => navigate(`/edit-activity?activity-id=${activity?.id}`)}
                                                        title='Edit activity'
                                                    >
                                                        <Pencil size={20}/>
                                                    </StyledGreenXButton>
                                                }
                                            ]}
                                            >
                                                <StyledDivCenterY>
                                                    <StyledActivityId>Id: {activity?.id}</StyledActivityId>
                                                    <p>{activity?.title ?? activity?.name ?? 'Unnamed activity'}</p>
                                                </StyledDivCenterY>
                                            </XRowDetails>
                                        )}
                                    </React.Fragment>
                                    : <XRowDetails key={"XRowDetails"}></XRowDetails>
                                }


                            {/* XRow Extra Details */}
                            <XRowExtraDetails
                                key={"XRowExtraDetails" + index}
                                extraDetails={[
                                    {
                                        label: 'Created date',
                                        type: assessment?.created_at ? "date" : 'text',
                                        value: assessment?.created_at ?? 'data unavailable' 
                                    },
                                    {
                                        label: 'Last update',
                                        type: assessment?.created_at ? "date" : 'text',
                                        value: assessment?.created_at ?? 'data unavailable' 
                                    },]}
                            />
                        </StyledXRow>
                    ))}
                    <Pagination
                        paginationInfo={paginationMetaInfo}
                        setPaginationMetaInfo={setPaginationMetaInfo}
                    />
                </>  
            ) : paginationMetaInfo?.total === 0 ? (
                <div>
                    <p style={{ margin: '3em 0.5em', textAlign: 'center', fontSize: '1.3em'}}>
                        No activities found with these filter settings.
                    </p>
                </div>
            ) : (assessmentsList?.length === 0 && errorData?.message) ? (
                <div>
                    <p style={{ margin: '3em 0.5em', textAlign: 'center', fontSize: '1.3em'}}>
                        {errorData?.message}
                    </p>
                    <p style={{ margin: '1em', textAlign: 'center', fontSize: '1em'}}>
                        {errorData?.error}
                    </p>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export default AssessmentsListMap;
