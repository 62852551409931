import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAssessmentById, getUserAssessmentById, updateAssessment } from '../../services/xevalApi.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { XContainerPage, XContainerSidebar } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import { LANGUAGES_OPTIONS, CLIENT, XEVAL_URI_API } from '../../CONSTANTS';
import { getAllActivities } from '../../services/xevalApi.service';
import Preview from '../../components/Preview/Preview';
import CustomTabs from '../../components/CustomTabs/CustomTabs';

import { 
    StyledXContainerPreview,
    StyledHeaderContainer,
    StyledGreenXButton,
    StyledFontAwesomeIcon,
    StyledActivityEditionContainer,
    StyledAddActivitiesModal,
    StyledDivFlexStartWrap,
} from '../../Styled-Components';
import ActivitiesListMap from '../../components/AssessmentCreation/ActivitiesListMap';
import { AddActvitiesModal } from '../../components/AssessmentCreation/AddActvitiesModal';
import { IdentifyingData } from '../../components/AssessmentCreation/IdentifyingData';

const AssessmentCreation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const { showSpinner, hideSpinner } = useSpinner();
    const [assessmentInfo, setAssessmentInfo] = useState(null);
    const [userAssessmentId, setUserAssessmentId] = useState(null);
    const [activitiesToBeAdded, setActivitiesToBeAdded] = useState([]);
    const [upToDate, setUpToDate] = useState(true);
    const [addActivities,setAddActivities] = useState({status: false, from_position: 0});
    const [potentialActivitiesList, setPotentialActivitiesList] = useState(undefined);
    const [activitiesListTotal, setActivitiesListTotal] = useState(undefined);
    const [loading, setLoading] = useState(false)
    const initActivitiesParams = 
    `only=${CLIENT === 'MHE' ? 'name,title,id,type,label,isbn,units' : 'name,title,id,type,label'}` +
    '&limit=400' +
    '&status=ACTIVE' +
    '&order_by=id&sort=desc'

    const [activitiesParams,setActivitiesParams] = useState(initActivitiesParams)
    const [tabSelected, setTabSelected] = useState('DATA');
    const assessmentId = parseInt(params.get('assessment-id'));

    useEffect(() => {
        !assessmentId ? handleNoAssessmentIdFound() : getAssessmentInfo(assessmentId);
    }, []);

    const getActivities = async () => {
        setPotentialActivitiesList(undefined)
        setActivitiesListTotal(undefined)
        
        await getAllActivities(activitiesParams).then(data => {
            assessmentInfo?.activities?.forEach(activity => {
                let indexOfActivityToRemove = undefined
                indexOfActivityToRemove = data.data.findIndex(potentialActivity => activity.id === potentialActivity.id)
                if (indexOfActivityToRemove > 0) {
                    data.data.splice(indexOfActivityToRemove, 1) 
                    data.total--
                }
            })
            setPotentialActivitiesList(data?.data ?? undefined)
            setActivitiesListTotal(data?.total ?? data?.data?.length ?? undefined)
        }).catch(err => {
            console.error(err)
        })
    }

    const getUserAssessmentId = async () => {
        setUserAssessmentId(null)
        if (assessmentInfo?.id) {
            const data = await getUserAssessmentById(assessmentInfo.id);
            setUserAssessmentId(data)
        }
    }

    useEffect(() => {
        if (assessmentInfo && !userAssessmentId) {
            if (assessmentInfo.activities.length > 0) {
                getUserAssessmentId();
            }
        }
    }, [assessmentInfo]);

    const handleNoAssessmentIdFound = () => {
        XPopUp({
            message:"The assessment id seems to be missing. Try again later.",
            iconType:'error',
            timer:'3000',
            popUpPosition:'top',
            iconColor: 'red',
        })
        navigate.goBack();
    }

    const getAssessmentInfo = (currentId) => {
        setLoading(true)
        showSpinner();
        getAssessmentById(currentId).then(data => {
            const newData = {...data};
            if (data?.language_default) {
                const selectedLanguage = LANGUAGES_OPTIONS.find(language => language.value === data.language_default);
                newData.language_default = selectedLanguage;           
            }
            if (!data?.units) newData.units= []
            if (!data?.isbn) newData.isbn = []
            setAssessmentInfo(newData);
        }).catch(err => {
            XPopUp({
                message:"There has been an error while getting this assessment's data. Please, try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner();
            setLoading(false)
        });
    }

    const handleActivities = () => {
        const allActivities = []
        const assessmentInfoCopy =  {...assessmentInfo}
         if (activitiesToBeAdded.length > 0) {
            // Add new activities. All without the weights so that it all gets recalculated by the BE
            assessmentInfoCopy.activities.splice(addActivities.from_position, 0, ...activitiesToBeAdded)
            assessmentInfoCopy.activities.forEach((activity, index) => allActivities.push({ id: activity?.id, order: index + 1 }))
        } else {
            assessmentInfoCopy.activities.forEach(activity => allActivities.push({ id: activity?.id, order: activity?.order, weight: activity?.weight }))
        }
        return allActivities
    }

    const updateAssessmentInfo = async () => {
        showSpinner()
        const activitiesWithIdOrderWeight = handleActivities()

        await updateAssessment(assessmentInfo.id, { ...assessmentInfo, activities: activitiesWithIdOrderWeight, language_default: assessmentInfo?.language_default?.value || null })
            .then(data => {
                const selectedLanguage = LANGUAGES_OPTIONS.find(language => language.value === data.language_default)
                data.language_default = selectedLanguage
                setActivitiesToBeAdded([])
                setAssessmentInfo(data)
                return data
            })
            .then(() => {
                XPopUp({
                    message:"Assessment update has been successful",
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
            })
            .catch(err => {
                XPopUp({
                    message:"Assessment update has failed. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            })
            .finally(async () => {
                await getUserAssessmentId()
                setUpToDate(true)
                hideSpinner()
            })
    }

    return (
        <StyledDivFlexStartWrap style={{ height: 'calc(100VH - 82px)', width:'100%', alignItems:'flex-start', margin: '0'}}>
            {!loading && <>
                <div
                    style={{ backgroundColor: '#eeeeee', width: '70%'}}
                >
                    <StyledHeaderContainer
                        title={"ASSESSMENT EDITOR"}
                        controls={[
                            {
                                component:<StyledGreenXButton 
                                                style={{height:'40px'}}
                                                onClick={() => updateAssessmentInfo()} 
                                                size='small'
                                                disabled={upToDate} 
                                            >
                                                <StyledFontAwesomeIcon
                                                    icon={upToDate ? ['fa', 'check'] : ['fa', 'floppy-disk']} size='1x' 
                                                    title={upToDate ? 'Assessment up to date' : 'Save assessment.'} 
                                                />
                                            </StyledGreenXButton>
                            }
                        ]}
                    />

                    {(assessmentId && assessmentId !== 0) && assessmentInfo && 
                        <StyledActivityEditionContainer 
                            id='assessment-scrollable-sidebar'
                            style={{ overflowY: 'auto', height: 'calc(100vh - (59px + 82px))', paddingTop:'1em'}}
                        >
                            {/* TABS */}
                            <CustomTabs
                                tabs={['DATA','ACTIVITIES']}
                                setTabSelected={setTabSelected}
                            />

                            {/* IDENTIFYING DATA */}
                            {tabSelected === "DATA" &&
                            <IdentifyingData
                                assessmentInfo={assessmentInfo}
                                setAssessmentInfo={setAssessmentInfo}
                                setUpToDate={setUpToDate}
                            />}
                            {/* ACTIVITIES LIST*/}
                            {tabSelected === 'ACTIVITIES' && 
                            <ActivitiesListMap
                                setAddActivities={setAddActivities}
                                assessmentInfo={assessmentInfo}
                                setAssessmentInfo={setAssessmentInfo}
                                updateAssessmentInfo={updateAssessmentInfo}
                                setUpToDate={setUpToDate}
                            />}
                        </StyledActivityEditionContainer>
                    }

                </div>

                {/* PREVIEW */}
                {(assessmentInfo && assessmentInfo?.activities?.length > 0)
                    ? <Preview typeOfPreview='assessment' getUserId={getUserAssessmentId} userId={userAssessmentId} />
                    : <StyledXContainerPreview><p style={{ textAlign: 'center' }}>Looks like this assessment doesn't have any activities yet.</p></StyledXContainerPreview>
                }

                {/* ADD ACTIVITIES MODAL */}
                <StyledAddActivitiesModal 
                    isOpen={addActivities.status}
                >
                    <AddActvitiesModal
                        activitiesToBeAdded={activitiesToBeAdded}
                        setActivitiesToBeAdded={setActivitiesToBeAdded}
                        updateAssessmentInfo={updateAssessmentInfo}
                        potentialActivitiesList={potentialActivitiesList}
                        activitiesListTotal={activitiesListTotal}
                        activitiesParams={activitiesParams}
                        setActivitiesParams={setActivitiesParams}
                        initActivitiesParams={initActivitiesParams}
                        getActivities={getActivities}
                        assessmentInfo={assessmentInfo}
                        setAddActivities={setAddActivities}
                    />
                </StyledAddActivitiesModal>
        </>}
        </StyledDivFlexStartWrap >
    )
}

export default AssessmentCreation;
