import React from "react";

const useSortOptionsTargets = () => {

    const sortOptionsAndTargets = (data) => {
        const newData = { ...data }
        if(newData?.options[0]?.target_order){
            newData.options?.sort((x, y) => x.target_order - y.target_order);
            newData.targets?.sort((x, y) => x.order - y.order);
        } else {
            newData.options?.sort((x, y) => x.id - y.id);
            newData.targets?.sort((x, y) => x.id - y.id);
        }
        return newData;
    }

    const sortOptions = (data) => {
        const newData = { ...data }
        if (newData?.options[0]?.target_order) {
            newData.options?.sort((x, y) => x.target_order - y.target_order)
        } else {
            newData.options?.sort((x,y) => x.id - y.id)
        }

        return newData
    }

  return [sortOptions, sortOptionsAndTargets];
}

export default useSortOptionsTargets;
