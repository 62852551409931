import React, { useState } from 'react';
import { 
    StyledSearchContainer, 
    StyledXCard,
    StyledFilterInputDiv,
    StyledXRadio,
    StyledGreenXButton,
} from '../../Styled-Components';
import { 
    XDropdown, 
    XInput, 
} from '@ximdex/xui-react/material';
import { 
    CLIENT,
    LANGUAGES_OPTIONS_SHOW_ALL, 
    STATUS_OPTIONS, 
    ACTIVITIES_LIST_ALL_TYPES,
    UNITS_OPTIONS
} from '../../CONSTANTS';
import {PoweredBy} from '../PoweredBy/PoweredBy'
import { Search } from 'lucide-react';

const ActivitiesListSearchColumn = ({setActivitiesValues,activitiesValues, unitsValues, setUnitsValues, searchParams, updateSearchParams }) => {
    const [temporaryISBN, setTemporaryISBN] = useState('')
   

    const handleChangeActivities = (values) => {
        const valuesArray = values.map( value => value.value)
        updateSearchParams('type', valuesArray.join(','))
        
        const valuesOptionsArray = valuesArray.map((activityParam) => {
            return ACTIVITIES_LIST_ALL_TYPES.find(option => option.value === activityParam)
        })
        setActivitiesValues(valuesOptionsArray)
    }

    const handleChangeUnits = (values) => {
        const valuesArray = values.map( value => value.value)
        updateSearchParams('units', valuesArray.join(','))

        const valuesOptionsArray = valuesArray.map((unitParam) => {
            return UNITS_OPTIONS.find(option => option.value === unitParam)
        })
        setUnitsValues(valuesOptionsArray)
    }

    const handleKeyPress = (e, param, value) => {
        if (e.key === 'Enter') {
            updateSearchParams(param, value)
            setTemporaryISBN("")
        }
    }

    const handleClickISBNSearch = (temporaryISBN) => {
        updateSearchParams('isbn', temporaryISBN)
        setTemporaryISBN("")
    }


    return (
        <StyledSearchContainer>
            <div>
            {/* STATUS */}
            <StyledXCard
                isCollapsable={true}
                isCollapsed={false}
                title='Status'
            >
                <StyledXRadio
                    direction='column'
                    value={searchParams.get('status') ? searchParams.get('status') : 'ALL'}
                    onChange={(e) => updateSearchParams('status', e.target.value)}
                    options={STATUS_OPTIONS}
                />
            </StyledXCard>


            {/* LANGUAGE*/}
            <StyledXCard
                isCollapsable={true}
                isCollapsed={false}
                title='Default language'
            >
                <StyledXRadio
                    direction='column'
                    value={searchParams.get('language_default') ? searchParams.get('language_default') : 'ALL'}
                    onChange={(e) => updateSearchParams('language_default', e.target.value)}
                    options={LANGUAGES_OPTIONS_SHOW_ALL}
                />
            </StyledXCard>


            {/* TYPE */}
            <StyledXCard
                isCollapsable={true}
                isCollapsed={false}
                title='Activity types'
            >
                <StyledFilterInputDiv>
                    <XDropdown
                        onChange={(e, values) => handleChangeActivities(values)}
                        value={activitiesValues}
                        options={ACTIVITIES_LIST_ALL_TYPES}
                        multiple={true}
                        hasCheckboxes={true}
                        labelOptions='label'
                        label='Select type'
                        width='100%'
                        bgColor='100'
                        size='small'
                    />
                </StyledFilterInputDiv>
            </StyledXCard>

            {CLIENT === 'MHE' && 
                (<>
                    {/* ISBN */}
                    <StyledXCard
                        isCollapsable={true}
                        isCollapsed={false}
                        title='ISBN'
                    >
                        <StyledFilterInputDiv>
                            <XInput
                                value={temporaryISBN}
                                id='isbn'
                                size='small'
                                label='Search by ISBN'
                                fullWidth
                                bgColor='100'
                                style={{ margin: '0 6px 0 0' }}
                                onChange={(e) => setTemporaryISBN(e.target.value)}
                                onKeyDown={(e) => handleKeyPress(e, 'isbn', temporaryISBN)}
                            />
                            <StyledGreenXButton onClick={() => handleClickISBNSearch(temporaryISBN)} variant='contained' title='Search by ISBN.'>
                                <Search size={20}/>
                            </StyledGreenXButton>
                        </StyledFilterInputDiv>
                    </StyledXCard>
                    {/* UNITS */}
                    <StyledXCard
                        isCollapsable={true}
                        isCollapsed={false}
                        title='Units'
                    >
                        <StyledFilterInputDiv>
                            <XDropdown
                                onChange={(e, values) => handleChangeUnits(values)}
                                value={unitsValues}
                                options={UNITS_OPTIONS}
                                multiple={true}
                                hasCheckboxes={true}
                                labelOptions='label'
                                label='Select units'
                                width='100%'
                                bgColor='100'
                                size='small'
                            />
                        </StyledFilterInputDiv>
                    </StyledXCard>
                </>)
            }
            </div>
           <PoweredBy
            position={"relative"}
           />

        </StyledSearchContainer>
    )
}

export default ActivitiesListSearchColumn;
