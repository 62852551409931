import ACTIVITY_TYPE from '../../default/activities/Activities'
import * as DEFAULT from '../../default/activities/ListSettings'

export const SEK_STATUS_OPTIONS = [
    ...DEFAULT.STATUS_OPTIONS,
];

export const SEK_PAGINATION_OPTIONS = [
    ...DEFAULT.PAGINATION_OPTIONS,
];

export const SEK_SORTING_OPTIONS = [
    ...DEFAULT.SORTING_OPTIONS,
];

export const SEK_LANGUAGES_OPTIONS_SHOW_ALL = [
    ...DEFAULT.LANGUAGES_OPTIONS_SHOW_ALL,
    { value: 'UK', label: 'Ukrainian' }
];

export const SEK_LANGUAGES_OPTIONS = [
    ...DEFAULT.LANGUAGES_OPTIONS,
    { value: 'uk-UA', label: 'Ukrainian' }
];

export const SEK_ACTIVITIES_LIST_ALL_TYPES = [
    ...DEFAULT.ACTIVITIES_LIST_ALL_TYPES,
    { value: ACTIVITY_TYPE.DEDUCTION, label: 'Deduction' },
    { value: ACTIVITY_TYPE.SHORT_QUESTION, label: 'Short question' },
    { value: ACTIVITY_TYPE.OPINION_SCALE, label: 'Opinion scale' },
    { value: ACTIVITY_TYPE.IMAGE_CHOICE, label: 'Image choice' },
    { value: ACTIVITY_TYPE.TEXT_CHOICE, label: 'Text choice' },
    { value: ACTIVITY_TYPE.IMAGE_PAIRING, label: 'Image pairing' },
    { value: ACTIVITY_TYPE.TEXT_PAIRING, label: 'Text pairing' },
    { value: ACTIVITY_TYPE.CARD_CHOICE, label: 'Card choice' },
    { value: ACTIVITY_TYPE.FLASH_CARDS, label: 'Flash cards' },
    { value: ACTIVITY_TYPE.FROGGY_JUMP, label: 'Froggy Jump' },
];
