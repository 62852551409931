import React from 'react';
import { 
    StyledPoweredByContainer, 
    StyledPoweredByImg,
    StyledVersionText 
} from '../../Styled-Components/index';

export const PoweredBy = ({position}) => {
    const poweredByImgSrc = "/assets/images/ximdex-logo-poweredby.png";
    return (
        <StyledPoweredByContainer position={position}>
            <a target="_blank" href="https://www.ximdex.com/" rel="noopener noreferrer">
                <StyledPoweredByImg src={poweredByImgSrc} alt="powered by ximdex"/>
            </a>
            <StyledVersionText>version {process.env.REACT_APP_VERSION}
                {/* if we are not in the production environment it will show a small text in red */}
                {process.env.REACT_APP_API_ENV !== 'production' &&
                    <span style={{ color: 'red' }}> PRE</span>
                }
            </StyledVersionText>
        </StyledPoweredByContainer>
    )
};
