import React, { useState } from 'react';
import "./InputRange.css";

const InputRange = (props) => {
    let min = props.min ? props.min : 0;
    let max = props.max ? props.max : min + 100;
    if (min >= max) {
        console.warn("The input min cannot be greater than the input max")
        max = min + 100;
    };

    let val = props.value ? props.value : min;
    if (val < min) val = min;
    if (val > max) val = max;

    const callDistanceLeft = (valueInput) => {
        const newValue = Number((valueInput - min) * 100 / (max - min));
        const newPosition = 10 - (newValue * 0.2);
        return `calc(${newValue}% + (${newPosition}px))`;
    }

    const [value, setValue] = useState(val);
    const [distanceLeft, setDistanceLeft] = useState(callDistanceLeft(value));


    const onChangeEvent = (e) => {
        setValue(e.target.value);
        setDistanceLeft(callDistanceLeft(e.target.value));
        if (typeof props.onChange === "function") props.onChange(e);
    }

    return (
        <div className={props.className} id={props.id} style={{ boxSizing: "border-box" }}>
            <div className="range-wrap">
                <div className="range-value" id="rangeV" style={{ left: distanceLeft }}><span>{value}</span></div>
                <input id="range" type="range" min={min} max={max} value={value} step="1" onChange={onChangeEvent} />
            </div>
        </div>
    )
}

export default InputRange;
