import React, {useState, useEffect} from 'react'
import {ActivityCreateTranslationCard, StyledActivityCreationXCard, StyledActivityEditionContainer, StyledContainerPreviewSpinner, StyledXCard} from '../../Styled-Components'
import useSortOptionsTargets from '../../hooks/useSortOptionsTargets';
import { CircularProgress } from '@mui/material';
import StringTranslation from './ActivityTranslations/StringTranslation';
import OptionTranslations from './ActivityTranslations/OptionTranslations';
import TargetTranslations from './ActivityTranslations/TargetTranslations';
import FeedbackTranslations from './ActivityTranslations/FeedbackTranslations';
import { AVAILABLE_TRANSLATION_LANGUAGES_BY_AI } from '../../CONSTANTS';
import useTranslations from '../../hooks/useTranslations';
import { XDropdown } from '@ximdex/xui-react/material';
import CustomTabs from '../CustomTabs/CustomTabs';

const ActivityCreateTranslations = ({
    activityInfo,
    setIsActivityTranslationUpdated,
    setActivityTranslation,
    activityTranslatedByIA,
    setActivityTranslatedByIA,
    setActivityTemplateTranslation,
    activityTemplateTranslation,
    getIATranslations,
    setControlEmptyInputs,
    iaLoadingSuggestions,
    languagesPendingToAdd,
    newLanguageSelected,
    setNewLanguageSelected,
    setIaLoadingSuggestions,
    charCount,
    setCharCount,
    ...props
}) => {
    const [loading, setLoading] = useState(false);
    const [, sortOptionsAndTargets] = useSortOptionsTargets();
    const [localStorageUpdate,setLocalStorageUpdate] = useState(false);
    const { buildTemplateTranslation, getCardTitle, handleRequestUniqueTranslation } = useTranslations();
    const [infoTranslationTabSelected, setInfoTranslationTabSelected] = useState('NAME');

    // useEffect(() => {
    //     if(localStorage.getItem("create-translation")){
    //         var translation = JSON.parse(localStorage.getItem("create-translation"))
    //         if(translation.lang !== '') setNewLanguageSelected(languagesPendingToAdd.filter(lang => lang.value === translation.lang)[0])
    //     }
    // }, []);

    useEffect(() => {
        if(AVAILABLE_TRANSLATION_LANGUAGES_BY_AI.includes(newLanguageSelected?.value)) getIATranslations(newLanguageSelected.value);
        createTemplate();
    }, [newLanguageSelected]);


    // UPDATE LOCAL STORAGE
    useEffect(() => {
        let translationStorage = JSON.parse(localStorage.getItem("create-translation"))
        localStorage.setItem("create-translation", JSON.stringify({
            ...translationStorage,
            feedbacks: activityTemplateTranslation?.feedbacks,
            name: activityTemplateTranslation?.name,
            text: activityTemplateTranslation?.text,
            title: activityTemplateTranslation?.title,
            options: activityTemplateTranslation?.options,
            targets: activityTemplateTranslation?.targets
        }))
    }, [localStorageUpdate]);

    const createTemplate = async () => {
        setLoading(true)
        await buildTemplateTranslation(activityInfo).then(res => {
            setActivityTemplateTranslation(res)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if(activityTemplateTranslation) setActivityTranslation(activityTemplateTranslation)
    }, [activityTemplateTranslation]);

    const getTabs = () => {
        const tabs = []
        activityInfo?.translatable_fields?.map((translatableField,i) => {
            if(typeof activityInfo[translatableField] === 'string' && activityInfo[translatableField] !== '' && translatableField !== 'name'){
                tabs.push(getCardTitle(translatableField).toUpperCase())
            }else if (typeof activityInfo[translatableField] === 'object' && activityInfo[translatableField]?.length > 0){
                tabs.push(getCardTitle(translatableField).toUpperCase())
            }
        })
        return tabs
    }

    const requestNewTranslation = async (translatableField, position = null) => {
        setIaLoadingSuggestions(true)
        await handleRequestUniqueTranslation(translatableField, newLanguageSelected.value, activityInfo, activityTranslatedByIA, position).then((result) => {
            setActivityTranslatedByIA(result)
        }).catch((err) => {
            console.log("Error updating translation")
        }).finally(() => {
            setIaLoadingSuggestions(false)
        });
    }

    if(loading) return (
        <StyledContainerPreviewSpinner>
            <CircularProgress color='primary' size={'50px'} style={{padding: '10px'}}/>
        </StyledContainerPreviewSpinner>
    )

    return (<StyledActivityEditionContainer>
            <CustomTabs
                tabs={getTabs()}
                setTabSelected={setInfoTranslationTabSelected}
            
            />
            {activityInfo?.translatable_fields?.map((translatableField,i) =>
                <div key={translatableField + i}>
                    {/* STRINGS */}
                    {activityInfo[translatableField] !== null &&
                    activityInfo[translatableField] !== '' &&
                    activityTemplateTranslation &&
                    (infoTranslationTabSelected === 'NAME' && translatableField === 'name' ||
                    infoTranslationTabSelected === 'HEADER' && translatableField === 'title' ||
                        infoTranslationTabSelected === 'CONTENT' && translatableField === 'text') &&  
                        (
                            <StringTranslation
                                setControlEmptyInputs={setControlEmptyInputs}
                                setTranslationUpdated={setIsActivityTranslationUpdated}
                                activityInfo={{...activityInfo}}
                                activityTranslatedByIA={activityTranslatedByIA}
                                activityTranslated={{...activityTemplateTranslation}}
                                setActivityTranslated={setActivityTemplateTranslation}
                                translatableField={translatableField}
                                newLanguage={true}
                                languageSelected={newLanguageSelected.value}
                                getTitle={getCardTitle}
                                setLocalStorageUpdate={setLocalStorageUpdate}
                                localStorageUpdate={localStorageUpdate}
                                iaLoadingSuggestions={iaLoadingSuggestions}
                                requestNewTranslation={requestNewTranslation}
                                charCount={charCount}
                                setCharCount={setCharCount}
                            />
                        ) 
                    }
                    {/* OPTIONS */}
                    {translatableField === 'options' && activityInfo.options?.length > 0 &&
                    activityTemplateTranslation &&
                    infoTranslationTabSelected === 'OPTIONS' && 
                    (activityInfo.image === null || activityInfo.image === '') && 
                        (
                            <OptionTranslations
                                newLanguage={true}
                                setTranslationUpdated={setIsActivityTranslationUpdated}   
                                activityInfo={{...sortOptionsAndTargets(activityInfo)}}
                                activityTranslated={{...activityTemplateTranslation}}
                                setActivityTranslated={setActivityTemplateTranslation}
                                languageSelected={newLanguageSelected.value}
                                getTitle={getCardTitle}
                                translatableField={translatableField}
                                activityTranslatedByIA={activityTranslatedByIA}
                                setLocalStorageUpdate={setLocalStorageUpdate}
                                localStorageUpdate={localStorageUpdate}
                                iaLoadingSuggestions={iaLoadingSuggestions}
                                requestNewTranslation={requestNewTranslation}
                                charCount={charCount}
                                setCharCount={setCharCount}
                            />
                        )
                    }
                    {/* TARGETS */}
                    {translatableField === 'targets' && 
                    activityTemplateTranslation &&
                    infoTranslationTabSelected === 'TARGETS' && 
                    activityInfo.targets?.length > 0 && 
                        (
                            <TargetTranslations
                                newLanguage={true}
                                setTranslationUpdated={setIsActivityTranslationUpdated}
                                activityInfo={{...sortOptionsAndTargets(activityInfo)}}
                                activityTranslated={{...activityTemplateTranslation}}
                                setActivityTranslated={setActivityTemplateTranslation}
                                languageSelected={newLanguageSelected.value}
                                getTitle={getCardTitle}
                                translatableField={translatableField}
                                setLocalStorageUpdate={setLocalStorageUpdate}
                                localStorageUpdate={localStorageUpdate}
                                activityTranslatedByIA={activityTranslatedByIA}
                                iaLoadingSuggestions={iaLoadingSuggestions}
                                requestNewTranslation={requestNewTranslation}
                                charCount={charCount}
                                setCharCount={setCharCount}
                            />
                        )
                    }
                    {/* FEEDBACKS */}
                    {translatableField === 'feedbacks' &&
                    infoTranslationTabSelected === 'GLOBAL FEEDBACK' && 
                        activityTemplateTranslation &&
                        activityInfo?.feedbacks?.length > 0 && 
                        (   
                            <FeedbackTranslations
                                newLanguage={true}
                                setTranslationUpdated={setIsActivityTranslationUpdated}   
                                languageSelected= {newLanguageSelected.value}
                                activityInfo={{...activityInfo}}
                                activityTranslated={{...activityTemplateTranslation}}
                                setActivityTranslated={setActivityTemplateTranslation}
                                getTitle={getCardTitle}
                                translatableField={translatableField}
                                setLocalStorageUpdate={setLocalStorageUpdate}
                                localStorageUpdate={localStorageUpdate}
                                activityTranslatedByIA={activityTranslatedByIA}
                                iaLoadingSuggestions={iaLoadingSuggestions}
                                requestNewTranslation={requestNewTranslation}
                            />
                        )
                    }
                </div>  
            )}
        </StyledActivityEditionContainer>  
    )
}

export default ActivityCreateTranslations
