import React, {useEffect, useState} from 'react';
import HTMLReactParser from 'html-react-parser';
import { XInput, XButton } from '@ximdex/xui-react/material';
import { faArrowDown, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { StyledFontAwesomeIcon, StyledTranslationSpan, StyledTranslationp, StyledDivCenterY, StyledXCardOption, ExtraInfoText, StyledActivityCreationXCard, StyledMarginContent, StyledXOwlBlackIcon, StyledGreenXButton} from '../../../Styled-Components'
import EditorTinyMCE from '../../TinyMCE/EditorTinyMCE';
import { ACTIVITIES_ANSWERS_OPTIONS_SETTINGS, ACTIVITY_TRANSLATION_AI, AVAILABLE_TRANSLATION_LANGUAGES_BY_AI, IA_LOADING_TRANSLATION_TEXT, IA_NOT_WORKING_MESSAGE, IA_SUGGESTED_TRANSLATION_TEXT, OPTIONS_WITH_TINY_EDITOR } from '../../../CONSTANTS';
import { CircularProgress } from '@mui/material';
import useMaxCharacters from '../../../hooks/useMaxCharacters';
import { ArrowDown, RefreshCcw } from 'lucide-react';

const OptionTranslations = ({   
    activityInfo, 
    activityTranslated, 
    setActivityTranslated, 
    languageSelected,
    translatableField,
    activityTranslatedByIA,
    localStorageUpdate,
    setlocalStorageUpdate,
    setTranslationUpdated,
    iaLoadingSuggestions,
    newLanguage,
    requestNewTranslation,
    charCount,
    setCharCount
}) => {
    const {getMaxCharOptions} = useMaxCharacters(activityInfo.type)
    const maxChar = getMaxCharOptions()
    const [totalChar, setTotalChar] = useState({})
    const [editorId, setEditorId] = useState([]);

    useEffect(() => {
        let newCharCount = [...charCount]
        editorId.forEach(id => {
            let index = charCount.findIndex(item => item.id === id)
            let data = {id: id, exceed: totalChar[id] > maxChar}
            if (index !== -1) {
                newCharCount[index] = data
            } else {
                newCharCount.push(data)
            }
        })
        setCharCount(newCharCount)
    }, [totalChar])

    const [hasOptions,setHasOptions] =  useState(() => {
        let hasvalues = []
        if(activityInfo.options && activityInfo.options.length > 0){
            activityInfo?.options.forEach((element,i) => {
                hasvalues.push(false)
                element.translatable_fields.forEach(translatableField => {
                    if(element[translatableField])
                        hasvalues.splice(hasvalues.length-1,1,true);
                })
            })
        }
        return hasvalues
    })

    
    const handleEditTranslation = (value, fieldOption, position, editor) => {
        if (editor && value.includes('<math xmlns="http://www.w3.org/1998/Math/MathML"')) {
            value = editor.getBody().getInnerHTML()
        }
        setActivityTranslated(prevState => ({
            ...prevState, 
            [translatableField]: prevState[translatableField].map((item, index) =>
                index === position ? { ...item, [fieldOption]: value } : item
            )
        }))
        setTranslationUpdated(false)
        if(setlocalStorageUpdate) setlocalStorageUpdate(!localStorageUpdate)

        let key = fieldOption+position
        setTotalChar({
            ...totalChar,
            [key]: editor ? editor?.getContent({ format: 'text' }).length : value.length
        })
        setEditorId([...editorId, key])
    }

    const handleApplyIARecomendation = (iaTranslation, fieldOption, position) => {
        handleEditTranslation(iaTranslation, fieldOption, position)
    }

    return (<>
            {hasOptions.includes(true) && 
        <StyledActivityCreationXCard
            isCollapsable={false}
            // backgroundHeader={true}
            isCollapsed={false}
            title={`${newLanguage ? 'Creating' : 'Editing'} language: ${languageSelected}`}
            // title={getTitle(translatableField)}
        >   
        <StyledMarginContent>  
                {activityInfo?.options?.map((option,key) => 
                <div key={key}>   
                    {hasOptions[key] &&
                    <StyledXCardOption 
                        key={key}
                        isCollapsed={false}
                        isCollapsable={true}
                        title={`Option ${key+1}`}
                    >
                    <StyledMarginContent>  

                    {option.translatable_fields.map((translatable_field,j) => 
                        <div key={j}>
                            {activityInfo.options[key][translatable_field] && 
                            <div>
                                {activityInfo.options[key][translatable_field] && 
                                    <>
                                        <p style={{textAlign:'left', color: 'grey', margin: '0'}}>
                                            <StyledTranslationSpan>
                                                Default language:
                                            </StyledTranslationSpan> 
                                            &nbsp;{HTMLReactParser(String(activityInfo.options[key][translatable_field]))}
                                        </p>
                                        {ACTIVITY_TRANSLATION_AI && AVAILABLE_TRANSLATION_LANGUAGES_BY_AI.includes(languageSelected) &&
                                        <StyledDivCenterY>
                                            <StyledXOwlBlackIcon src="./assets/images/xowl-logo4.png" />
                                            <p style={{textAlign:'left', color: 'grey'}}>
                                                <StyledTranslationp style={{color: '#43a1a2'}}>
                                                    <StyledTranslationSpan>{IA_SUGGESTED_TRANSLATION_TEXT}</StyledTranslationSpan>
                                                    {iaLoadingSuggestions ? IA_LOADING_TRANSLATION_TEXT :(activityTranslatedByIA?.options[key][translatable_field] 
                                                        ? HTMLReactParser(activityTranslatedByIA?.options[key][translatable_field]) 
                                                        : IA_NOT_WORKING_MESSAGE)}
                                                    </StyledTranslationp>     
                                            </p>
                                            {iaLoadingSuggestions ? 
                                                <CircularProgress size={15}/>
                                            :
                                                <>
                                                    <StyledGreenXButton
                                                        onClick={() => handleApplyIARecomendation(activityTranslatedByIA.options[key][translatable_field], translatable_field, key)} 
                                                        size='small' style={{minWidth: 'unset', height:'100%', marginRight:'0.2em'}}
                                                        disabled={activityTranslatedByIA?.options[key][translatable_field] ? false : true}
                                                        title={"Use this translation."}
                                                    >
                                                        <ArrowDown size={15}/>
                                                    </StyledGreenXButton>
                                                    <StyledGreenXButton
                                                        onClick={() => requestNewTranslation(translatableField, key)} 
                                                        size='small' style={{minWidth: 'unset', height:'100%'}}
                                                        disabled={iaLoadingSuggestions}
                                                        title={"Request a different translation."}
                                                    >
                                                        <RefreshCcw size={15}/>
                                                    </StyledGreenXButton>
                                                </>
                                            }
                                        </StyledDivCenterY>}
                                    </>
                                }
                                <div style={{display: 'flex', flexDirection:'column'}}>
                                    {OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type) && translatable_field !== 'feedback'
                                    ?
                                        <EditorTinyMCE 
                                            language={languageSelected.split('-')[0]}
                                            value={activityTranslated.options[key][translatable_field]} 
                                            handleEditorChange={(e,editor) => handleEditTranslation(e, translatable_field, key, editor)} 
                                            style={{ margin: '0 0 10px 0', width: '100%' }}
                                            config={{
                                                max_num_characters: maxChar,
                                                wirisformulaeditorlang: languageSelected.split('-')[0],
                                            }}
                                        />
                                    :
                                        <XInput
                                            placeholder="Enter translation"
                                            style={{   margin: '0 0 10px 0', width: '100%' }}
                                            size='normal'
                                            fullWidth
                                            multiline
                                            rows={2}
                                            value={activityTranslated?.options[key][translatable_field]}
                                            onChange={(e) => handleEditTranslation(e.target.value, translatable_field, key)}
                                            inputProps={{ maxLength: maxChar}}
                                        />
                                    }
                                    <ExtraInfoText isError={totalChar[translatableField+key] > maxChar} >{totalChar[translatableField+key]} of {maxChar} characters</ExtraInfoText>
                                </div> 
                            </div>}
                        </div>
                    )}
                        </StyledMarginContent>  

                    </StyledXCardOption>}
                </div>)}
                </StyledMarginContent>  
            </StyledActivityCreationXCard>}           
        </>
    )
}


export default OptionTranslations;
