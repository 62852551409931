import React from 'react'
import Error from './Error'
import Maintenance from './Maintenance'

const ErrorBoundaryFallback = ({error}) => {
    const errorStatusCode = (error?.isAxiosError && error?.response?.status) || 0
    console.error('ERROR BOUNDARY MESSAGE: ' + error?.stack || error?.message)

    if (errorStatusCode === 503) {
        return (<Maintenance error={error} />)
    }

    return (<Error error={error} />)
}

export default ErrorBoundaryFallback