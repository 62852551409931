import styled from 'styled-components/macro';

export const StyledPaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2em 0 1em 0;
    height: 65px;
`

export const StyledPaginationGroupContainer = styled.div`
    min-width: 95px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
       margin: 0.25em;
       cursor: pointer;
    }
`
