import { styled } from "@mui/system";
import { XDropdown, XInput, XRadio } from "@ximdex/xui-react/material";

export const StyledXDropdown = styled(XDropdown)`  
    &.css-1nxfwx6-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: unset;
    }
    &.MuiOutlinedInput-root {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }
`;

export const StyledXInput = styled(XInput)`
    width: 60%; 
    margin: 0; 
    background: #FBFBFB;

    // & label.Mui-hover {
    //     color: white;
    // }
    //   & .MuiOutlinedInput-root {
    //     &.Mui-hover fieldset {
    //       border-color: white;
    //     }
    // }

`

export const StyledXRadio = styled(XRadio)`
    padding: 5px;
    margin: 1em 1em;
    & .MuiRadio-root{
        padding: 3px !important;
    }

`


export const StyledXRadioMarkAsCorrect = styled(XRadio)`
    justify-content: flex-start;
    & .MuiFormGroup-root{
        margin-left: 1em;
    }
    & .MuiRadio-root{
        padding: 3px !important;
    }
    margin: 0.5em 0;

`
