import React, { useState } from 'react'
import { getAllExistingMedia, postMediaToCMS } from '../../../services/xevalApi.service'
import { XButton, XCard, XInput, XLabel, XRadio, XGallery } from '@ximdex/xui-react/material'
import { StyledButtonCenterContainer, TrashIcon, StyledFontAwesomeIcon, StyledXCard, StyledXCardOption, StyledActivityCreationXCard, StyledMarginContent, StyledRedXButton } from '../../../Styled-Components'
import { XDAM_URI_RESOURCE, MEDIA_PREVIEW, XDAM_URI_API } from '../../../CONSTANTS'

export const CORRECT_OPTIONS = [
    { value: 'TRUE', label: 'True' },
    { value: 'FALSE', label: 'False' }
];

const FlashCardAnswer = ({ 
    activityInfo, 
    setActivityInfo, 
    setIsActivityInfoUpdated, 
    TOOLTIPS 
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [activeImgIndex, setActiveImgIndex] = useState(0)

    const openGalleryModal = (index) => {
        setActiveImgIndex(index)
        setIsOpen(true)
    }

    const editActivityImg = (media) => {
        const newData = { ...activityInfo }

        if (media.type.startsWith('image')) {
            newData.options[activeImgIndex].image = media?.path
            newData.options[activeImgIndex].image_url = media?.url
            setIsActivityInfoUpdated(false)
            setActivityInfo(newData)
            return media
        }

        return false
    }

    const deleteCard = (async (index) => {
        const resultPrompt = await window.confirm(`Are you sure you want to remove flashcard ${index +1}?`)
        if (!resultPrompt) return;

        const newData = { ...activityInfo }
        newData.options.splice(index, 1);
        setIsActivityInfoUpdated(false)
        setActivityInfo(newData)
    })

    const addCard = () => {
        const newData = { ...activityInfo }
        newData.options.push({
            "id": 0,
            "text": "",
            "image": "",
            "title": "",
            "is_right": "TRUE",
            "image_url": '',
            "target_order": newData.options.lenght
        });
        setIsActivityInfoUpdated(false)
        setActivityInfo(newData)
    }

    const handleOnChange = (type, value, index) => {
        const newData = { ...activityInfo }
        newData.options[index][type] = value
        setIsActivityInfoUpdated(false)
        setActivityInfo(newData)
    }
    
    return (
        <StyledActivityCreationXCard
            isCollapsable={true}
            title={`Editing default language: ${activityInfo.language_default}`}
            tooltip={
                <StyledFontAwesomeIcon icon={['fa', 'circle-info']} title={TOOLTIPS.INSTRUCTIONS.IMAGES[activityInfo?.type]} size='1x' isInfo />
            }
        >
            <StyledMarginContent>
            {activityInfo.options?.sort((a, b) => {
                if (a.target_order < b.target_order) return -1;
                if (a.target_order > b.target_order) return 1;
                return 0;

            }).map((element, index) =>
                <StyledXCardOption
                    style={{ paddingBotton: "3rem"}}
                    isCollapsable={true}
                    title={`Flashcard ${index +1}`}
                    controls={[
                        {
                            component: <StyledRedXButton
                            title="delete"
                            variant="contained"
                            onClick={() => deleteCard(index)}
                            >
                                <TrashIcon size={20}/>
                            </StyledRedXButton>
                        }

                    ]}
                >
                    <StyledMarginContent>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            style={{ cursor: 'pointer', maxWidth: '95%', height: '175px', objectFit: 'contain' }}
                            src={element?.image_url ? element.image_url :
                                (element.image !== null && element.image !== '')
                                ? XDAM_URI_RESOURCE + element.image
                                : '/assets/images/default_open_gallery.svg'
                            }
                            onClick={() => openGalleryModal(index)}
                            onError={(e) => { e.target.onError = null; e.target.src = '/assets/images/default_open_gallery.svg' }}
                        />
                    </div>
                    <XLabel
                        label={<p style={{ marginRight: "1rem" }}>Title:</p>}
                        width="100%"
                        paddingSize='0'
                        htmlFor={`title-${index}-text`}
                        labelLocation='left'
                        component=
                        {
                            <XInput
                                type='text'
                                id={`title-${index}-text`}
                                style={{ width: '100%' }}
                                value={element.title}
                                onChange={(evt) => handleOnChange('title', evt.target.value, index)}
                            />
                        }

                    />
                    <XLabel
                        label={<p style={{ marginRight: "1rem" }}>Text:</p>}
                        width="100%"
                        paddingSize='0'
                        htmlFor={`card-${index}-text`}
                        labelLocation='left'
                        component=
                        {
                            <XInput
                                type='text'
                                id={`card-${index}-text`}
                                style={{ width: '100%' }}
                                value={element.text}
                                onChange={(evt) => handleOnChange('text', evt.target.value, index)}
                            />
                        }

                    />

                    <XRadio
                        label="Mark as correct answer:"
                        id={`${element.id}-is_right`}
                        options={CORRECT_OPTIONS}
                        value={element.is_right === 'TRUE' ? 'TRUE' : 'FALSE'}
                        paddingXSize='s'
                        onChange={(evt) => handleOnChange('is_right', evt.target.value, index)}
                    />
                    </StyledMarginContent>
                </StyledXCardOption>
            )}

            <StyledButtonCenterContainer>
                <XButton onClick={addCard}>ADD Flashcard</XButton>
            </StyledButtonCenterContainer>
            <XGallery
                isGalleryOpen={isOpen}
                setIsGalleryOpen={setIsOpen}
                fetchMedia={getAllExistingMedia}
                selectMedia={editActivityImg}
                addNewMedia={postMediaToCMS}
                mediaPreview={MEDIA_PREVIEW} 
                hideAddNewMedia={XDAM_URI_API?.startsWith('http') ? false : true}
            />
            </StyledMarginContent>
        </StyledActivityCreationXCard >
    )
}

export default FlashCardAnswer
