import React, { useState } from 'react'
import { XInput, XTag } from '@ximdex/xui-react/material'
import { 
    StyledXLabel,
    StyledXButtonS,
    StyledTagContainerForIdentifyingData,
    StyledGreenXButton,
} from '../../Styled-Components/index'
import { Plus } from 'lucide-react'

function MultipleValueInput({
    labelName,
    keyToEdit,
    data,
    editData,
    editAssessmentData = undefined,
    ...props
}) {
    const [newValue, setNewValue] = useState('')

    const handleAddNewValue = () => {
        setNewValue('')
        editAssessmentData ? editAssessmentData(newValue, keyToEdit) : editData(undefined, keyToEdit, 'add', newValue)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleAddNewValue()
        };
    }

    return (
        <div>
            <StyledXLabel
                label={labelName ? labelName : `${keyToEdit}: `}
                paddingSize='s'
                htmlFor={keyToEdit}
                labelLocation='left'
                component=
                    {<>
                        <StyledGreenXButton onClick={handleAddNewValue}>
                            <Plus size={20}/>
                        </StyledGreenXButton>
                        <XInput
                            value={newValue}
                            placeholder='Free field input (allows multiple values)'
                            id={keyToEdit}
                            size='small'
                            fullWidth
                            style={{ margin: '0 8px 0 0' }}
                            onChange={(e) => setNewValue(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                    </>}          
            />
            {data[keyToEdit]?.length > 0 && 
                <StyledTagContainerForIdentifyingData>
                    {data[keyToEdit].map((value, index) => (
                        <XTag
                            key={`isbnWordCollectionTag_${index}`}
                            style={{ margin: '4px' }}
                            status='correct'
                            isRemovable
                            name={value}
                            onClickRemove={(e) => editAssessmentData ? editAssessmentData(e, keyToEdit, 'remove', index) : editData(e, keyToEdit, 'remove', index)}
                        />
                    ))}
                </StyledTagContainerForIdentifyingData>
            }
        </div>
    )
}

export default MultipleValueInput
