import React from 'react';
import { StyledLink } from '../../Styled-Components/Links';
import { StyledDivCenterY, StyledHeaderListDiv } from '../../Styled-Components';
import { XButton } from '@ximdex/xui-react/material';
import { XInput } from '@ximdex/xui-react/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { BookPlus, ClipboardList, Plus } from 'lucide-react';

const AssessmentsListHeader = ({
    textBeforeSearch,
    setTextBeforeSearch,
    handleKeyPressSearch,
    title="Assessments"
}) => {
    return (
        <StyledHeaderListDiv>
            <StyledDivCenterY>
                <ClipboardList size={40} style={{marginRight: '10px', color:'#214F61'}}/>
                <h1>{title}</h1>
            </StyledDivCenterY>
            <XInput
                value={textBeforeSearch}
                onChange={(e) => setTextBeforeSearch(e.target.value)}
                onKeyDown={(e) => handleKeyPressSearch(e)}
                type='search'
                bgColor='100'
                size="small"
                style={{ width: '60%', margin: '0'}}
                placeholder='Search in assessment list'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <StyledLink to={'/create-assessment'} style={{ marginLeft: '12px' }}>
                <XButton title='Create a new assessment'>
                    <Plus size={20} style={{marginRight: '8px'}}/>
                    new assessment
                </XButton>
            </StyledLink>
        </StyledHeaderListDiv>
    );
}

export default AssessmentsListHeader;
