import React, { useState } from 'react';
import { 
    XRadio, 
    XDropdown,
    XInput
} from '@ximdex/xui-react/material';
import { 
    StyledSearchContainer,
    StyledXButtonS,
    StyledFontAwesomeIcon,
    StyledXCard,
    StyledXRadio,
    StyledGreenXButton,
    StyledFilterInputDiv,
} from '../../Styled-Components';
import {
    CLIENT, 
    UNITS_OPTIONS,
    LANGUAGES_OPTIONS_SHOW_ALL, 
    STATUS_OPTIONS
} from '../../CONSTANTS';
import { PoweredBy } from '../PoweredBy/PoweredBy';
import { Search } from 'lucide-react';

const AssessmentsListSearchColumn = ({ unitsValue, setUnitsValues, searchParams, updateSearchParams }) => {
    const [temporaryISBN, setTemporaryISBN] = useState('')
   
    const handleKeyPress = (e, param, value) => {
        if (e.key === 'Enter') {
            updateSearchParams(param, value)
            setTemporaryISBN("")
        }
    }

    const handleClickISBNSearch = (temporaryISBN) => {
        updateSearchParams('isbn', temporaryISBN)
        setTemporaryISBN("")
    }

    const handleChangeUnits = (values) => {
        const valuesArray = values.map( value => value.value)
        updateSearchParams('units', valuesArray.join(','))

        const valuesOptionsArray = valuesArray.map((unitParam) => {
            return UNITS_OPTIONS.find(option => option.value === unitParam)
        })
        setUnitsValues(valuesOptionsArray)
    }

    return (
        <StyledSearchContainer>
            <div>
            {/* STATUS */}
            <StyledXCard
                isCollapsable={true}
                isCollapsed={false}
                title='Status'
            >
                <StyledXRadio
                    direction='column'
                    value={searchParams.get('status') ? searchParams.get('status') : 'ACTIVE'}
                    onChange={(e) => updateSearchParams('status', e.target.value)}
                    options={[STATUS_OPTIONS[1],STATUS_OPTIONS[3]]}
                />
            </StyledXCard>

            {/* LANGUAGE*/}
            <StyledXCard
                isCollapsable={true}
                isCollapsed={false}
                title='Language'
            >
                <StyledXRadio
                    direction='column'
                    value={searchParams.get('language_default') || 'ALL'}
                    onChange={(e) => updateSearchParams('language_default', e.target.value)}
                    options={LANGUAGES_OPTIONS_SHOW_ALL}
                />
            </StyledXCard>

          
            {CLIENT === 'MHE' && 
                (<>
                    {/* ISBN */}
                    <StyledXCard
                        isCollapsable={true}
                        isCollapsed={false}
                        title='ISBN'
                    >
                        <StyledFilterInputDiv>
                            <XInput
                                value={temporaryISBN}
                                id='isbn'
                                size='small'
                                label='Search by ISBN'
                                bgColor='100'
                                fullWidth
                                style={{ margin: '0 6px 0 0' }}
                                onChange={(e) => setTemporaryISBN(e.target.value)}
                                onKeyDown={(e) => handleKeyPress(e, 'isbn', temporaryISBN)}
                            />
                            <StyledGreenXButton onClick={() => {handleClickISBNSearch(temporaryISBN)}} variant='contained' title='Search by ISBN.'>
                                <Search size={20}/>
                            </StyledGreenXButton>
                        </StyledFilterInputDiv>
                    </StyledXCard>
                    {/* UNITS */}
                    <StyledXCard
                        isCollapsable={true}
                        isCollapsed={false}
                        title='Units'
                    >
                        <StyledFilterInputDiv>
                            <XDropdown
                                onChange={(e, value) => handleChangeUnits(value)}
                                value={unitsValue}
                                options={UNITS_OPTIONS}
                                multiple={true}
                                hasCheckboxes={true}
                                labelOptions='label'
                                width='100%'
                                bgColor='100'
                                label='Select units'
                                size='small'
                            />
                        </StyledFilterInputDiv>
                    </StyledXCard>
                </>)
            }
            </div>
            <PoweredBy
                position={"relative"}
            />
        </StyledSearchContainer>
    )
}

export default AssessmentsListSearchColumn;
