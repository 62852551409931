
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routes from './routes/Routes';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorBoundaryFallback from './pages/Fallback/ErrorBoundaryFallback';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';
import 'animate.css';

function App() {
    return (
        <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <ErrorHandler>
                    <div className="App">
                        <Routes />
                    </div>
                </ErrorHandler>
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
