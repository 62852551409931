import React, { useState } from 'react';
import { StyledActivityEditionContainer } from '../../Styled-Components';
import CustomTabs from '../CustomTabs/CustomTabs';
import { IdentifyingData } from './ActivityData/IdentifyingData';
import ActivityHeader from './ActivityData/ActivityHeader';
import ActivityContent from './ActivityData/ActivityContent';
import { ActivityAnswers } from './ActivityAnswers';
import ActivityProperties from './ActivityData/ActivityProperties';
import { GlobalFeedback } from './ActivityData/GlobalFeedback';
import {
    ACTIVITIES_GLOBAL_FEEDBACK,
    ACTIVITIES_ANSWERS_OPTIONS_SETTINGS,
    ACTIVITIES_ANSWERS_TEMPLATE,
    ACTIVITIES_CONTENT_SETTINGS,
    ACTIVITIES_PROPERTIES_SETTINGS,
    ACTIVITIES_TOOLTIPS,
} from '../../CONSTANTS';

const ActivityCreateData = ({
    userActivityId,
    activityInfo,
    setActivityInfo,
    setIsActivityInfoUpdated,
    saveActivity,
    ActivityCreateData,
    charCount,
    setCharCount,
}) => {
    const [infoTabSelected, setinfoTabSelected] = useState('DATA');
    const [tags, setTags] = useState([]);
    const [currentTag, setCurrentTag] = useState('');


    const addNewTag = () => {
        const ALL_TAGS = [...tags];
        const tag = {
            name: currentTag,
            status: 'incorrect',
            onClick: removeTag
        }
        ALL_TAGS.push(tag);
        setCurrentTag('');
        setTags(ALL_TAGS);
        // REFACTOR: Remove one we solve why setCurrentTag to empty string does not work
        document.querySelector('#tags').value = '';
    }

    const removeTag = (allTags, index) => {
        const ALL_TAGS = [...allTags];
        ALL_TAGS.splice(index, 1);
        setTags(ALL_TAGS);
    }

    const editActivityInfo = (e, keyToEdit, actionForArray, detailToEditFromArray) => {
        const keyChange = keyToEdit || e?.target?.id;
        const valueChange = (e?.target?.value ?? e) || detailToEditFromArray;
        let newInfo = { ...activityInfo }

        if (!actionForArray) {
            if (keyToEdit === 'current_language' && activityInfo.language_default === activityInfo.current_language) {
                newInfo[keyChange]= valueChange
                newInfo.language_default= valueChange
            } else {
                newInfo[keyChange]= valueChange;
            }
        } else {
            if (actionForArray.toLowerCase() === 'remove') {
                newInfo[keyToEdit].splice(detailToEditFromArray, 1);
            } else if (actionForArray.toLowerCase() === 'add') {
                if (!newInfo[keyToEdit]) newInfo[keyToEdit] = []
                newInfo[keyToEdit].push(valueChange)
            } else if (actionForArray.toLowerCase() === 'edit-multiple-values') {
                newInfo[keyToEdit] = []
                detailToEditFromArray.forEach(value => {
                    newInfo[keyToEdit].push(value.value)
                })
            }
        }

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo)
    }

    const handleInfoUpdated = (newActivityInfo, newUpdateStatus) => {
        setIsActivityInfoUpdated(newUpdateStatus)
        setActivityInfo(newActivityInfo)
    }

    const createTabs = () => {
        const tabs = ['DATA', 'HEADER']
        if(ACTIVITIES_CONTENT_SETTINGS.SHOW_CONTENT.includes(activityInfo?.type)) tabs.push('CONTENT')
        if(ACTIVITIES_ANSWERS_TEMPLATE.FINISHED.includes(activityInfo?.type)) tabs.push('ANSWERS')
        if(ACTIVITIES_PROPERTIES_SETTINGS.SHOW_PROPERTIES.includes(activityInfo?.type)) tabs.push('PROPERTIES')
        if(ACTIVITIES_GLOBAL_FEEDBACK.includes(activityInfo?.type)) tabs.push('GLOBAL FEEDBACK')
        return tabs
    }

    return (
        <StyledActivityEditionContainer>
            <CustomTabs
                tabs={createTabs()}
                setTabSelected={setinfoTabSelected}
            />
            {/* IDENTIFYING DATA */}
            {infoTabSelected === 'DATA' && 
            <IdentifyingData
                activityInfo={activityInfo}
                setActivityInfo={setActivityInfo}
                editActivityInfo={editActivityInfo}
                tags={tags}
                currentTag={currentTag}
                setCurrentTag={setCurrentTag}
                addNewTag={addNewTag}
            />}
        
            {/* ACTIVITY HEADER */}
            {infoTabSelected === 'HEADER' && 
            <ActivityHeader
                activityTitle={activityInfo?.title || ""}
                editActivityInfo={editActivityInfo}
                activityInfo={activityInfo}
            />}

            {/* ACTIVITY CONTENT */}
            {ACTIVITIES_CONTENT_SETTINGS.SHOW_CONTENT.includes(activityInfo?.type) &&
            infoTabSelected === 'CONTENT' &&
                <ActivityContent
                    setActivityInfo={setActivityInfo}
                    setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                    activityInfo={activityInfo}
                    charCount={charCount}
                    setCharCount={setCharCount}
                    SETTINGS={ACTIVITIES_CONTENT_SETTINGS}
                    TOOLTIPS={ACTIVITIES_TOOLTIPS}
                />
            }

            {/* ANSWERS */}
            {ACTIVITIES_ANSWERS_TEMPLATE.FINISHED.includes(activityInfo?.type) &&
            infoTabSelected === 'ANSWERS' &&
                <ActivityAnswers
                    SETTINGS={ACTIVITIES_ANSWERS_OPTIONS_SETTINGS}
                    TEMPLATE={ACTIVITIES_ANSWERS_TEMPLATE}
                    TOOLTIPS={ACTIVITIES_TOOLTIPS}
                    userActivityId={userActivityId}
                    activityInfo={activityInfo}
                    setActivityInfo={setActivityInfo}
                    setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                    saveActivity={saveActivity}
                    charCount={charCount}
                    setCharCount={setCharCount}
                />
            }

            {/* CUSTOMIZABLE PROPERTIES */}
            {ACTIVITIES_PROPERTIES_SETTINGS.SHOW_PROPERTIES.includes(activityInfo?.type) &&
            infoTabSelected === 'PROPERTIES' &&
                <ActivityProperties
                    SETTINGS={ACTIVITIES_PROPERTIES_SETTINGS}
                    activityInfo={activityInfo}
                    setActivityInfo={setActivityInfo}
                    setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                    userActivityId={userActivityId}
                />
            }

            {/* GLOBAL FEEDBACK */}
            {ACTIVITIES_GLOBAL_FEEDBACK.includes(activityInfo?.type) && 
            infoTabSelected === 'GLOBAL FEEDBACK' &&
                <GlobalFeedback
                    TOOLTIPS={ACTIVITIES_TOOLTIPS}
                    activityInfo={activityInfo}
                    setActivityInfo={setActivityInfo}
                    setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                    handleInfoUpdated={handleInfoUpdated}
                />
            }              
        </StyledActivityEditionContainer>
    );
}

export default ActivityCreateData;
