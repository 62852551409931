import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { XInput, XButton } from '@ximdex/xui-react/material';
import { faArrowDown, faArrowsRotate} from "@fortawesome/free-solid-svg-icons";
import { StyledFontAwesomeIcon, StyledTranslationSpan, StyledTranslationp, StyledDivCenterY, StyledXCard, StyledXCardOption, ExtraInfoText, StyledActivityCreationXCard, StyledMarginContent, StyledXOwlBlackIcon, StyledGreenXButton} from '../../../Styled-Components'
import { ACTIVITIES_ANSWERS_OPTIONS_SETTINGS, ACTIVITY_TRANSLATION_AI, AVAILABLE_TRANSLATION_LANGUAGES_BY_AI, IA_LOADING_TRANSLATION_TEXT, IA_NOT_WORKING_MESSAGE, IA_SUGGESTED_TRANSLATION_TEXT } from '../../../CONSTANTS';
import { CircularProgress } from '@mui/material';
import useMaxCharacters from '../../../hooks/useMaxCharacters';
import { ArrowDown, RefreshCcw } from 'lucide-react';

const FeedbackTranslations = ({
    activityInfo, 
    activityTranslated, 
    setActivityTranslated, 
    languageSelected,
    translatableField,
    activityTranslatedByIA,
    localStorageUpdate,
    setlocalStorageUpdate,
    setTranslationUpdated,
    iaLoadingSuggestions,
    newLanguage,
    requestNewTranslation
}) => {
    const {getMaxCharGlobalFeedback} = useMaxCharacters()
    const maxChar = getMaxCharGlobalFeedback()

    const handleEditTranslation = (value, position) => {
        // Edit value
        setActivityTranslated(prevState => ({
            ...prevState, 
            [translatableField]: prevState[translatableField].map((item, index) =>
                index === position ? { ...item, text: HTMLReactParser(value) } : item
            )
        }))
        setTranslationUpdated(false)
        if(setlocalStorageUpdate) setlocalStorageUpdate(!localStorageUpdate)
    }

    const handleApplyIARecomendation = (iaTranslation, position) => {
        handleEditTranslation(iaTranslation, position)
    }
    return (<>
        <StyledActivityCreationXCard
            isCollapsable={false}
            // backgroundHeader={true}
            isCollapsed={false}
            title={`${newLanguage ? 'Creating' : 'Editing'} language: ${languageSelected}`}
            // title={getTitle(translatableField)}
        >
            <StyledMarginContent>
            {activityInfo?.feedbacks.map((element,i) =>
             <StyledXCardOption 
                key={i}
                isCollapsed={false}
                isCollapsable={true}
                title={`${element?.success_percentage}% message`}
             >
                <StyledMarginContent>         
                    {element && 
                         <>
                            <p style={{textAlign:'left', color: 'grey', margin: '0'}}>
                                <StyledTranslationSpan>
                                    Default language:
                                </StyledTranslationSpan> 
                                &nbsp;{HTMLReactParser(String(element.text))}
                            </p>
                            {ACTIVITY_TRANSLATION_AI && AVAILABLE_TRANSLATION_LANGUAGES_BY_AI.includes(languageSelected) &&
                            <StyledDivCenterY>
                                <StyledXOwlBlackIcon src="./assets/images/xowl-logo4.png" />
                                <p style={{textAlign:'left', color: 'grey'}}>
                                    <StyledTranslationp style={{color: '#43a1a2'}}>
                                        <StyledTranslationSpan>{IA_SUGGESTED_TRANSLATION_TEXT}</StyledTranslationSpan>
                                        {iaLoadingSuggestions ? IA_LOADING_TRANSLATION_TEXT : (String(activityTranslatedByIA?.feedbacks[i].text ? activityTranslatedByIA?.feedbacks[i].text : IA_NOT_WORKING_MESSAGE)) }
                                        </StyledTranslationp>     
                                </p>
                                {iaLoadingSuggestions ? 
                                    <CircularProgress size={15}/>
                                    :
                                    <>
                                        <StyledGreenXButton
                                            onClick={() => handleApplyIARecomendation(activityTranslatedByIA?.feedbacks[i]?.text,i)} 
                                            size='small' style={{minWidth: 'unset', height:'100%', marginRight:'0.2em'}}
                                            disabled={activityTranslatedByIA?.feedbacks[i]?.text ? false : true}
                                            title={"Use this translation."}
                                        >
                                            <ArrowDown size={15}/>
                                        </StyledGreenXButton>
                                        <StyledGreenXButton
                                            onClick={() => requestNewTranslation(translatableField,i)} 
                                            size='small' style={{minWidth: 'unset', height:'100%'}}
                                            disabled={iaLoadingSuggestions}
                                            title={"Request a different translation."}
                                        >
                                            <RefreshCcw size={15}/>
                                        </StyledGreenXButton>
                                    </>
                                }
                            </StyledDivCenterY>}
                        </>
                    }

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                            <XInput
                                placeholder="Enter translation"
                                style={{ margin: '0 0 10px 0', width: '100%' }}
                                size='normal'
                                fullWidth
                                multiline
                                rows={2}
                                value={activityTranslated?.feedbacks[i]?.text}
                                onChange={(e) => handleEditTranslation(e?.target?.value || '', i)}
                                inputProps={{ maxLength: maxChar }}
                                />
                            <ExtraInfoText>{`${activityTranslated?.feedbacks[i]?.text?.length || 0} of ${maxChar} characters`}</ExtraInfoText>

                        </div>
                    </StyledMarginContent>  
                </StyledXCardOption>
            )}
            </StyledMarginContent>
        </StyledActivityCreationXCard>   

    </>);
}

export default FeedbackTranslations;
