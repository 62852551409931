import * as SETTINGS from './constants/index'

const { 
    REACT_APP_CLIENT, 
    REACT_APP_API_ENV, 
    REACT_APP_XEVAL_API, 
    REACT_APP_XEDIT_API, 
    REACT_APP_XPANEL_API, 
    REACT_APP_XDAM_API,
    REACT_APP_CLONE_ACTIVITY_SELECT_LANG,
    REACT_APP_XOWL_API,
    REACT_APP_ACTIVITY_TRANSLATION_AI,
    REACT_APP_TINY_MATH_PLUGINS,
    REACT_APP_CURRENT_THEME
} = process.env;

// INITAL CONFIG
const API = 'api/v1.0';
export const CLIENT = REACT_APP_CLIENT.toUpperCase()
export const ENVIRONMENT = REACT_APP_API_ENV.toLowerCase().startsWith('pro') ? 'PRO' : 'PRE' 
export const COOKIE_NAME = `XIMDEX_${CLIENT}_${ENVIRONMENT}`

// SERVICES
export const XEVAL_URI_API = REACT_APP_XEVAL_API
export const XEDIT_URI_API = REACT_APP_XEDIT_API
export const XPANEL_URI_API = REACT_APP_XPANEL_API
export const XDAM_URI_API = REACT_APP_XDAM_API
export const XOWL_URI_API = REACT_APP_XOWL_API

// ENDOPINTS
export const XDAM_URI_RESOURCE = XDAM_URI_API + '/resource/render/'
export const FETCH_ACTIVITY_TYPES = `${XEVAL_URI_API}/${API}/activity_type`;
export const DELETE_TRANSLATION = `${XEVAL_URI_API}/${API}/translation/delete`
export const ACTIVITIES = `${XEVAL_URI_API}/${API}/activities`;
export const ASSESSMENTS = `${XEVAL_URI_API}/${API}/assessments`;
export const MEDIA_PREVIEW = `${XDAM_URI_API}/resource/render/`;
export const BASE_API_DAM_MEDIA = `${XDAM_URI_API}`;
export const LOGIN_KAKUMA = 'http://kakumav1.pre-cloud.ximdex.net/api/v1/login';
export const USER_ACTIVITY_ID = `${XEVAL_URI_API}/${API}/user-activities/preview`;
export const USER_ASSESSMENT_ID = `${XEVAL_URI_API}/${API}/user-assessments/preview`;


// SETTINGS OPTIONS
export const CORRECT_OPTIONS = [
    { value: 'TRUE', label: 'Yes' },
    { value: 'FALSE', label: 'No' }
];

export const MATRIX_SIZE_OPTIONS = [
    { label: 'Small (8 x 8)', value: 8, width: 8, height: 8 },
    { label: 'Regular (10 x 10)', value: 10, width: 10, height: 10 },
    { label: 'Large (12 x 12)', value: 12, width: 12, height: 12 },
];

export const STATUS_OPTIONS = SETTINGS[`${CLIENT}_STATUS_OPTIONS`] 

export const ACTIVITIES_PREVIEWS = SETTINGS[`${CLIENT}_ACTIVITIES_PREVIEWS`]

export const ACTIVITIES_GLOBAL_FEEDBACK = SETTINGS[`${CLIENT}_ACTIVITIES_GLOBAL_FEEDBACK`]

export const ACTIVITIES_CONTENT_SETTINGS = SETTINGS[`${CLIENT}_ACTIVITIES_CONTENT_SETTINGS`] 

export const ACTIVITIES_ANSWERS_TEMPLATE = SETTINGS[`${CLIENT}_ANSWERS_TEMPLATE`]

export const ACTIVITIES_ANSWERS_OPTIONS_SETTINGS = SETTINGS[`${CLIENT}_ACTIVITIES_ANSWERS_OPTIONS_SETTINGS`]

export const ACTIVITIES_PROPERTIES_SETTINGS = SETTINGS[`${CLIENT}_ACTIVITIES_PROPERTIES_SETTINGS`]

export const ACTIVITIES_TOOLTIPS = SETTINGS[`${CLIENT}_ACTIVITIES_TOOLTIPS`]

export const ACTIVITIES_INIT = SETTINGS[`${CLIENT}_ACTIVITIES_INIT`]

export const PAGINATION_OPTIONS = SETTINGS[`${CLIENT}_PAGINATION_OPTIONS`]

export const SORTING_OPTIONS = SETTINGS[`${CLIENT}_SORTING_OPTIONS`]

export const SORTING_OPTIONS_ASSESSMENTS =  SETTINGS[`${CLIENT}_SORTING_OPTIONS_ASSESSMENTS`]

export const LANGUAGES_OPTIONS =  SETTINGS[`${CLIENT}_LANGUAGES_OPTIONS`]

export const LANGUAGES_OPTIONS_SHOW_ALL =  SETTINGS[`${CLIENT}_LANGUAGES_OPTIONS_SHOW_ALL`]

export const ACTIVITIES_LIST_ALL_TYPES =  SETTINGS[`${CLIENT}_ACTIVITIES_LIST_ALL_TYPES`]

export const OPTIONS_WITH_TINY_EDITOR  = SETTINGS[`${CLIENT}_OPTIONS_WITH_TINY_EDITOR`]

export const ACTIVITIES_GLOBAL_FEEDBACK_MESSAGES  =  SETTINGS[`${CLIENT}_ACTIVITIES_GLOBAL_FEEDBACK_MESSAGES`]

export const UNITS_OPTIONS = SETTINGS[`${CLIENT}_UNITS_OPTIONS`]

export const PREVIEW_CHANNELS = [{ value: 'mobile', label: 'Mobile' }, { value: 'web', label: 'Web' }]

export const PREVIEW_THEME_TYPES = SETTINGS[`${CLIENT}_THEME_TYPES`]

export const CLONE_ACTIVITY_SELECT_LANG = REACT_APP_CLONE_ACTIVITY_SELECT_LANG ?? false


// IA CONFIGURATION
export const ACTIVITY_TRANSLATION_AI = REACT_APP_ACTIVITY_TRANSLATION_AI?.toUpperCase?.() === 'TRUE' || REACT_APP_ACTIVITY_TRANSLATION_AI;
export const AVAILABLE_TRANSLATION_LANGUAGES_BY_AI = SETTINGS[`${CLIENT}_AVAILABLE_TRANSLATION_LANGUAGES_BY_AI`]
export const IA_NOT_WORKING_MESSAGE = "XOwl suggestions are not working."
export const IA_SUGGESTED_TRANSLATION_TEXT = "XOwl suggested translation:"
export const IA_LOADING_TRANSLATION_TEXT = "XOwl is thinking..."

// TINY MATH
const TINY_PLUGINS = {
    FMath: 'FMathEditorButton',
    Wiris: 'tiny_mce_wiris_formulaEditor  tiny_mce_wiris_formulaEditorChemistry'
}

export const TINY_MATH_PLUGINS = REACT_APP_TINY_MATH_PLUGINS?.split?.(',')?.map(plugin => TINY_PLUGINS[plugin]).toString()

export const CURRENT_THEME = Object.values(PREVIEW_THEME_TYPES).find(theme => theme.value === REACT_APP_CURRENT_THEME) ?? PREVIEW_THEME_TYPES[0]