import React from 'react'
import { StyledContainerPreviewSpinner } from '../../Styled-Components'
import { XSpinner } from '@ximdex/xui-react/material'

function ContainedLoading() {
    return (
      <StyledContainerPreviewSpinner>
        <XSpinner
            width="75px"
            border="15px"
            color="#43a1a2"
            backgroundColor="#f2f2f2"
        />
      </StyledContainerPreviewSpinner>
    )
}

export default ContainedLoading