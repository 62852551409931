import styled from 'styled-components/macro';
import { XButton } from "@ximdex/xui-react/material";

export const StyledXButtonS = styled(XButton)`
    height: 39px;
    min-width: unset;
    width: 40px;
`;

export const StyledGreenXButton = styled(XButton)`
    color: #ffffff;
    font-size: 1em;
    min-width: unset;
    margin-left: 0.5em;
    padding: 6px;
`;

export const StyledRedXButton = styled(StyledGreenXButton)`
    // background: #e13144;

    &:hover {
        background: #e13144;
        // background: #cb2c3c;
    }
`;


export const StyledAddActivitiesButton = styled(XButton)`
    display: inline-block;
    border-radius: 50%;
    transition: all .2s ease-in-out;
    font-size: 1em;
    padding: 0.2em 0.8em;
    min-width: 0;
    &:hover {
       border-radius: 5px;
       > span {
          display: inline-block;
       }
    }

    span {
        transition: all .2s ease-in-out;
        display: none;
     }

`

export const StyledNewActivityItemButton = styled(XButton)`
    width: 225px;
    minHeight:250px;
    margin: 1.2em;
    font-weight: unset; 
    text-transform: unset;
    border-radius: 0 !important;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in;
    color: rgb(33, 79, 97);

    &:hover{
        background-color: rgb(255, 255, 255);
        border: 1px solid #43a1a2;
        font-weight: 500;
    }
`
