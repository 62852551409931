import React, { useState, useEffect } from 'react';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import {
    StyledGrid,
    StyledActivitiesListContainer,
    StyledXModal,
    StyledListMapContainer,
    StyledCenteredXYDiv,
    StyledDivCenterY,
    StyledDivFlexWrap,
} from '../../Styled-Components';
import {
    PAGINATION_OPTIONS,
    SORTING_OPTIONS,
    ACTIVITIES_LIST_ALL_TYPES,
    UNITS_OPTIONS,
    CLONE_ACTIVITY_SELECT_LANG,
} from '../.././CONSTANTS';
import {
    getAllActivities,
    updateActivityStatus
} from '../../services/xevalApi.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActivitiesListSearchColumn from '../../components/ActivitiesList/ActivitiesListSearchColumn';
import useDuplicateActivity from '../../hooks/useDuplicateActivity';
import useModals from '../../hooks/useSweetAlert';
import DuplicateActivityModal from '../../components/DuplicateActivityModal/DuplicateActivityModal';
import ActivitiesListMap from '../../components/ActivitiesList/ActivitiesListMap';
import ActivitesListHeader from '../../components/ActivitiesList/ActivitesListHeader';
import ActivitiesListMapControls from '../../components/ActivitiesList/ActivitiesListMapControls';

const ActivitiesList = () => {
    const { showSpinner, hideSpinner } = useSpinner();
    const navigate = useNavigate();
    const {handleCloneActivity} = useDuplicateActivity();
    const {modal} = useModals();
    const [openModalDuplicate, setOpenModalDuplicate] = useState(false);
    const [activityToDuplicate, setActivityToDuplicate] = useState({});

    const handleValue = (type) => {
        let paramsStorage = JSON.parse(localStorage.getItem("activities-filters"))
        if (paramsStorage) {
            if (type === 'sortBy') {
                let val = SORTING_OPTIONS.find((option) => option.value === (`${paramsStorage.order_by}&${paramsStorage.sort}`))
                return val
            } else {
                let val = PAGINATION_OPTIONS.find((option) => option.label === paramsStorage.limit)
                return val
            }
        } else {
            if (type === 'sortBy') {
                let val = SORTING_OPTIONS.find((option) => option.value === SORTING_OPTIONS[0].value)
                return val
            } else {
                let val = PAGINATION_OPTIONS.find((option) => option.value === PAGINATION_OPTIONS[0].value)
                return val
            }
        }

    }

    const [searchParams, setSearchParams] = useSearchParams()
    const [limitSearchParam, setLimitSearchParam] = useState(() => handleValue('limit'))
    const [sortBySearchParam, setSortBySearchParam] = useState(() => handleValue('sortBy'))
    const [activitiesList, setActivitiesList] = useState([])
    const [paginationMetaInfo, setPaginationMetaInfo] = useState({
        current_page: 1,
        last_page: undefined,
        next_page: undefined,
        prev_page: undefined,
        total: undefined,
    });
    const [textBeforeSearch, setTextBeforeSearch] = useState('')
    const [errorData, setErrorData] = useState({
        message: '',
        error: false
    })
    const [activitiesValues, setActivitiesValues] = useState(() => {
        let value = []
        if (searchParams.has('type')) {
            const activitiesParams = searchParams.get('type').split(',')
            activitiesParams.forEach(activityParam => {
                let optionParam = ACTIVITIES_LIST_ALL_TYPES.find(option => option.value === activityParam)
                value.push(optionParam)
            });
        }
        return value
    })
    const [unitsValues, setUnitsValues] = useState(() => {
        let value = []
        if (searchParams.has('units')) {
            const unitsParams = searchParams.get('units').split(',')
            unitsParams.forEach((unitParam) => {
                let optionParam = UNITS_OPTIONS.find(option => option.value === unitParam)
                value.push(optionParam)
            })
        }
        return value
    })

    // Set default filters or Get Local Storage Filters
    useEffect(() => {
        if (localStorage.getItem("activities-filters")) {
            let paramsStorage = JSON.parse(localStorage.getItem("activities-filters"))
            if (!searchParams.has('limit')) searchParams.set('limit', paramsStorage.limit)
            if (!searchParams.has('order_by')) searchParams.set('order_by', paramsStorage.order_by)
            if (!searchParams.has('sort')) searchParams.set('sort', paramsStorage.sort)

        } else {
            if (!searchParams.has('limit')) searchParams.set('limit', 24)
            if (!searchParams.has('order_by')) searchParams.set('order_by', 'id')
            if (!searchParams.has('sort')) searchParams.set('sort', 'desc')

            localStorage.setItem("activities-filters", JSON.stringify({
                limit: 24,
                order_by: 'id',
                sort: 'desc',
            }))
        }
        setSearchParams(searchParams, { replace: true })
    }, [])

    // Set Page Params
    useEffect(() => {
        if (searchParams.get('page') !== paginationMetaInfo?.current_page) {
            searchParams.set('page', paginationMetaInfo?.current_page)
            setSearchParams(searchParams, { replace: false })
        }
    }, [paginationMetaInfo?.current_page]);


    // Set New Filters to Local Storage and call getActivities()
    useEffect(() => {
        localStorage.setItem("activities-filters", JSON.stringify({
            limit: searchParams.get('limit'),
            order_by: searchParams.get('order_by'),
            sort: searchParams.get('sort'),
        }))

        getActivities();
    }, [searchParams]);


    const duplicateActivity = (activity) => {
        setActivityToDuplicate(activity)
        if(!CLONE_ACTIVITY_SELECT_LANG || activity.available_languages.length === 0){
            modal(
            `Are you sure you wish to duplicate this activity ?`,
            `Duplicate activity`, 
            () => handleCloneActivity(activity)
            )
        }else{
            setOpenModalDuplicate(true)
        }
    }


    // Get All Activities
    const getActivities = async () => {
        showSpinner();
        await getAllActivities(searchParams).then(data => {
            data.data.forEach(activity => {
                activity.isCollapsed = false;
            })
            setActivitiesList(data.data);

            let cloneData = Object.assign({}, data);
            delete cloneData.data;
            setPaginationMetaInfo(cloneData);
        }).catch(err => {
            console.error(err);
            setErrorData({ message: 'It looks like something has happened. Try again later.', error: err })
        }).finally(() => {
            hideSpinner();
        });
    }

    const handleActivityStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 'ARCHIVED' ? 'ACTIVE' : 'ARCHIVED';
        modal(
            `Are you sure you want to change the status of the activity with id ${id} to ${newStatus}?`,
            `Change activity status`, 
            () => {
                showSpinner();
                updateActivityStatus(id, newStatus).then((res) => {
                getActivities();
                XPopUp({
                    message:`Status changed to ${res?.data?.status}`,
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
            }).catch(err => {
                console.error(err);
                XPopUp({
                    message:"There's been an error. Please, try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }).finally(() => {
                hideSpinner();
            });}
        )  
    }

    // Update Search Params
    const updateSearchParams = (searchOption, value) => {
        if (searchOption === "sortBy") {
            let val = SORTING_OPTIONS.find((option) => option.label === SORTING_OPTIONS[value].label)
            setSortBySearchParam(val)
            val = val.value.split('&')
            searchParams.set('order_by', val[0])
            searchParams.set('sort', val[1])
        } else if (searchOption === 'limit') {
            let val = PAGINATION_OPTIONS.find((option) => option.label === PAGINATION_OPTIONS[value].label)
            setLimitSearchParam(val)
            searchParams.set('limit', val.value)
        } else if (value.toUpperCase() === 'ALL' || !value) {
            searchParams.delete(`${searchOption}`)
        } else {
            searchParams.set(`${searchOption}`, value)
        }
        setSearchParams(searchParams, { replace: false })
    }

    // On key press(Intro) Search
    const handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            updateSearchParams('search', textBeforeSearch);
            setTextBeforeSearch('')
        }
    }

    if (errorData.error) throw errorData.error
    return (
        <>
        <ActivitesListHeader
            textBeforeSearch={textBeforeSearch}
            sortBySearchParam={sortBySearchParam}
            handleKeyPressSearch={handleKeyPressSearch}
            setTextBeforeSearch={setTextBeforeSearch}
            updateSearchParams={updateSearchParams}
            limitSearchParam={limitSearchParam}
            />
        <StyledGrid>
            <ActivitiesListSearchColumn
                activitiesValues={activitiesValues}
                setActivitiesValues={setActivitiesValues}
                searchParams={searchParams}
                updateSearchParams={updateSearchParams}
                unitsValues={unitsValues}
                setUnitsValues={setUnitsValues}
                />

            <StyledActivitiesListContainer>
                <StyledListMapContainer>
                    <ActivitiesListMapControls
                        paginationMetaInfo={paginationMetaInfo}
                        sortBySearchParam={sortBySearchParam}
                        limitSearchParam={limitSearchParam}
                        updateSearchParams={updateSearchParams}
                        setActivitiesValues={setActivitiesValues}
                        setUnitsValues={setUnitsValues}
                        searchParams={searchParams}
                        />
                    <ActivitiesListMap
                        errorData={errorData}
                        setPaginationMetaInfo={setPaginationMetaInfo}
                        paginationMetaInfo={paginationMetaInfo}
                        activitiesList={activitiesList}
                        handleActivityStatus={handleActivityStatus}
                        duplicateActivity={duplicateActivity}
                        
                        />

                </StyledListMapContainer>
            </StyledActivitiesListContainer >
            
            <StyledXModal             
                isOpen={openModalDuplicate}
                ariaHideApp={false}
                >
                <DuplicateActivityModal 
                    setOpenModal={setOpenModalDuplicate} 
                    activityToDuplicate={activityToDuplicate} 
                    handleCloneActivity={handleCloneActivity}
                    />
            </StyledXModal>

        </StyledGrid >
        </>
    )
}

export default ActivitiesList;
