import ACTIVITY_TYPE from '../../default/activities/Activities'

export const MHE_ACTIVITIES_INIT =[
    //* Castellano
    {"es-ES": [
        {
            "type": "single-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Escoge la respuesta correcta.",
            "text": "¿Cómo se llama el río más largo del mundo?",
            "options": [
                { "id": 0, "is_right": "FALSE", "title": "Yangtsé​" },
                { "id": 0, "is_right": "FALSE", "title": "Amazonas" },
                { "id": 0, "is_right": "TRUE", "title": "Nilo" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        {
            "type": "multiple-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Marca las respuestas que son correctas.",
            "text": "¿Cuáles son planetas del sistema solar?",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Neptuno" },
                { "id": 0, "is_right": "FALSE", "title": "Luna" },
                { "id": 0, "is_right": "TRUE", "title": "Júpiter" },
                { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
                { "id": 0, "is_right": "FALSE", "title": "Draugr" },

            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        // only for MHE
        {
            "type": "match",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Empareja las opciones como corresponda.",
            "text": "Ordena cronológicamente estas edades de la historia (de más a menos reciente).",
            "options": [
                { "id": 0, "target_order": 1, "title": "Edad Moderna", "is_right": "TRUE" },
                { "id": 0, "target_order": 3, "title": "Edad Antigua", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "Edad Media", "is_right": "TRUE" },
            ],
            "targets": [
                { "id": 0, "order": 1, "title": "Primera" },
                { "id": 0, "order": 2, "title": "Segunda" },
                { "id": 0, "order": 3, "title": "Tercera" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        {
            "type": "true-false",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Marca si la afirmación es verdadera o falsa.",
            "text": "Las sustancias iniciales de una reacción química se denominan reactivos.",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Verdadero" },
                { "id": 0, "is_right": "FALSE", "title": "Falso" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        // only for MHE
        {
            "type": "input",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Escribe tu respuesta a continuación.",
            "text": "¿Cómo se llama la unidad más pequeña de la vida?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["célula"] },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ],
        },
        {
            "type": "input-long",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Escribe tu respuesta a continuación.",
            "text": "¿Cómo se llama la unidad más pequeña de la vida?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": [] },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ],
        },
        // only for MHE
        {
            "type": "fit-word",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Arrastra cada palabra a la casilla correspondiente.",
            "text": "La ecología es el estudio de las relaciones entre los {1} vivos, incluidos los humanos, y su {2} físico.",
            "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
            "options": [
                { "id": 0, "target_order": 1, "title": "organismos", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "entorno", "is_right": "TRUE" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ],
        },
        {
            "type": "fill-the-blanks",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Rellena los espacios en blanco para completar la frase siguiente:",
            "text": "Un {1} no es más que una serie de de pasos que se siguen para completar una determinada {2}.",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["algoritmo"] },
                { "id": 0, "order": 2, "accepted_values": ["tarea"] },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        {
            "type": "order-chronology",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Ordena las imágenes según la cantidad de criterios que cumplen: facilitan tu movilidad, la movilidad de tus alumnos, trabajar en grupos y mirar la pizarra.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "", "image_url": "" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Permite trabajar en grupos, pueden moverse, pero algunos están de espaldas a la pizarra." },
                { "id": 0, "order": 1, "title": "Permite trabajar en grupos, mirar la pizarra y facilita la movilidad de la mayoría de los alumnos." },
                { "id": 0, "order": 2, "title": "Permite trabajar en grupos, pueden moverse bien, pero no todos verán bien la pizarra." }
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        {
            "type": "word-search",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Busca estas frutas: plátano, pera y coco.",
            "text": "",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["platano", "pera", "coco"] },
            ],
            "props": {
                "time": 120, "background_image": {}
            },
            "matrix": {
                "data": [],
                "props": {
                    "alphabet": "EN",
                    "rules": {
                        "allow_diagonal": false,
                        "allow_horizontal": true,
                        "allow_inverse": false,
                        "allow_vertical": true
                    },
                    "size": {
                        "height": 10,
                        "width": 10
                    }
                },
            },
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        {
            "type": "word-catcher",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Atrapa la fruta.",
            "targets": [
                {
                    "id": 0,
                    "order": 1,
                    "accepted_values": ["Platano"],
                    "unaccepted_values": ["Pizza", "Cebolla", "Bocadillo", "Queso", "Mantequilla", "Sopa", "Huevo", "Pasta", "Tomate", "Lechuga", "Pan"]
                },
            ],
            "background": null,
            "props": {
                "time": 120,
                "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
                "word_color": '#eeeeee',
                "speed": { "min": 0.5, "max": 1.5 },
            },
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        {
            "type": "seek-and-find",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Toca donde está corazón en la imagen.",
            "image": "@@@dam:@image@9cd37bcc-878c-4dd1-a41a-c966a2cf7823@e3d1ccd4-46f2-47dc-8558-6304d846172b@@@",
            "options": [
                {
                    "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 120
                    }
                },
                {
                    "id": 0, "is_right": "TRUE", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 60
                    }
                }
            ],
            "targets": [
                {
                    "id": 0, "order": 1,
                    "area": {
                        "x": 518, "y": 478, "radius": null
                    }
                }
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        {
            "type": "correspondence",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Título de actividad de tipo correspondencia.",
            "text": "Contenido de actividad de tipo correspondencia",
            "options": [
                { "id": 0, "target_order": 1, "is_right": "TRUE" },
                { "id": 0, "target_order": 1, "is_right": "FALSE" }
            ],
            "targets": [
                { "id": 0, "order": 1 }
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        },
        // only for MHE
        {
            "type": ACTIVITY_TYPE.RANK,
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Pon las opciones en orden.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "title": "Primera" },
                { "id": 0, "target_order": 1, "is_right": "TRUE", "image": "", "title": "Segunda" },
                { "id": 0, "target_order": 2, "is_right": "TRUE", "image": "", "title": "Tercera" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Permite trabajar en grupos, pueden moverse, pero algunos están de espaldas a la pizarra." },
                { "id": 0, "order": 1, "title": "Permite trabajar en grupos, mirar la pizarra y facilita la movilidad de la mayoría de los alumnos." },
                { "id": 0, "order": 2, "title": "Permite trabajar en grupos, pueden moverse bien, pero no todos verán bien la pizarra." }
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Lo sentimos, tu respuesta no es correcta." },
                { "success_percentage": 100, "text": "¡Muy bien!" }
            ]
        }
    ]},

    //* Catalán
    {"ca-ES": [
        {
            "type": "single-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "text": "Com es diu el riu més llarg del món?",
            "title": "Escull la resposta correcta.",
            "options": [
                { "id": 0, "is_right": "FALSE", "title": "Iang-Tsé" },
                { "id": 0, "is_right": "FALSE", "title": "Amazones" },
                { "id": 0, "is_right": "TRUE", "title": "Nil" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        {
            "type": "multiple-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Marca les respostes que són correctes.",
            "text": "Quins són planetes del sistema solar?",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Neptú" },
                { "id": 0, "is_right": "FALSE", "title": "Lluna" },
                { "id": 0, "is_right": "TRUE", "title": "Júpiter" },
                { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
                { "id": 0, "is_right": "FALSE", "title": "Draugr" },

            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        // only for MHE
        {
            "type": "match",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Emparella les opcions com correspongui.",
            "text": "Ordena cronològicament aquestes edats de la història (de més a menys recent).",
            "options": [
                { "id": 0, "target_order": 1, "title": "Edat Moderna", "is_right": "TRUE" },
                { "id": 0, "target_order": 3, "title": "Edat Antiga", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "Edat Mitjana", "is_right": "TRUE" },
            ],
            "targets": [
                { "id": 0, "order": 1, "title": "Primera" },
                { "id": 0, "order": 2, "title": "Segona" },
                { "id": 0, "order": 3, "title": "Tercera" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        {
            "type": "true-false",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Marca si l'afirmació és vertadera o falsa.",
            "text": "Les substàncies inicials d'una reacció química es denominen reactius.",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Veritable" },
                { "id": 0, "is_right": "FALSE", "title": "Fals" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        // only for MHE
        {
            "type": "input",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Escriu la teva resposta a continuació.",
            "text": "Com es diu la unitat més petita de la vida?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["cèl·lula"] },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ],
        },
        {
            "type": "input-long",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Escriu la teva resposta a continuació.",
            "text": "Com es diu la unitat més petita de la vida?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": [] },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ],
        },
        // only for MHE
        {
            "type": "fit-word",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Arrossega cada paraula a la casella corresponent.",
            "text": "L'ecologia és l'estudi de les relacions entre els {1} vius, inclosos els humans, i el seu {2} físic.",
            "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
            "options": [
                { "id": 0, "target_order": 1, "title": "organismes", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "entorn", "is_right": "TRUE" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ],
        },
        {
            "type": "fill-the-blanks",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Omple els espais en blanc per completar la frase següent:",
            "text": "Un {1} no és més que una sèrie de passos que se segueixen per completar una determinada {2}.",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["algoritme"] },
                { "id": 0, "order": 2, "accepted_values": ["tasca"] },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        {
            "type": "order-chronology",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Ordena les imatges segons la quantitat de criteris que compleixen: faciliten la teva mobilitat, la mobilitat dels alumnes, treballar en grups i mirar la pissarra.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image":  "", "image_url": "" },
                { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "", "image_url": "" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Permet treballar en grups, es poden moure, però alguns estan d'esquena a la pissarra." },
                { "id": 0, "order": 1, "title": "Permet treballar en grups, mirar la pissarra i facilitar la mobilitat de la majoria dels alumnes." },
                { "id": 0, "order": 2, "title": "Permet treballar en grups, es poden moure bé, però no tots veuran bé la pissarra." }
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        {
            "type": "word-search",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Busca aquestes fruites: plàtan, pera i coco.",
            "text": "",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["platan", "pera", "coco"] },
            ],
            "props": {
                "time": 120, "background_image": {}
            },
            "matrix": {
                "data": [],
                "props": {
                    "alphabet": "EN",
                    "rules": {
                        "allow_diagonal": false,
                        "allow_horizontal": true,
                        "allow_inverse": false,
                        "allow_vertical": true
                    },
                    "size": {
                        "height": 10,
                        "width": 10
                    }
                },
            },
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        {
            "type": "word-catcher",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Atrapa la fruita.",
            "targets": [
                {
                    "id": 0,
                    "order": 1,
                    "accepted_values": ["Platan"],
                    "unaccepted_values": ["Pizza", "Ceba", "Entrepà", "Formatge", "Mantega", "Sopa", "Ou", "Pasta", "Tomàquet", "Enciam", "Pa"]
                },
            ],
            "background": null,
            "props": {
                "time": 120,
                "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
                "word_color": '#eeeeee',
                "speed": { "min": 0.5, "max": 1.5 },
            },
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        {
            "type": "seek-and-find",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Toca on està el cor a la imatge.",
            "image": "@@@dam:@image@9cd37bcc-878c-4dd1-a41a-c966a2cf7823@e3d1ccd4-46f2-47dc-8558-6304d846172b@@@",
            "options": [
                {
                    "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 120
                    }
                },
                {
                    "id": 0, "is_right": "TRUE", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 60
                    }
                }
            ],
            "targets": [
                {
                    "id": 0, "order": 1,
                    "area": {
                        "x": 518, "y": 478, "radius": null
                    }
                }
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        {
            "type": "correspondence",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Títol d'activitat de tipus correspondència.",
            "text": "Contingut d'activitat de tipus correspondència",
            "options": [
                { "id": 0, "target_order": 1, "is_right": "TRUE" },
                { "id": 0, "target_order": 1, "is_right": "FALSE" }
            ],
            "targets": [
                { "id": 0, "order": 1 }
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        },
        // only for MHE
        {
            "type": ACTIVITY_TYPE.RANK,
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Posa les opcions en ordre.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "title": "Primera" },
                { "id": 0, "target_order": 1, "is_right": "TRUE", "image": "", "title": "Segona" },
                { "id": 0, "target_order": 2, "is_right": "TRUE", "image": "", "title": "Tercera" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Permet treballar en grups, es poden moure, però alguns estan d'esquena a la pissarra." },
                { "id": 0, "order": 1, "title": "Permet treballar en grups, mirar la pissarra i facilita la mobilitat de la majoria dels alumnes." },
                { "id": 0, "order": 2, "title": "Permet treballar en grups, es poden moure bé, però no tots veuràn bé la pissarra." }
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Ho sentim, la teva resposta no és correcta." },
                { "success_percentage": 100, "text": "Molt bé!" }
            ]
        }
    ]},

    //* Euskera
    {"eu-ES":[
        {
            "type": "single-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "text": "Zein da munduko ibilbide luzeena?",
            "title": "Zuzeneko erantzuna hautatu",
            "options": [
                { "id": 0, "is_right": "FALSE", "title": "Yangtze" },
                { "id": 0, "is_right": "FALSE", "title": "Amazon" },
                { "id": 0, "is_right": "TRUE", "title": "Nilo" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Barkatu, zure erantzuna ez da zuzena." },
                { "success_percentage": 100, "text": "Ondo egin!" }
            ]
        },
        {
            "type": "multiple-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Aukeratu erantzun zuzenak.",
            "text": "Zein dira Eguzki Sistemako planetak?",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Neptune" },
                { "id": 0, "is_right": "FALSE", "title": "Ilargia" },
                { "id": 0, "is_right": "TRUE", "title": "Jupiter" },
                { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
                { "id": 0, "is_right": "FALSE", "title": "Draugr" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Barkatu, zure erantzuna ez da zuzena." },
                { "success_percentage": 100, "text": "Ondo egin!" }
            ]
        },
        {
            "type": "match",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Uztaiak erantzun zuzeneko helmugarekin lotu.",
            "text": "Historiako Aroak kronologikoki ordenatu (berrienetik hasita)",
            "options": [
                { "id": 0, "target_order": 1, "title": "Aro Modernoa", "is_right": "TRUE" },
                { "id": 0, "target_order": 3, "title": "Aro Antzina", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "Aro Erdia", "is_right": "TRUE" },
            ],
            "targets": [
                { "id": 0, "order": 1, "title": "Lehenengo" },
                { "id": 0, "order": 2, "title": "Bigarren" },
                { "id": 0, "order": 3, "title": "Hirugarren" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Barkatu, zure erantzuna ez da zuzena." },
                { "success_percentage": 100, "text": "Ondo egin!" }
            ]
        },
        {
            "type": "true-false",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Hautatu egia edo gezurra dela esan.",
            "text": "Kimiko erreakzioaren hasierako substantzak erreakzioak deiturikoak dira",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Egia" },
                { "id": 0, "is_right": "FALSE", "title": "Gezurra" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Barkatu, zure erantzuna ez da zuzena." },
                { "success_percentage": 100, "text": "Ondo egin!" }
            ]
        },
        {
            "type": "input",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Idatzi zure erantzuna behean.",
            "text": "Zein da bizitzaaren unitate txikiena izena?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["zelaia"] },
            ],
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ],
        },
        {
            "type": "input-long",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Idatzi zure erantzuna behean.",
            "text": "Zein da bizitzaaren unitate txikiena izena?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": [] },
            ],
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ],
        },
        {
            "type": "fit-word",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Arrastatu hitzak beren edukietara.",
            "text": "Ekologia da bizitza, barnean dauden pertsonen arteko harremanen ikasketak {1}, besteak beste, eta haien {2} ingurukoak",
            "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
            "options": [
                { "id": 0, "target_order": 1, "title": "organismoa", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "ingurumena", "is_right": "TRUE"},
            ],
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ],
        },
        {
            "type": "fill-the-blanks",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Bete espazioak esaldia osatzeko:",
            "text": "Kimiko erreakzio baten hasierako substantziak erreakzioaren izenburua da",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["algoritmoa"] },
                { "id": 0, "order": 2, "accepted_values": ["lan"] },
            ],
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ]
        },
        {
            "type": "order-chronology",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Zordekatu irudiak hainbat kriterioetara egokitu.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "", "image_url": "" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Taldean lan egitea ahalbidetzen du, mugitzea, baina batzuek atzean dute plousa." },
                { "id":0, "order":1, "title":"Taldean lan egitea, plousak ikusteko eta ikasle gehien mobilak izateko aukera ematen du"},
                { "id":0, "order":2, "title":"Taldean lan egitea, ondo mugitzen dira, baina ez guztiak ikusiko dute plousa" }
            ],
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ]
        },
        {
            "type": "word-search",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Bilatu fruitu hauek: banana, Udarea eta kokonutua.",
            "text": "",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["banana", "Udarea", "kokonutua"] },
            ],
            "props": {
                "time": 120, "background_image": { }
            },
            "matrix": {
                "data": [],
                "props": {
                    "alphabet": "EN",
                    "rules": {
                        "allow_diagonal": false,
                        "allow_horizontal": true,
                        "allow_inverse": false,
                        "allow_vertical": true
                    },
                    "size": {
                        "height": 10,
                        "width": 10
                    }
                },
            },
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ]
        },
        {
            "type": "word-catcher",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Hartu fruitua.",
            "targets": [
                {
                    "id": 0,
                    "order": 1,
                    "accepted_values": ["Banana"],
                    "unaccepted_values": ["Pizza", "Tipula", "Sandwich", "Gazta", "Gurina", "Zopa", "Arrautza", "Pasta", "Tomatea", "Letxuga", "Ogia"]
                }
            ],
            "background": null,
            "props": {
                "time": 120,
                "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
                "word_color": '#eeeeee',
                "speed": { "min": 0.5, "max": 1.5 },
            },
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ]
        },
        {
            "type": "seek-and-find",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Sakatu non dagoen bihotza irudian.",
            "image": "@@@dam:@image@9cd37bcc-878c-4dd1-a41a-c966a2cf7823@e3d1ccd4-46f2-47dc-8558-6304d846172b@@@",
            "options": [
                {
                    "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 120
                    }
                },
                {
                    "id": 0, "is_right": "TRUE", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 60
                    }
                }
            ],
            "targets": [
                {
                    "id": 0, "order": 1,
                    "area": {
                        "x": 518, "y": 478, "radius": null
                    }
                }
            ],
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ]
        },
        {
            "type": "correspondence",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Korrespondentzia probako jarduera izenburua.",
            "text": "Korrespondentzia probako jarduera edukia",
            "options": [
                { "id": 0, "target_order": 1, "is_right": "TRUE" },
                { "id": 0, "target_order": 1, "is_right": "FALSE" }
            ],
            "targets": [
                { "id": 0, "order": 1 }
            ],
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ]
        },
        {
            "type": "ACTIVITY_TYPE.RANK",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Antolatu aukerak ordenan.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "title": "Lehenengo" },
                { "id": 0, "target_order": 1, "is_right": "TRUE", "image": "", "title": "Bigarren" },
                { "id": 0, "target_order": 2, "is_right": "TRUE", "image": "", "title": "Hirugarren" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Taldean lan egitea ahalbidetzen du, mugitzea, baina batzuek atzean dute plousa." },
                { "id": 0, "order": 1, "title": "Taldean lan egitea, plousak ikusteko eta ikasle gehien mobilak izateko aukera ematen du." },
                { "id": 0, "order": 2, "title": "Taldean lan egitea, ondo mugitzen dira, baina ez guztiak ikusiko dute plousa." }
            ],
            "feedbacks": [
                { "text": "Barkatu, zure erantzuna ez da zuzena.", "success_percentage": 0 },
                { "text": "Ondo egin!", "success_percentage": 100 }
            ]
        }
    ]},

    //* Gallego
    {"gl-Es":[
        {
            "type": "single-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "text": "Cal é o nome do río máis longo do mundo?",
            "title": "Seleccione a resposta correcta",
            "options": [
                { "id": 0, "is_right": "FALSE", "title": "Yangtsé" },
                { "id": 0, "is_right": "FALSE", "title": "Amazonas" },
                { "id": 0, "is_right": "TRUE", "title": "Nilo" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Desculpa, a túa resposta non é correcta." },
                { "success_percentage": 100, "text": "Ben feito!" }
            ]
        },
        {
            "type": "multiple-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Seleccione as respostas correctas.",
            "text": "Cales son os planetas do Sistema Solar?",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Neptuno" },
                { "id": 0, "is_right": "FALSE", "title": "Lúa" },
                { "id": 0, "is_right": "TRUE", "title": "Xúpiter" },
                { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
                { "id": 0, "is_right": "FALSE", "title": "Draugr" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Desculpa, a túa resposta non é correcta." },
                { "success_percentage": 100, "text": "Ben feito!" }
            ]
        },
        {
            "type": "match",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Relacione as opcións co seu obxectivo correcto.",
            "text": "Ordene estas épocas da Historia cronoloxicamente (a máis recente primeiro)",
            "options": [
                { "id": 0, "target_order": 1, "title": "Idade Moderna", "is_right": "TRUE" },
                { "id": 0, "target_order": 3, "title": "Idade Antiga", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "Idade Media", "is_right": "TRUE" },
            ],
            "targets": [
                { "id": 0, "order": 1, "title": "Primeira" },
                { "id": 0, "order": 2, "title": "Segunda" },
                { "id": 0, "order": 3, "title": "Terceira" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Desculpa, a túa resposta non é correcta." },
                { "success_percentage": 100, "text": "Ben feito!" }
            ]
        },
        {
            "type": "true-false",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Seleccione se a afirmación é verdadeira ou falsa.",
            "text": "As substancias iniciais dunha reacción química chámanse reactantes",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Verdadeira" },
                { "id": 0, "is_right": "FALSE", "title": "Falsa" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Desculpa, a túa resposta non é correcta." },
                { "success_percentage": 100, "text": "Ben feito!" }
            ]
        },
        {
            "type": "input",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Escriba a súa resposta a continuación.",
            "text": "Cal é o nome da unidade máis pequena da vida?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["célula"] },
            ],
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ],
        },
        {
            "type": "input-long",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Escriba a súa resposta a continuación.",
            "text": "Cal é o nome da unidade máis pequena da vida?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": [] },
            ],
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ],
        },
        {
            "type": "fit-word",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Arrastre cada palabra ao seu recipiente.",
            "text": "A ecoloxía é o estudo das relacións entre os seres vivos {1}, incluíndo os humanos, e o seu {2} físico.",
            "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
            "options": [
                { "id": 0, "target_order": 1, "title": "organismo", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "medio ambiente", "is_right": "TRUE" },
            ],
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ],
        },
        {
            "type": "fill-the-blanks",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Encha os espazos en branco para completar a seguinte oración:",
            "text": "Un {1} é simplemente un conxunto de pasos usados para completar unha {2} específica.",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["algoritmo"] },
                { "id": 0, "order": 2, "accepted_values": ["tarefa"] },
            ],
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ]
        },
        {
            "type": "order-chronology",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Ordene as imaxes segundo o maior número destes criterios: facilitar a súa mobilidade, a mobilidade dos seus estudantes, o traballo en grupo e mirar a táboa",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "", "image_url": "" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Permite traballar en grupos, poden moverse, pero algúns deles teñen as costas para a pizarra." },
                { "id": 0, "order": 1, "title": "Permite traballar en grupos, mirando a pizarra e facilita a mobilidade da maioría dos estudantes." },
                { "id": 0, "order": 2, "title": "Permite traballar en grupos, poden moverse ben, pero non todos verán ben a pizarra." }
            ],
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ]
        },
        {
            "type": "word-search",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Busque estas froitas: banana, pera e coco.",
            "text": "",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["banana", "pera", "coco"] },
            ],
            "props": {
                "time": 120, "background_image": {}
            },
            "matrix": {
                "data": [],
                "props": {
                    "alphabet": "EN",
                    "rules": {
                        "allow_diagonal": false,
                        "allow_horizontal": true,
                        "allow_inverse": false,
                        "allow_vertical": true
                    },
                    "size": {
                        "height": 10,
                        "width": 10
                    }
                },
            },
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ]
        },
        {
            "type": "word-catcher",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Captura a froita.",
            "targets": [
                {
                    "id": 0,
                    "order": 1,
                    "accepted_values": ["Plátano"],
                    "unaccepted_values": ["Pizza", "Cebola", "Sanduíche", "Queixo", "Manteiga", "Sopa", "Ovo", "Massa", "Tomate", "Alface", "Pão"]
                }
            ],
            "background": null,
            "props": {
                "time": 120,
                "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
                "word_color": '#eeeeee',
                "speed": { "min": 0.5, "max": 1.5 },
            },
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ]
        },
        {
            "type": "seek-and-find",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Toca onde está o corazón na imaxe.",
            "image": "@@@dam:@image@9cd37bcc-878c-4dd1-a41a-c966a2cf7823@e3d1ccd4-46f2-47dc-8558-6304d846172b@@@",
            "options": [
                {
                    "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 120
                    }
                },
                {
                    "id": 0, "is_right": "TRUE", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 60
                    }
                }
            ],
            "targets": [
                {
                    "id": 0, "order": 1,
                    "area": {
                        "x": 518, "y": 478, "radius": null
                    }
                }
            ],
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ]
        },
        {
            "type": "correspondence",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Título da actividade de proba de correspondencia.",
            "text": "Contido da actividade de proba de correspondencia",
            "options": [
                { "id": 0, "target_order": 1, "is_right": "TRUE" },
                { "id": 0, "target_order": 1, "is_right": "FALSE" }
            ],
            "targets": [
                { "id": 0, "order": 1 }
            ],
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ]
        },
        {
            "type": "ACTIVITY_TYPE.RANK",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Ordena as opcións en orde.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "title": "Primeiro" },
                { "id": 0, "target_order": 1, "is_right": "TRUE", "image": "", "title": "Segundo" },
                { "id": 0, "target_order": 2, "is_right": "TRUE", "image": "", "title": "Terceiro" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Permite traballar en grupos, poden moverse, pero algúns deles teñen as costas para a pizarra." },
                { "id": 0, "order": 1, "title": "Permite traballar en grupos, mirando a pizarra e facilita a mobilidade da maioría dos estudantes." },
                { "id": 0, "order": 2, "title": "Permite traballar en grupos, moven ben, pero non todos verán ben a pizarra." }
            ],
            "feedbacks": [
                { "text": "Desculpa, a túa resposta non é correcta.", "success_percentage": 0 },
                { "text": "Ben feito!", "success_percentage": 100 }
            ]
        }
    ]},

    //* Inglés
    {"en-EN": [
        {
            "type": "single-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "text": "What is the name of the longest river in the world?",
            "title": "Select the correct answer",
            "options": [
                { "id": 0, "is_right": "FALSE", "title": "Yangtze" },
                { "id": 0, "is_right": "FALSE", "title": "Amazon" },
                { "id": 0, "is_right": "TRUE", "title": "Nile" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Sorry, your answer is not correct." },
                { "success_percentage": 100, "text": "Well done!" }
            ]
        },
        {
            "type": "multiple-choice",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Select the answers that are correct.",
            "text": "Which are planets of the Solar System?",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "Neptune" },
                { "id": 0, "is_right": "FALSE", "title": "Moon" },
                { "id": 0, "is_right": "TRUE", "title": "Jupiter" },
                { "id": 0, "is_right": "FALSE", "title": "HR 2562 b" },
                { "id": 0, "is_right": "FALSE", "title": "Draugr" },

            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Sorry, your answer is not correct." },
                { "success_percentage": 100, "text": "Well done!" }
            ]
        },
        // only for MHE
        {
            "type": "match",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Match the options with their correct target.",
            "text": "Order these Ages of History chronologically (more recent first)",
            "options": [
                { "id": 0, "target_order": 1, "title": "Modern Age", "is_right": "TRUE" },
                { "id": 0, "target_order": 3, "title": "Ancient Age", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "Medieval Age", "is_right": "TRUE" },
            ],
            "targets": [
                { "id": 0, "order": 1, "title": "First" },
                { "id": 0, "order": 2, "title": "Second" },
                { "id": 0, "order": 3, "title": "Third" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Sorry, your answer is not correct." },
                { "success_percentage": 100, "text": "Well done!" }
            ]
        },
        {
            "type": "true-false",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Select if the statement is true or false.",
            "text": "The starting substances of a chemical reaction are called the reactants",
            "options": [
                { "id": 0, "is_right": "TRUE", "title": "True" },
                { "id": 0, "is_right": "FALSE", "title": "False" },
            ],
            "feedbacks": [
                { "success_percentage": 0, "text": "Sorry, your answer is not correct." },
                { "success_percentage": 100, "text": "Well done!" }
            ]
        },
        // only for MHE
        {
            "type": "input",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Write your answer below.",
            "text": "What is the name of the smallest unit of life?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["cell"] },
            ],
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ],
        },
        {
            "type": "input-long",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Write your answer below.",
            "text": "What is the name of the smallest unit of life?",
            "options": [],
            "targets": [
                { "id": 0, "order": 1, "accepted_values": [] },
            ],
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ],
        },
        // only for MHE
        {
            "type": "fit-word",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Drag each word into its container.",
            "text": "Ecology is the study of the relationships between living {1}, including humans, and their physical {2}.",
            "targets": [{ "id": 0, "order": 1 }, { "id": 0, "order": 2 }],
            "options": [
                { "id": 0, "target_order": 1, "title": "organism", "is_right": "TRUE" },
                { "id": 0, "target_order": 2, "title": "environment", "is_right": "TRUE" },
            ],
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ],
        },
        {
            "type": "fill-the-blanks",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Fill in the blanks to complete the following sentence:",
            "text": "An {1} is simply a set of steps used to complete a specific {2}.",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["algorithm"] },
                { "id": 0, "order": 2, "accepted_values": ["task"] },
            ],
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ]
        },
        {
            "type": "order-chronology",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Sort the images according to the highest number of these criteria: facilitating your mobility, your students' mobility, group work and looking at the board.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 1, "is_right": "FALSE", "image": "", "image_url": "" },
                { "id": 0, "target_order": 2, "is_right": "FALSE", "image": "", "image_url": "" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Allows working in groups, they can move, but some of them have their back to the blackboard." },
                { "id": 0, "order": 1, "title": "Allows working in groups, looking at the blackboard and facilitates the mobility of most of the students." },
                { "id": 0, "order": 2, "title": "Allows working in groups, they can move well, but not all will see the blackboard well." }
            ],
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ]
        },
        {
            "type": "word-search",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Look for these fruits: banana, pear, and coconut.",
            "text": "",
            "targets": [
                { "id": 0, "order": 1, "accepted_values": ["banana", "pear", "coconut"] },
            ],
            "props": {
                "time": 120, "background_image": {}
            },
            "matrix": {
                "data": [],
                "props": {
                    "alphabet": "EN",
                    "rules": {
                        "allow_diagonal": false,
                        "allow_horizontal": true,
                        "allow_inverse": false,
                        "allow_vertical": true
                    },
                    "size": {
                        "height": 10,
                        "width": 10
                    }
                },
            },
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ]
        },
        {
            "type": "word-catcher",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Catch the fruit.",
            "targets": [
                {
                    "id": 0,
                    "order": 1,
                    "accepted_values": ["Banana"],
                    "unaccepted_values": ["Pizza", "Onion", "Sandwich", "Cheese", "Butter", "Soup", "Egg", "Pasta", "Tomato", "Lettuce", "Bread"]
                },
            ],
            "background": null,
            "props": {
                "time": 120,
                "background_image": { "image": "/assets/images/word-catcher/sunset-in-sandy-beach-standard-theme.png", "is_external": false },
                "word_color": '#eeeeee',
                "speed": { "min": 0.5, "max": 1.5 },
            },
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ]
        },
        {
            "type": "seek-and-find",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Touch where the heart is in the image.",
            "image": "@@@dam:@image@9cd37bcc-878c-4dd1-a41a-c966a2cf7823@e3d1ccd4-46f2-47dc-8558-6304d846172b@@@",
            "options": [
                {
                    "id": 0, "is_right": "PARTIALLY", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 120
                    }
                },
                {
                    "id": 0, "is_right": "TRUE", "target_order": 1,
                    "area": {
                        "x": null, "y": null, "radius": 60
                    }
                }
            ],
            "targets": [
                {
                    "id": 0, "order": 1,
                    "area": {
                        "x": 518, "y": 478, "radius": null
                    }
                }
            ],
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ]
        },
        {
            "type": "correspondence",
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Título de actividad prueba correspondence.",
            "text": "Contenido de actividad prueba correspondence",
            "options": [
                { "id": 0, "target_order": 1, "is_right": "TRUE" },
                { "id": 0, "target_order": 1, "is_right": "FALSE" }
            ],
            "targets": [
                { "id": 0, "order": 1 }
            ],
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ]
        },
        // only for MHE
        {
            "type": ACTIVITY_TYPE.RANK,
            "status": "ACTIVE",
            "version": 1,
            "level": 1,
            "title": "Sort the options in order.",
            "text": "",
            "options": [
                { "id": 0, "target_order": 3, "is_right": "TRUE", "image": "", "title": "First" },
                { "id": 0, "target_order": 1, "is_right": "TRUE", "image": "", "title": "Second" },
                { "id": 0, "target_order": 2, "is_right": "TRUE", "image": "", "title": "Third" }
            ],
            "targets": [
                { "id": 0, "order": 3, "title": "Allows working in groups, they can move, but some of them have their back to the blackboard." },
                { "id": 0, "order": 1, "title": "Allows working in groups, looking at the blackboard and facilitates the mobility of most of the students." },
                { "id": 0, "order": 2, "title": "Allows working in groups, they can move well, but not all will see the blackboard well." }
            ],
            "feedbacks": [
                { "text": "Sorry, your answer is not correct.", "success_percentage": 0 },
                { "text": "Well done!", "success_percentage": 100 }
            ]
        }
    ]}
]


export const MHE_ACTIVITIES_GLOBAL_FEEDBACK_MESSAGES = {
    "es-ES": ["Lo sentimos, tu respuesta no es correcta.", "Has completado esta actividad con una puntuación de al menos el ", "¡Muy bien!"],
    "en-EN": ["Sorry, your answer is not correct.", "You have completed this activity with a score of at least ", "Well done!"],
    "ca-ES": ["Ho sentim, la teva resposta no és correcta.", "Has completat aquesta activitat amb una puntuació d'almenys el" ,"Molt bé!"],
    "gl-Es": ["Desculpa, a túa resposta non é correcta.", "Completaches esta actividade cunha puntuación de polo menos", "Ben feito!"],
    "eu-ES": ["Barkatu, zure erantzuna ez da zuzena.", "Jarduera hau gutxienez puntuazio batekin burutu duzu", "Ondo egin!"],
}

