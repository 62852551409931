import {useEffect, useState} from 'react';
import HTMLReactParser from 'html-react-parser';
import { XInput } from '@ximdex/xui-react/material';
import { StyledTranslationSpan, StyledTranslationp, StyledDivCenterY, ExtraInfoText, StyledActivityCreationXCard, StyledMarginContent, StyledXOwlBlackIcon, StyledGreenXButton} from '../../../Styled-Components'
import EditorTinyMCE from '../../TinyMCE/EditorTinyMCE';
import { ACTIVITIES_ANSWERS_OPTIONS_SETTINGS, ACTIVITY_TRANSLATION_AI, AVAILABLE_TRANSLATION_LANGUAGES_BY_AI, IA_LOADING_TRANSLATION_TEXT, IA_NOT_WORKING_MESSAGE, IA_SUGGESTED_TRANSLATION_TEXT } from '../../../CONSTANTS';
import { CircularProgress } from '@mui/material';
import useMaxCharacters from '../../../hooks/useMaxCharacters';
import { ArrowDown, RefreshCcw } from 'lucide-react';

const StringTranslation = ({
    languageSelected,
    activityInfo, 
    activityTranslated, 
    setActivityTranslated,
    translatableField, 
    activityTranslatedByIA,
    localStorageUpdate,
    setlocalStorageUpdate,
    setTranslationUpdated,
    iaLoadingSuggestions,
    newLanguage,
    requestNewTranslation,
    getTitle,
    charCount,
    setCharCount
}) => {
    const settingValue = getTitle(translatableField)
    const {getMaxCharHeader, getMaxCharContent} = useMaxCharacters(activityInfo?.type)
    const maxChar = translatableField === 'title' ? getMaxCharHeader() : getMaxCharContent()
    const [totalChar, setTotalChar] = useState(0)
    const [editorId, setEditorId] = useState(0);


    useEffect(() => {
        if (charCount && editorId.length > 0) {
            let newCharCount = [...charCount] 
            let index = charCount.findIndex(item => item.id === editorId)
            let data = {id: editorId, exceed: totalChar > maxChar}
            if (index !== -1) {
                newCharCount[index] = data
            } else {
                newCharCount.push(data)
            }
            setCharCount(newCharCount)
        }
     
    }, [totalChar])

    const handleChangeTranslation = (value, editor) => {   
        if (editor && value.includes('<math xmlns="http://www.w3.org/1998/Math/MathML"')) {
            value = editor.getBody().getInnerHTML()
        } 
       
        setActivityTranslated(prevState => ({...prevState, [translatableField]: value}))
        if(setlocalStorageUpdate) setlocalStorageUpdate(!localStorageUpdate)
            setTranslationUpdated(false)
        
        setTotalChar(editor ? editor?.getContent({ format: 'text' }).length : value.length )
        setEditorId(editor?.id ?? settingValue)
    }

    const handleApplyIARecomendation = (iaTranslation) => {
        handleChangeTranslation(iaTranslation)
    }

    


    return (<>
        {activityInfo[translatableField] && 
        <StyledActivityCreationXCard
            isCollapsable={false}
            // backgroundHeader={true}
            isCollapsed={false}
            title={`${newLanguage ? 'Creating' : 'Editing'} language: ${languageSelected}`}
            // title={getTitle(translatableField)}
        >
            <StyledMarginContent>
                <p style={{textAlign:'left', color: 'grey', margin: '0'}}>
                    <StyledTranslationSpan>
                        Default language:
                    </StyledTranslationSpan> 
                    &nbsp;{HTMLReactParser(String(activityInfo[translatableField]))}
                </p>
                {ACTIVITY_TRANSLATION_AI && AVAILABLE_TRANSLATION_LANGUAGES_BY_AI.includes(languageSelected) &&
                <StyledDivCenterY>
                    <StyledXOwlBlackIcon src="./assets/images/xowl-logo4.png" />
                    <p style={{textAlign:'left', color: 'grey'}}>
                        <StyledTranslationp style={{color: '#43a1a2'}}>
                            <StyledTranslationSpan>

                                {IA_SUGGESTED_TRANSLATION_TEXT}</StyledTranslationSpan>
                            {iaLoadingSuggestions ? IA_LOADING_TRANSLATION_TEXT : (activityTranslatedByIA && activityTranslatedByIA[translatableField] !== "" 
                                ? 
                                HTMLReactParser(activityTranslatedByIA[translatableField]) 
                                : IA_NOT_WORKING_MESSAGE)}
                        </StyledTranslationp>     
                    </p>
                    {iaLoadingSuggestions ? 
                        <CircularProgress size={15}/>
                        :   
                        <>
                            <StyledGreenXButton
                                onClick={() => handleApplyIARecomendation(activityTranslatedByIA[translatableField])} 
                                size='small' style={{minWidth: 'unset', height:'100%', marginRight:'0.2em'}}
                                disabled={activityTranslatedByIA && activityTranslatedByIA[translatableField] !== "" ? false : true}
                                title={"Use this translation."}
                            >
                                <ArrowDown size={15}/>
                            </StyledGreenXButton>
                            <StyledGreenXButton
                                onClick={() => requestNewTranslation(translatableField)} 
                                size='small' style={{minWidth: 'unset', height:'100%'}}
                                disabled={iaLoadingSuggestions}
                                title={"Request a different translation."}
                            >
                                <RefreshCcw size={15}/>
                            </StyledGreenXButton>
                        </>
                        }
                </StyledDivCenterY>}

                        {translatableField === 'text' 
                        ?
                        <EditorTinyMCE 
                            language={languageSelected.split('-')[0]}
                            value={activityTranslated[translatableField]} 
                            handleEditorChange={(e,editor) => handleChangeTranslation(e, editor)} 
                            style={{ margin: '0 0 10px 0', width: '100%' }}
                            config={{
                                max_num_characters: maxChar,
                                wirisformulaeditorlang: languageSelected.split('-')[0],
                            }}
                        />
                        :
                        <XInput
                        placeholder="Enter translation"
                        style={{  margin: '0 0 10px 0', width: '100%' }}
                        size='normal'
                        fullWidth
                        multiline
                        rows={2}
                        value={activityTranslated[translatableField]}
                        onChange={(e) => handleChangeTranslation(e?.target?.value ?? '')}
                        inputProps={{ maxLength: maxChar }}
                        />
                    } 
                    <ExtraInfoText isError={totalChar > maxChar} >{totalChar} of {maxChar} characters</ExtraInfoText>
            </StyledMarginContent>
        </StyledActivityCreationXCard>}
</>);
}

export default StringTranslation;
