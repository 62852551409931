import ACTIVITY_TYPE from './Activities'

export const STATUS_OPTIONS = [
    { value: 'ALL', label: 'Show all: Active & Draft', color: '#000000' },
    { value: 'ACTIVE', label: 'Active', color: '#008000' },
    { value: 'DRAFT', label: 'Draft', color: '#FFFF00' },
    { value: 'ARCHIVED', label: 'Archived', color: '#FF0000' },
];

export const PAGINATION_OPTIONS = [
    { value: '12', label: '12' },
    { value: '24', label: '24' },
    { value: '32', label: '32' },
    { value: '48', label: '48' }
];

export const SORTING_OPTIONS = [
    { value: 'id&asc', label: 'Id: low to high' },
    { value: 'id&desc', label: 'Id: high to low' },
    { value: 'name&asc', label: 'Name: A to Z' },
    { value: 'name&desc', label: 'Name: Z to A' },
];

export const LANGUAGES_OPTIONS_SHOW_ALL = [
    { value: 'ALL', label: 'Show all' },
    { value: 'EN', label: 'English' },
    { value: 'ES', label: 'Spanish' },
];

export const LANGUAGES_OPTIONS = [
    { value: 'en-EN', label: 'English' },
    { value: 'es-ES', label: 'Spanish' },
];

export const ACTIVITIES_LIST_ALL_TYPES = [
    { value: ACTIVITY_TYPE.SINGLE_CHOICE, label: 'Single choice' },
    { value: ACTIVITY_TYPE.MULTIPLE_CHOICE, label: 'Multiple choice' },
    { value: ACTIVITY_TYPE.TRUE_FALSE, label: 'True/False' },
    { value: ACTIVITY_TYPE.FILL_THE_BLANKS, label: 'Fill in the blanks' },
    { value: ACTIVITY_TYPE.ORDER_CHRONOLOGY, label: 'Order chronology' },
    { value: ACTIVITY_TYPE.CORRESPONDENCE, label: 'Correspondence' },
    { value: ACTIVITY_TYPE.WORD_SEARCH, label: 'Word search' },
    { value: ACTIVITY_TYPE.WORD_CATCHER, label: 'Word catcher' },
    { value: ACTIVITY_TYPE.SEEK_AND_FIND, label: 'Seek and find' },
];