import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getActivityById, getUserActivityById, updateActivity } from '../../services/xevalApi.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import ActivityEditTranslations from '../../components/ActivityCreation/ActivityEditTranslations';
import Preview from '../../components/Preview/Preview'
import SamplePreview from '../../components/Preview/SamplePreview'
import {
    ACTIVITIES_PREVIEWS,
    ACTIVITIES_GLOBAL_FEEDBACK,
    ACTIVITIES_ANSWERS_OPTIONS_SETTINGS,
    LANGUAGES_OPTIONS,
    ACTIVITY_TRANSLATION_AI,
} from '../../CONSTANTS';
import { 
    StyledXContainerPreview,
    StyledDivFlexStartWrap,
    StyledActivityEditionContainer
} from '../../Styled-Components';
import useSortOptionsTargets from '../../hooks/useSortOptionsTargets';
import useTranslations from '../../hooks/useTranslations';
import ActivityCreateData from '../../components/ActivityCreation/ActivityCreateData';
import ActivityHeaderCreation from '../../components/ActivityCreation/ActivityHeaderCreation';
import ActivityCreateTranslations from '../../components/ActivityCreation/ActivityCreateTranslations';

const ActivityCreation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const assessmentId = params.get('from-assessment-id')
    const { showSpinner, hideSpinner } = useSpinner();
    const activityId = parseInt(params.get('activity-id'));
    const [sortOptionsAndTargets, sortOptions] = useSortOptionsTargets();
    const { handleIATranslation } = useTranslations();

    // ACTIVITY DATA STATE CONTROLS
    const [activityInfo, setActivityInfo] = useState({ id: activityId, available_languages:{} });
    const [isActivityInfoUpdated, setIsActivityInfoUpdated] = useState(true);
    const [reload,setReload] = useState(false)
    const [userActivityId, setUserActivityId] = useState(null);

    // TRANSLATIONS STATE CONTROLS
    const [activityTranslation,setActivityTranslation] = useState({ id: activityId })
    const [activityTemplateTranslation, setActivityTemplateTranslation] =  useState(undefined);
    const [isActivityTranslationUpdated, setIsActivityTranslationUpdated] = useState(true);

    // IA STATE CONTROLS
    const [activityTranslatedByIA, setActivityTranslatedByIA] = useState(undefined);
    const [iaButtonDisabled, setIaButtonDisabled] = useState(false);
    const [iaLoadingSuggestions, setIaLoadingSuggestions] = useState(false);

    // LANGUAGES STATE CONTROLS
    const [languagesPendingToAdd, setLanguagesPendingToAdd] = useState([])
    const [languagesAvaliables, setLanguagesAvaliables] = useState([])
    const [languageSelected, setLanguageSelected] = useState({});
    const [newLanguageSelected, setNewLanguageSelected] = useState(undefined)
    const [addNewTranslation, setAddNewTranslation] = useState(false)


    // CHARS COUNT
    const [charCount, setCharCount] = useState([]);
    const [charsExceeded, setCharsExceeded] = useState(false);

    useEffect(() => {
        !activityId ? handleNoActivityIdFound() : getActivityInfo(activityId, true)
    }, [reload]);

    useEffect(() => {
        navigate(`/edit-activity?activity-id=${activityId}${assessmentId ? `&from-assessment-id=${assessmentId}` : '' }&lang=${newLanguageSelected?.value ? newLanguageSelected?.value : addNewTranslation ? 'new' : languageSelected.value}`)
    }, [languageSelected, addNewTranslation, newLanguageSelected])


    useEffect(() => {
        localStorage.setItem("create-translation", JSON.stringify({
            lang: ''
        }))
    }, [activityId]);


    const handleNoActivityIdFound = () => {
        alert("The activity id seems to be missing. Try again later.")
        navigate.goBack();
    }

    const getIATranslations = async(lang) => {
        if(!ACTIVITY_TRANSLATION_AI) return
        setIaLoadingSuggestions(true)
        await handleIATranslation(activityInfo, lang).then(res => {
            setActivityTranslatedByIA(res)
            setIaButtonDisabled(false)
            if(res.data == 'error:500') setIaButtonDisabled(true)
        }).catch(err => {
            setIaButtonDisabled(true)
        }).finally(() => {
            setIaLoadingSuggestions(false)
        })
    }

    const handleSetLangDropdown = (data) => {
        setLanguagesPendingToAdd(LANGUAGES_OPTIONS.filter(element => element.value !== data.language_default && !data.available_languages.includes(element.value)))

        let newDropdownValues = LANGUAGES_OPTIONS.filter(element => element.value !== data.language_default && data.available_languages.includes(element.value))
        newDropdownValues.unshift(LANGUAGES_OPTIONS.filter(lang => lang.value === data.language_default)[0])
        setLanguagesAvaliables(newDropdownValues)

        let langSelected = data.available_languages.includes(params.get('lang')) ? params.get('lang') : data.language_default
        setLanguageSelected(newDropdownValues.filter(lang => lang.value === langSelected)[0])

    }

    const getUserActivityId = async (activityInfoId) => {
        setUserActivityId(null);

        if (activityInfoId) {
            const activityId = await getUserActivityById(activityInfoId);

            if (activityId) {
                setUserActivityId(activityId)
            }
        }
    }

    const getActivityInfo = (currentId, isInit, langTabs) => {
        showSpinner()

        getActivityById(currentId).then(data => {
            let newData = { ...data }

            sortOptionsAndTargets(newData)
            newData["translatable_fields"] = ["name","title","text","options","targets","feedbacks"]
            
            // if (ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TO_SORT.includes(newData.type)) {
            //     newData = sortOptionsAndTargets(newData)
            // }
            // if (ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TO_SORT_OPTIONS_ONLY.includes(newData.type)) {
            //     newData = sortOptions(newData)
            // }

            setActivityInfo(newData)
            return newData
        }).then((data) => {
            isInit && handleSetLangDropdown(data) 
    
            getUserActivityId(data?.id)
            return data
        }).then((data) => {
            setIsActivityInfoUpdated(true)
        }).catch(err => {
            console.error(err)
            XPopUp({
                message:"There's been an error getting your activity. Please, try again.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner()
        });
    }

    const saveActivity = async () => {
        // showSpinner();
        if (charCount && charCount.some(char => char.exceed)) {
            XPopUp({
                message:"You have exceeded the allowed character limit.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
            return
        }
        if (ACTIVITIES_GLOBAL_FEEDBACK.includes(activityInfo?.type) && activityInfo.feedbacks && activityInfo.feedbacks.length > 0 ) activityInfo.feedbacks.sort((x, y) => x.success_percentage - y.success_percentage);

        const data = await updateActivity(activityInfo.id, activityInfo);
            try {
                if (data?.id) {
                    let newData = { ...data }
                    if (ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TO_SORT.includes(newData.type)) {
                        newData = sortOptionsAndTargets(newData)
                    }
                    if (ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.TO_SORT_OPTIONS_ONLY.includes(newData.type)) {
                        newData = sortOptions(newData)
                    }
                    setIsActivityInfoUpdated(true)
                    setActivityInfo(newData);
                    XPopUp({
                        message:"Activity saved successfully",
                        iconType:'success',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'ligthgreen',
                    })
                    return newData
                } else {
                    XPopUp({
                        message:"Looks like an error may have occured while updating this activity. Try again later.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }
            } catch (error) {
                console.error('There has been an error while updating this activity. Try again later.', error)
                XPopUp({
                    message:"Looks like an error may have occured while updating this activity. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }
            setReload(!reload)
    
    }

    return (
        <StyledDivFlexStartWrap style={{ height: 'calc(100VH - 82px)', width:'100%', alignItems:'flex-start', margin: '0', overflow: 'hidden'}}>
            <div
                style={{ backgroundColor: '#eeeeee', width: '70%'}}
            >
                <ActivityHeaderCreation
                    activityInfo={activityInfo}
                    languageSelected={languageSelected}
                    setIsActivityTranslationUpdated={setIsActivityTranslationUpdated}
                    setLanguageSelected={setLanguageSelected}
                    activityTranslation={activityTranslation}
                    saveActivity={saveActivity}
                    reload={reload}
                    setReload={setReload}
                    activityTemplateTranslation={activityTemplateTranslation}
                    setActivityTemplateTranslation={setActivityTemplateTranslation}
                    assessmentId={assessmentId}
                    activityTranslatedByIA={activityTranslatedByIA}
                    iaLoadingSuggestions={iaLoadingSuggestions}
                    iaButtonDisabled={iaButtonDisabled}
                    isActivityTranslationUpdated={isActivityTranslationUpdated}
                    isActivityInfoUpdated={isActivityInfoUpdated}
                    languagesAvaliables={languagesAvaliables}
                    setAddNewTranslation={setAddNewTranslation}
                    addNewTranslation={addNewTranslation}
                    setNewLanguageSelected={setNewLanguageSelected}
                    languagesPendingToAdd={languagesPendingToAdd}
                    charCount={charCount}
                />
                {addNewTranslation && !newLanguageSelected?.value 
                ?
                    <StyledActivityEditionContainer>
                        <></>
                    </StyledActivityEditionContainer>
                : newLanguageSelected?.value ? 
                    <ActivityCreateTranslations
                        languagesPendingToAdd={languagesPendingToAdd}
                        activityInfo={activityInfo}
                        setIsActivityTranslationUpdated={setIsActivityTranslationUpdated}
                        setActivityTranslation={setActivityTranslation}
                        activityTranslatedByIA={activityTranslatedByIA}
                        setActivityTemplateTranslation={setActivityTemplateTranslation}
                        activityTemplateTranslation={activityTemplateTranslation}
                        getIATranslations={getIATranslations}
                        iaLoadingSuggestions={iaLoadingSuggestions}
                        newLanguageSelected={newLanguageSelected}
                        setNewLanguageSelected={setNewLanguageSelected}
                        setActivityTranslatedByIA={setActivityTranslatedByIA}
                        setIaLoadingSuggestions={setIaLoadingSuggestions}
                        charCount={charCount}
                        setCharCount={setCharCount}
                    />   
                : (activityInfo?.id && activityInfo?.id !== 0 && activityInfo?.type) &&
                    activityInfo?.language_default === languageSelected.value 
                ? 
                    <ActivityCreateData
                        userActivityId={userActivityId}
                        activityInfo={activityInfo}
                        setActivityInfo={setActivityInfo}
                        setIsActivityInfoUpdated={setIsActivityInfoUpdated}
                        saveActivity={saveActivity}
                        charCount={charCount}
                        setCharCount={setCharCount}
                    />
                : 
                    <ActivityEditTranslations
                        activityInfo={activityInfo}
                        setActivityInfo={setActivityInfo}
                        languageSelected={languageSelected}
                        activityTranslatedByIA={activityTranslatedByIA}
                        setActivityTranslation={setActivityTranslation}
                        setActivityTemplateTranslation={setActivityTemplateTranslation}
                        activityTemplateTranslation={activityTemplateTranslation}
                        setIsActivityTranslationUpdated={setIsActivityTranslationUpdated}
                        getIATranslations={getIATranslations}
                        iaLoadingSuggestions={iaLoadingSuggestions}
                        setActivityTranslatedByIA={setActivityTranslatedByIA}
                        setIaLoadingSuggestions={setIaLoadingSuggestions}
                        charCount={charCount}
                        setCharCount={setCharCount}
                    />
                }
            </div>
            {ACTIVITIES_PREVIEWS.SHOW_PREVIEW.includes(activityInfo?.type) 
                ? <Preview typeOfPreview='activity' getUserId={getUserActivityId} userId={userActivityId} />
                : <SamplePreview 
                    type={activityInfo?.type} 
                    showPreview={(ACTIVITIES_PREVIEWS.UNDER_CONSTRUCTION.includes(activityInfo?.type) || !ACTIVITIES_PREVIEWS.NOT_AVAILABLE.includes(activityInfo?.type)) ? true : false}
                    />
            }
        </StyledDivFlexStartWrap >
    )
}

export default ActivityCreation;
