import XthemeProvider from "./XthemeProvider/XthemeProvider";
import XthemeContext from "./XthemeProvider/XthemeContext";
import UserProvider from "./UserProvider/UserProvider";
import UserContext from "./UserProvider/UserContext";

const Providers = ({ children }) => {
    return (
        <XthemeProvider>
            <UserProvider>
                {children}
            </UserProvider>
        </XthemeProvider>
    )
}

export default Providers;

export {
    XthemeContext,
    UserContext
}
