import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    StyledDivFlexWrap, 
    StyledActivityCardsContainer, 
    StyledPageContainer,
    StyledFontAwesomeIcon,
    StyledNewActivityItemButton,
    StyledDivCenterY,
    StyledCreateNewActivityHeader,
    StyledGreenXButton
} from '../../Styled-Components/index';
import { StyledCardTitle, StyledCardText } from '../../Styled-Components/Paragraph';
import { fetchActivityTypes, createNewActivity } from '../../services/xevalApi.service';
import { XBox, XButton, XDropdown } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { ACTIVITIES_ANSWERS_TEMPLATE, LANGUAGES_OPTIONS } from '../../CONSTANTS';
import useModals from '../../hooks/useSweetAlert';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ActivitySelector = (props) => {
    const navigate = useNavigate()
    const {modal} = useModals()

    const { showSpinner, hideSpinner } = useSpinner()
    const [activities, setActivities] = useState([])
    const [defaultLang, setDefaultLang] = useState({label:'Select language', value: 'default'})


    useEffect(() => {
        getActivityTypes()
    }, []);

    const getActivityTypes = async () => {
        showSpinner();
        await fetchActivityTypes().then(data => {
            setActivities(data);
        }).catch(err => {
            console.error(err)
            XPopUp({
                message:"There's been an error loading your activities. Please, try again.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner();
        });
    }

    const handleLangChange = (event) => {
        const newLang = LANGUAGES_OPTIONS.find((option) => option.label === event.target.textContent)
        setDefaultLang(newLang)
    }

    const createActivity = async (activityType) => {
        showSpinner()
        await createNewActivity({activityType: activityType, activityLang: defaultLang?.value})
        .then(data => {
            if (data?.id) {
                return data?.id
            } else {
                XPopUp({
                    message:"There has been an error while creating this activity. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }
        }).then(activityId =>{
            if (activityId) navigate(`/edit-activity?activity-id=${activityId}&lang=${defaultLang.value}`);
        }).catch(err => {
            console.error("error", err);
            XPopUp({
                message:"There's been an error. Please, try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner()
        })
    }

    const handleActivityTypeSelect = (activityType) => {
        modal(
            `Are you sure you wish to create an activity type ${activityType} in ${defaultLang.label}?`,
            `Create activity`, 
            () => createActivity(activityType)
        )
    }

    return (
        <StyledPageContainer>
            <StyledCreateNewActivityHeader>
                <XButton 
                    onClick={() => navigate(-1)}
                    title={"Go back"}
                    style={{padding: '5px 20px'}}
                    >
                    <StyledFontAwesomeIcon icon={faArrowLeft} style={{marginRight: '8px'}} />
                    CANCEL
                </XButton>
                
                <h2 style={{marginLeft: '2em'}}>
                    Create New Activity
                </h2>

                {defaultLang.value !== 'default' ?
                    <XDropdown
                        onChange={(e) => handleLangChange(e)}
                        value={defaultLang}
                        options={LANGUAGES_OPTIONS}
                        multiple={false}
                        hasCheckboxes={false}
                        disableClearable
                        label="Default language"
                        labelOptions='label'
                        width='100%'
                        bgColor='100'
                        style={{maxWidth: '200px'}}
                    />: <div style={{minWidth: '200px'}}></div>
                }
            </StyledCreateNewActivityHeader>
            

            {defaultLang.value === 'default' ?
                <StyledDivCenterY style={{flexDirection:'column', height:'80%', width: '100%', justifyContent:'center'}}>
                    <p style={{ textAlign: 'center' }}>
                        Select the default language of the new activity:
                    </p>

                    <XDropdown
                        onChange={(e) => handleLangChange(e)}
                        value={defaultLang}
                        options={LANGUAGES_OPTIONS}
                        multiple={false}
                        hasCheckboxes={false}
                        disableClearable
                        label="Default language"
                        labelOptions='label'
                        width='100%'
                        bgColor='100'
                        style={{maxWidth: '200px', marginBottom: '20px'}}
                    />
                </StyledDivCenterY>
            :
                <>
                    <p style={{ textAlign: 'center', marginTop: '2em', fontSize: '20px' }}>
                        Select the activity:
                    </p>
                    <StyledDivFlexWrap style={{marginTop:'0.5em', padding:'1em'}}>
                        {activities.map((activity, index) => (
                            ACTIVITIES_ANSWERS_TEMPLATE.FINISHED.includes(activity.label) ? 
                            (<StyledNewActivityItemButton
                                key={`activity-selector-${index}`}
                                id={activity?.id ?? index}
                                onClick={() => {handleActivityTypeSelect(activity.label)}}
                            >
                                <StyledActivityCardsContainer>
                                    <img style={{ width: '80px', height: '80px', marginTop:'15px' }} src='./assets/images/activity_icon.png' />
                                    <StyledCardTitle>{activity?.title}</StyledCardTitle>
                                    <StyledCardText>{activity?.description}</StyledCardText>
                                    {(activity?.title === "Input Long" || activity?.label === "input-long" || activity?.id === 21) && 
                                        <StyledCardText style={{fontSize: '0.8rem', margin: 0, color: '#f6ab0e'}}>
                                            <StyledFontAwesomeIcon
                                                icon={['fa', 'triangle-exclamation']}
                                                hasMarginRight size='1x'
                                                isWarning
                                            />
                                            This is not an interactive activity.
                                        </StyledCardText>
                                    }
                                </StyledActivityCardsContainer>
                            </StyledNewActivityItemButton>)
                            :
                            (<XBox
                                key={`activity-selector-${index}`}
                                id={activity?.id ?? index}
                                style={{ width: '200px' }}>
                                <StyledActivityCardsContainer>
                                    <img style={{ width: '80px', height: '80px' }} src='./assets/images/activity_icon.png' />
                                    <StyledCardTitle>{activity?.title}</StyledCardTitle>
                                    <StyledCardText>{activity?.description}</StyledCardText>
                                    <XButton disabled>Coming Soon</XButton>
                                </StyledActivityCardsContainer>
                            </XBox>)
                        ))}
                    </StyledDivFlexWrap>
                </>
            }
        </StyledPageContainer>

    )
}

export default ActivitySelector;
