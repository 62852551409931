import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { createAssessment } from '../../services/xevalApi.service';
import { StyledCenteredXYDiv, StyledCreateNewActivityHeader, StyledDivCenterY, StyledDivFlexStartWrap, StyledPageContainer } from '../../Styled-Components/Container';
import { XButton, XDropdown } from '@ximdex/xui-react/material';
import { LANGUAGES_OPTIONS } from '../../CONSTANTS';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import { StyledFontAwesomeIcon } from '../../Styled-Components';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ActivitySelector = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { showSpinner, hideSpinner } = useSpinner()
    const [defaultLang, setDefaultLang] = useState(LANGUAGES_OPTIONS[0])
    const assessmentToDuplicate = location?.state?.assessment || undefined

    const handleLangChange = (event) => {
        const newLang = LANGUAGES_OPTIONS.find((option) => option.label === event.target.textContent)
        setDefaultLang(newLang)
    }

    const handleAssessmentBody = () => {
        let body = {}

        if (assessmentToDuplicate) {
            let assessmentCopy = { ...assessmentToDuplicate }
            
            const filteredActivities = assessmentCopy.activities.map((activity) => {
                return {id: activity.id, weight: activity.weight, order: activity.order}
            })
            
            assessmentCopy.title = `Copy: ${assessmentToDuplicate?.title || 'Assessment #' + assessmentToDuplicate?.id}`
            assessmentCopy.status = "DRAFT"
            assessmentCopy.language_default = defaultLang.value
            assessmentCopy.activities = filteredActivities
            delete assessmentCopy.custom_client_id
            delete assessmentCopy.isCollapsed
            delete assessmentCopy.id
            delete assessmentCopy.created_at
            delete assessmentCopy.updated_at

            body = assessmentCopy
        } else {
            body = {
                // REFACTOR: Include dynamic creator_id once login is implemented
                creator_id: 1,
                title: "",
                language_default: defaultLang.value
            }
        }

        return body
    }

    const handleCreateAssessment = async () => {
        showSpinner()

        const body = await handleAssessmentBody()

        createAssessment(body).then(data => {
            if (data?.id) {
                return data.id;
            } else {
                XPopUp({
                    message:"There has been an error while creating this activity. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }
        }).then(newId => {
            if (newId) navigate(`/edit-assessment?assessment-id=${newId}`);
        }).catch(err => {
            console.error(err);
            XPopUp({
                message:"There's been an error. Please, try again later",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }).finally(() => {
            hideSpinner();
        })
    }

    return (
        <StyledPageContainer>
            <StyledCreateNewActivityHeader>
                <XButton 
                    onClick={() => navigate(-1)}
                    title={"Go back"}>
                    <StyledFontAwesomeIcon icon={faArrowLeft} style={{marginRight: '8px'}} />
                    CANCEL
                </XButton>
                

                <h2 style={{marginRight: '4em'}}>
                    {assessmentToDuplicate ? `Duplicate assessment #${assessmentToDuplicate?.id}` : 'Create new assessment'}
                </h2>
            </StyledCreateNewActivityHeader>
            <StyledDivCenterY style={{flexDirection:'column', height:'60%', width: '100%', justifyContent:'center', marginTop:'1em'}}>
            {assessmentToDuplicate && 
                <h3 style={{ textAlign: 'center', margin: '-32px 0 0 0', fontSize: '25px' }}>{assessmentToDuplicate?.title} ({assessmentToDuplicate?.language_default})</h3>
            }
            
            {/* <StyledCenteredXYDiv style={{minHeight:'auto', width: '60%', marginBottom:'1em'}}>
                <p style={{textAlign:'left'}}>One activity may have different translations in other languages (pending the integration of this functionality in the activity editor). The assessment language you set here will help the system select the translation you would expect to be added for this assessment.</p>
                <p style={{textAlign:'left'}}>If your team has not translated the activity in this assessment's selected language, then the system will add the activity using the default language of said activity.</p>
                <StyledDivFlexStartWrap style={{justifyContent:'flex-start', width:'100%'}}>
                    <input type='checkbox' checked={isChecked} onChange={() => setIsChecked(!isChecked)}/>
                    <label onClick={() => setIsChecked(!isChecked)}>He leido y acepto las condiciones</label>
                </StyledDivFlexStartWrap>
            </StyledCenteredXYDiv> */}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '24px', width:'100%'}}>
                <XDropdown
                    onChange={(e) => handleLangChange(e)}
                    value={defaultLang}
                    options={LANGUAGES_OPTIONS}
                    multiple={false}
                    hasCheckboxes={false}
                    disableClearable
                    label={'Select language'}
                    labelOptions='label'
                    width='100%'
                    bgColor='100'
                    style={{maxWidth: '200px'}}
                    />
                <XButton
                    key={`assessment-lang-selector`}
                    style={{ height: '48px', margin: '0 1em', padding: '0 15px', textTransform: 'unset' }}
                    variant='contained'
                    onClick={() => handleCreateAssessment()}
                    >
                    {assessmentToDuplicate ? 'Duplicate' : 'Create'}
                </XButton>
            </div>
            </StyledDivCenterY>
        </StyledPageContainer>

    )
}

export default ActivitySelector;
