import { XButton, XCard, XInput, XRadio } from '@ximdex/xui-react/material'
import React from 'react'
import { StyledActivityCreationXCard, StyledButtonCenterContainer, StyledMarginContent, StyledRedXButton } from '../../../Styled-Components'
import { Trash } from 'lucide-react';


export const CORRECT_OPTIONS = [
    { value: 'TRUE', label: 'True' },
    { value: 'FALSE', label: 'False' }
];

const FroggyJumpAnswer = ({ activityInfo, setActivityInfo, setIsActivityInfoUpdated }) => {
    const editAnswer = (type, index, value) => {
        const newAnswer = { ...activityInfo }
        newAnswer.targets[index][type] = value;
        setIsActivityInfoUpdated(false)
        setActivityInfo(newAnswer)
    }

    const editOption = (type, indexOption, value) => {
        const newAnswer = { ...activityInfo }
        newAnswer.options[indexOption][type] = value;
        setIsActivityInfoUpdated(false)
        setActivityInfo(newAnswer)
    }

    const deleteCard = async (index) => {
        const resultPrompt = await window.confirm(`Are you sure you want to take the card ${index}`)
        if (!resultPrompt) return;
        const newData = { ...activityInfo }
        const targetDelete = newData.targets.splice(index, 1);
        newData.options = newData.options.filter(element => {
            return targetDelete[0].order !== element.target_order;
        })
        setIsActivityInfoUpdated(false)
        setActivityInfo(newData)
    }

    const addCard = () => {
        const newAnswer = { ...activityInfo }
        const lastOrder = newAnswer.targets.sort((a, b) => {
            if (a.order > b.order) return -1;
            if (a.order < b.order) return 1;
            return 0;
        })[0].order

        newAnswer.targets.push({
            "id": 0,
            "area": {
                "x": null,
                "y": null,
                "radius": null
            },
            "text": null,
            "order": lastOrder + 1,
            "title": "",
            "image_url": null,
            "accepted_values": null,
            "unaccepted_values": null
        })

        newAnswer.options.push({
            "id": 0,
            "area": null,
            "text": null,
            "image": null,
            "title": "",
            "feedback": null,
            "is_right": "FALSE",
            "image_url": null,
            "target_order": lastOrder + 1
        })
        newAnswer.options.push({
            "id": 0,
            "area": null,
            "text": null,
            "image": null,
            "title": "",
            "feedback": null,
            "is_right": "FALSE",
            "image_url": null,
            "target_order": lastOrder + 1
        })
        newAnswer.options.push({
            "id": 0,
            "area": null,
            "text": null,
            "image": null,
            "title": "",
            "feedback": null,
            "is_right": "FALSE",
            "image_url": null,
            "target_order": lastOrder + 1
        })
        setIsActivityInfoUpdated(false)
        setActivityInfo(newAnswer)
    }


    return (
        <StyledActivityCreationXCard
            isCollapsable={false}
            title={`Editing default language: ${activityInfo.language_default}`}
        >
            <StyledMarginContent>
            {activityInfo?.targets.sort((a, b) => {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
            }).map((element, indexTarget) => {
                // const options = activityInfo?.options.filter((option) => option.target_order === element.order)
                return <XCard
                    title={'Question ' + (indexTarget + 1)}
                    isCollapsable={true}
                    style={{ border: '1px solid #0000002f', paddingBotton: "3rem" }}
                    marginXSize='m'
                    controls={[
                        {
                            component: <StyledRedXButton
                            variant="contained"
                            onClick={() => deleteCard(indexTarget)}
                            title="delete"
                            >
                                <Trash size={20}/>
                            </StyledRedXButton>
                        }
                    ]}
                >
                    <StyledMarginContent>

                    {/* <XLabel
                        label={<p style={{ marginRight: "1rem" }}>Title:</p>}
                        width="100%"
                        paddingSize='0'
                        htmlFor={`title-${indexTarget}-text`}
                        labelLocation='left'
                        component=
                        { */}
                            <XInput
                                placeholder="Enter the question."
                                value={element.title}
                                style={{ width: '100%' }}
                                onChange={(evt) => { editAnswer('title', indexTarget, evt.target.value) }}
                            />
                        {/* }

                    /> */}

                    <XCard
                        title={'Answer options'}
                        style={{ border: '1px solid #0000002f', paddingBotton: "3rem" }}
                    >
                        <StyledMarginContent>

                        {activityInfo?.options.map((option, indexOption) => {
                            if (option.target_order !== element.order) return null;
                            return <>
                                <XInput
                                    value={option.title}
                                    placeholder={`Answer for question ${indexTarget + 1}`}
                                    style={{ width: '100%' }}
                                    id={`option-${indexTarget}-${indexOption}`}
                                    onChange={(evt) => { editOption("title", indexOption, evt.target.value) }}

                                />
                                <XRadio
                                    label="Mark as correct answer:"
                                    id={`${option.id}-is_right`}
                                    options={CORRECT_OPTIONS}
                                    value={option.is_right === 'TRUE' ? 'TRUE' : 'FALSE'}
                                    paddingXSize='s'
                                    onChange={(evt) => editOption("is_right", indexOption, evt.target.value)}
                                />
                                {/* {indexOption !== options.length - 1 &&
                                    <hr style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }} />
                                } */}
                            </>
                        }
                        )}
                        </StyledMarginContent>
                    </XCard>
                    </StyledMarginContent>
                </XCard>
            })}

            <StyledButtonCenterContainer>
                <XButton onClick={addCard}>ADD Question</XButton>
            </StyledButtonCenterContainer>
            </StyledMarginContent>
        </StyledActivityCreationXCard>
    )
}

export default FroggyJumpAnswer
