const ACTIVITY_TYPE_MHE = {
    MATCH: 'match',
    INPUT: 'input',
    INPUT_LONG: 'input-long',
    FIT_WORD: 'fit-word',
    RANK: 'rank'
}

const ACTIVITY_TYPE_SEK = {
    DEDUCTION: 'deduction',
    SHORT_QUESTION: 'short-question',
    OPINION_SCALE: 'opinion-scale',
    TEXT_CHOICE: 'text-choice',
    IMAGE_CHOICE: 'image-choice',
    TEXT_PAIRING: 'text-pairing',
    IMAGE_PAIRING: 'image-pairing',
    CARD_CHOICE: 'card-choice',
    FLASH_CARDS: 'flash-cards',
    FROGGY_JUMP: 'froggy-jump'
}

const ACTIVITY_TYPE_MHE_AND_SEK = {
    SINGLE_CHOICE: 'single-choice', 
    MULTIPLE_CHOICE: 'multiple-choice',
    TRUE_FALSE: 'true-false',
    FILL_THE_BLANKS: 'fill-the-blanks',
    ORDER_CHRONOLOGY: 'order-chronology',
    CORRESPONDENCE: 'correspondence',
    WORD_SEARCH: 'word-search',
    WORD_CATCHER: 'word-catcher',
    SEEK_AND_FIND: 'seek-and-find',
}

const ACTIVITY_TYPE = {
    ...ACTIVITY_TYPE_MHE,
    ...ACTIVITY_TYPE_SEK,
    ...ACTIVITY_TYPE_MHE_AND_SEK,
}

export default ACTIVITY_TYPE
