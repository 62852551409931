import Axios from 'axios';
import config from '../config'
import { getStorageToken } from './storage.service';
import {
    CLIENT,
    FETCH_ACTIVITY_TYPES,
    ACTIVITIES,
    ASSESSMENTS,
    ACTIVITIES_INIT,
    BASE_API_DAM_MEDIA,
    USER_ACTIVITY_ID,
    USER_ASSESSMENT_ID,
    DELETE_TRANSLATION,
    COOKIE_NAME,
    XOWL_URI_API
} from '../CONSTANTS';

const userDataInStorage = () => JSON.parse(sessionStorage.getItem(COOKIE_NAME)) ?? JSON.parse(sessionStorage.getItem(`${COOKIE_NAME}_XEVAL`));
const XDAMToken = () => userDataInStorage()?.xdam_token ?? process.env?.REACT_APP_TOKEN_XDAM ?? null;
export const XevalAxios = Axios.create()

export const whoami = () => {
    return request('GET', config.xevalUri + '/whoami', {
        allowToken: true
    })
}

export const loginXDAM = (email, password) => {
    try {
        return fetch(`${process.env?.REACT_APP_XDAM_API}/auth/login`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })  
        .then(res => res.json())
        .catch((err) => { console.error(err); return false;});
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const loginUserKakuma = (email, password) => {
    try {
        return fetch(`${process.env?.REACT_APP_KAKUMA_API}/login`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
            .then(res => res.json())
            .catch((err) => { console.error(err); return false;});
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const getDataList = async (uri) => {
    return request('GET', config.xevalUri + uri, { allowToken: true });
}

export const request = async (method, uri, { body = {}, allowToken = false } = {}) => {
    let headers = {};
    if (allowToken) {
        headers = {
            token: getStorageToken()
        }
    }
    const response = method === 'GET' ? XevalAxios.get(uri, {headers}) : XevalAxios.post(uri, body);
    
    if (response.data) {
        return response.data
    }
}

export const fetchActivityTypes = () => {
    return XevalAxios.get(FETCH_ACTIVITY_TYPES)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const createNewActivity = (activityInfoInit, activityInfo) => {
    let body

    if (activityInfoInit?.activityType) {
        if (CLIENT === 'MHE' || CLIENT === 'SEK') {
            const langIndex = ACTIVITIES_INIT.findIndex(activities => activities.hasOwnProperty(activityInfoInit?.activityLang)) || 0
            const lang = activityInfoInit?.activityLang || Object.keys(ACTIVITIES_INIT[langIndex])
            body = ACTIVITIES_INIT[langIndex][lang].find(activity => activityInfoInit?.activityType === activity.type)
        } else {
            body = ACTIVITIES_INIT.find(activity => activityInfoInit?.activityType === activity.type)
        }
        if (activityInfoInit?.activityLang) body.language_default = activityInfoInit.activityLang
    } else if (activityInfo) {
        body = activityInfo;
    }

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }

    return XevalAxios.post(ACTIVITIES, JSON.stringify(body), options)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const getActivityById = async (id) => {
    return await XevalAxios.get(ACTIVITIES + '/' + id)
        .then(data => data.data)
        .catch(error => Promise.reject(error))
}

export const getUserActivityById = async (id) => {
    return await XevalAxios.get(USER_ACTIVITY_ID + '/' + id)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const getUserAssessmentById = async (id) => {
    return await XevalAxios.get(USER_ASSESSMENT_ID + '/' + id)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const putActivityLanguage = (id,language, body) => {
    // let lang = language ? language : langTabChecked
    return Axios.put(ACTIVITIES + '/' + id + `/translate?lang=${language}`,body)
        .then(data => data.data)
        .catch(error => Promise.reject(error))
}

export const getActivityLanguageById = async (id, language) => {
    return Axios.get(ACTIVITIES + '/' + id + `?lang=${language}`)
    .then(data => {
        return data.data;
    }).catch(error => {
        console.error(error)
        return Promise.reject(error);
    });
}

export const getAiTranslation = async (lang, value, id) => {
    const postData = {
        "lang": lang.slice(0, 2),
        "text": value,
        "id": id,
        "type": "activity"
    };
    const headers = {'Content-Type': 'application/json' };

    return await Axios.post(XOWL_URI_API + '/translate', postData, { headers })
        .then((res) => {
            if (res?.status === 200) {
                return res?.data?.data
            }
            throw new Error (res?.statusText)
        })
        .catch((err) => {
            console.error("There has been an error while getting this AI translation. Try again later.", err);
            return Promise.reject(err);
        });
}

export const removeIAcache = async (id, lang) => {
    return await Axios.delete(XOWL_URI_API + `/cache-remove?id=translation%23activity%23${id}%23${lang.slice(0, 2)}`)
        .then((res) => {
            if (res?.status === 200) {
                return res?.data?.data
            }
            throw new Error (res?.statusText)
        })
        .catch((err) => {
            console.error("There has been an error while deleting this AI cache. Try again later.", err);
            return Promise.reject(err);
        });
}


export const updateActivity = (id, body) => {
    return XevalAxios.put(ACTIVITIES + '/' + id, body)
        .then(data => data.data)
        .catch(error => {
            console.error(error)
            return Promise.reject(error);
        });
}

export const updateActivityStatus = (id, newStatus) => {
    const body = { status: newStatus.toUpperCase() }

    return XevalAxios.put(`${ACTIVITIES}/${id}/change-status`, body)
        .then(res => res)
        .catch(error => {
            console.error(error)
            return Promise.reject(error);
        });
}

export const getAllActivities = async (searchParams) => {
    return await XevalAxios.get(`${ACTIVITIES}?${searchParams}`)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const getAllExistingMedia = async (searchTerm, pageMedia) => {
    let cancelToken;
    const pageSearchMedia = pageMedia || 1;

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    // cancelToken = XevalAxios.CancelToken.source();

    return await Axios.get(
        `${BASE_API_DAM_MEDIA}/catalogue/${CLIENT === 'SEK' ? '4' : '6' }?search=${searchTerm}&page=${pageSearchMedia}&limit=12`,
        {
            headers: {
                Authorization: `Bearer ${XDAMToken()}`,
                Accept: "*/*",
                Accept: "application/json",
            }
        }
        // , { cancelToken: cancelToken.token }
    ).then(data => {
        return data.data;
    }).catch(error => {
        return Promise.reject(error);
    });

    // const options = {
    //     headers: {
    //         Authorization: `Bearer ${process.env.REACT_APP_TOKEN_XDAM}`,
    //         Accept: "application/json",
    //     }
    // }

    // const url = `${BASE_API_DAM_MEDIA}?search=${searchTerm}&page=${pageSearchMedia}&limit=12`

    // return await XevalAxios.get(url, options)
    //     .then(data => data.data)
    //     .catch(error => Promise.reject(error));
}

function getFileType(type) {
    let typeOfFile = type.split("/");

    typeOfFile = typeOfFile[0];
    switch (typeOfFile) {
        case "image":
            return "image";

        case "video":
            return "video";

        default:
            break;
    };
};

export const postMediaToCMS = async (data) => {
    const type = getFileType(data?.fileData?.type);

    let obj = {
        description: {
            name: data?.mediaName,
            tags: data?.tags,
            active: true,
            partials: {},
            categories: [],
            description: "",
            external_url: ""
        },
    };

    obj = JSON.stringify(obj);

    let formData = new FormData();

    formData.append("File[]", data?.fileData, data?.mediaName);
    formData.append("type", type);
    formData.append("size", data?.mediaSize);
    formData.append("data", obj);
    formData.append("collection_id", `${CLIENT === 'SEK' ? '4' : '6'}`);

    try {
        let res = await fetch(`${BASE_API_DAM_MEDIA}/resource`, {
            method: "POST",
            headers: {
            Authorization: `Bearer ${XDAMToken()}`,
            Accept: "*/*",
            Accept: "application/json",
            },
            body: formData,
        });
        res = await res.json();
        return res;
    } catch (error) {
        return Promise.reject(error);
    };
}

export const getAllAssessments = async (searchParams) => {
    return await XevalAxios.get(`${ASSESSMENTS}?lite=true&${searchParams}`)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const createAssessment = (body) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }

    return XevalAxios.post(ASSESSMENTS, JSON.stringify(body), options)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const getAssessmentById = (id) => {
    return XevalAxios.get(ASSESSMENTS + '/' + id)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const updateAssessment = (assessmentId, infoToUpdate, onlyActivities = false) => {
    let body;
    if (onlyActivities) {
        body = {
            activities: infoToUpdate
        }
    } else {
        body = infoToUpdate
    }
    return XevalAxios.put(ASSESSMENTS + '/' + assessmentId, body)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
}

export const updateAssessmentStatus = (id, newStatus) => {
    const body = { status: newStatus.toUpperCase() }

    return XevalAxios.put(`${ASSESSMENTS}/${id}/status`, body)
    .then(res => res)
    .catch(error => {
        console.error(error)    
        Promise.reject(error)
    });
}


export const deleteTranslation = (id,dataType,lang,field) => {
    return XevalAxios.delete(`${DELETE_TRANSLATION}?data_type=${dataType}&data_type_id=${id}` + `${lang ? `&lang=${lang}` : ''}` + `${field ? `&field=${field}` : ''}`)
    .then(res => res)
    .catch(error => {
        console.error(error)    
        Promise.reject(error)
    });
}

