import React from 'react';
import { XRow, XRowContent, XRowDetails, XRowExtraDetails } from '@ximdex/xui-react/material';
import { useNavigate } from 'react-router-dom';
import {
    StyledActivityId,
    StyledFontAwesomeIcon,
    StyledGreenXButton,
    StyledRedXButton,
    StyledDivCenterY,
    StyledXRow,
} from '../../Styled-Components';
import Pagination from '../Pagination/Pagination';
import { ArchiveRestore, Copy, Pencil, Trash } from 'lucide-react';

const ActivitiesListMap = ({
    activitiesList,
    paginationMetaInfo,
    setPaginationMetaInfo,
    errorData,
    handleActivityStatus,
    duplicateActivity
}) => {
    const navigate = useNavigate();

    return (
        <>
            
            {activitiesList.length > 0 ? (
                    <>
                        {activitiesList.map((activity, index) => (
                            <StyledXRow
                                style={{
                                    borderBottom: index === (activitiesList.length - 1) ? '1px solid #BBBBBB' : '',
                                    background: 'rgb(247, 247, 247)',
                                    width: '100%'
                                }}
                                key={'row' + index}
                                identifier={activity.id}
                                isCollapsable={true}
                                labelButtonCollapsable={`Used in ${activity?.assessments?.length} ${activity?.assessments?.length === 1 || activity?.assessments?.length === 0 ? 'assessment' : 'assessments'}`}
                                controls={[
                                {
                                    component:
                                    activity?.status !== 'ARCHIVED' && (
                                        <>
                                            <StyledGreenXButton onClick={() => duplicateActivity(activity)} title='Duplicate activity.'>
                                                <Copy size={20}/>
                                            </StyledGreenXButton>
                                            <StyledGreenXButton onClick={() => navigate(`/edit-activity?activity-id=${activity.id}&lang=${activity?.language_default}`)} title='Edit activity.'>
                                                <Pencil size={20}/>
                                            </StyledGreenXButton>
                                        </>
                                    )
                                },
                                {
                                    component:
                                    <StyledRedXButton 
                                        onClick={() => handleActivityStatus(activity?.id, activity?.status)}                                             
                                        title={activity?.status === "ARCHIVED" ? 'Unarchive and change status to active' : 'Archive activity'}
                                    >
                                        {activity?.status === 'ARCHIVED' ? <ArchiveRestore size={20}/> : <Trash size={20}/>}

                                    </StyledRedXButton>
                                }, 
                                ]}
                            >
                                {/* XRow Content */}
                                <XRowContent key={"XRowContent" + index}>
                                    <StyledDivCenterY>
                                         <StyledFontAwesomeIcon
                                            icon={['far', 'flag']}
                                            title={`Status: ${activity.status}`}
                                            hasMarginRight size='1x'
                                            isSuccess={activity.status === 'ACTIVE' ? true : false}
                                            isWarning={activity.status === 'DRAFT' ? true : false}
                                            isError={(activity.status !== 'ACTIVE' && activity?.status !== 'DRAFT') ? true : false}
                                        />
                                        <span>Id: {activity.id}</span>
                                    
                                        <div style={{marginLeft:'1em'}}>
                                            <p style={{ marginTop: '6px', marginBottom: '6px' }}>{activity?.name ?? 'Unnamed activity'} ({activity?.type})</p>
                                            {activity?.isbn?.length > 0 && 
                                            <p style={{ marginTop: '0px', marginBottom: '6px' }}>
                                                ISBN: {activity.isbn.join(', ')} 
                                                {"  "}
                                                UNITS: {activity?.units?.length > 0 && activity.units.join(', ')}
                                            </p>}
                                        </div>
                                    
                                    </StyledDivCenterY>
                                </XRowContent>
                                {/* XRow Details */}
                                {activity.status === 'ARCHIVED' ?
                                    <XRowDetails key={'XRowDetails'}>
                                        <div>
                                            <p><strong>Title: </strong>{activity?.title}</p>
                                            <p><strong>Text: </strong>{activity?.text}</p>
                                        </div>
                                    </XRowDetails>
                                : (activity?.assessments && activity.assessments?.length) > 0 ? 
                                    <React.Fragment key={'XRowDetails'}>
                                    {activity.assessments?.map((assessment, assessmentIndex) => 
                                        <XRowDetails 
                                            key={'XRowDetails' + assessmentIndex} 
                                            controlsDetails={[
                                            {
                                                component:
                                                <StyledGreenXButton
                                                    onClick={() => navigate(`/edit-assessment?assessment-id=${assessment?.id}`)}
                                                    title='Edit assessment'
                                                >
                                                    <Pencil size={20}/>
                                                </StyledGreenXButton>
                                            },
                                        ]}
                                        >
                                            <StyledDivCenterY>
                                                <StyledActivityId>Id: {assessment?.id}</StyledActivityId>
                                                <p>{assessment?.name || assessment?.title}</p>
                                            </StyledDivCenterY>
                                        </XRowDetails>    
                                    )}
                                    </React.Fragment> 
                                : 
                                    <XRowDetails key={'XRowDetails'}></XRowDetails>
                                }
                                {/* XRow extra details */}
                                <XRowExtraDetails
                                    key={"XRowExtraDetails" + index}
                                    extraDetails={[
                                        {
                                            label: 'Languages',
                                            type: 'text',
                                            value: activity?.language_default + (activity?.available_languages?.length > 0 ? ", " + activity?.available_languages?.join(', ') : "")
                                        },
                                        {
                                            label: 'Created date',
                                            type: activity?.created_at ? "date" : 'text',
                                            value: activity?.created_at ?? 'data unavailable' 
                                        },
                                        {
                                            label: 'Last update',
                                            type: activity?.updated_at ? 'date' : 'text',
                                            value: activity?.updated_at ?? 'data unavailable'
                                        }
                                    ]}
                                />
                            </StyledXRow>
                        ))}
                        <Pagination
                            paginationInfo={paginationMetaInfo}
                            setPaginationMetaInfo={setPaginationMetaInfo}
                        />
                    </>
                ) : paginationMetaInfo?.total === 0 ? (
                    <div>
                        <p style={{ margin: '3em 0.5em', textAlign: 'center', fontSize: '1.3em' }}>
                            No activities found with these filter settings.
                        </p>
                    </div>
                ) : errorData?.message ? (
                    <div>
                        <p style={{ margin: '3em 0.5em', textAlign: 'center', fontSize: '1.3em' }}>
                            {errorData.message}
                        </p>
                        <p style={{ margin: '1em', textAlign: 'center', fontSize: '1em' }}>
                            {errorData.message}
                        </p>
                    </div>
                ) : (
                    <></>
                )}
        </>
    );
}

export default ActivitiesListMap;
