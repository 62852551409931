import React from 'react'
import { useNavigate } from 'react-router-dom'
import { XButton } from '@ximdex/xui-react/material'
import { 
    StyledCenteredXYDiv, 
    StyledPageStatusHeader,
    StyledPageStatusImg,
    StyledFontAwesomeIcon, 
} from '../../Styled-Components'

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <StyledCenteredXYDiv>
            <StyledPageStatusImg src={"/assets/images/undraw-page-not-found.svg"} />  
            <StyledPageStatusHeader>Page not found</StyledPageStatusHeader>          
            <div style={{ margin: '5em auto 0.5em'}}>
                <XButton onClick={() => navigate(-1)} size='small'>
                    <StyledFontAwesomeIcon icon={['fa', 'angle-left']} title="Go to previous page" hasMarginRight />
                    Go Back
                </XButton>
            </div>
        </StyledCenteredXYDiv>
    )
}

export default NotFound