import React from 'react';
import { XInput, XButton } from '@ximdex/xui-react/material';
import { 
    StyledFeedbackContainer, 
    StyledButtonCenterContainer,
    ExtraInfoText,
    StyledFontAwesomeIcon,
    StyledActivityCreationXCard,
    StyledRedXButton,
    StyledMarginContent
} from '../../../Styled-Components';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import useMaxCharacters from '../../../hooks/useMaxCharacters';
import { ACTIVITIES_GLOBAL_FEEDBACK_MESSAGES } from '../../../CONSTANTS';
import { Trash } from 'lucide-react';

export const GlobalFeedback = ({
    TOOLTIPS,
    activityInfo,
    handleInfoUpdated
}) => {  
    const {getMaxCharGlobalFeedback} = useMaxCharacters()
    const maxChar = getMaxCharGlobalFeedback()

    const modifyFeedbacks = (action, index, keyToEdit, valueToEdit) => {
        let newInfo = { ...activityInfo };

        switch(action) {
            case 'add':
                if (!newInfo.feedbacks || newInfo.feedbacks.length === 0) {
                    newInfo.feedbacks = [{ "success_percentage": 0, "text": ACTIVITIES_GLOBAL_FEEDBACK_MESSAGES[newInfo.current_language][0]}];
                } else if (newInfo.feedbacks.length === 1) {
                    newInfo.feedbacks.push({ "success_percentage": 100, "text": ACTIVITIES_GLOBAL_FEEDBACK_MESSAGES[newInfo.current_language][2] });
                } else if (newInfo.feedbacks.length >= 2) {
                    const newPercentage = (newInfo.feedbacks[newInfo.feedbacks.length - 1].success_percentage + newInfo.feedbacks[newInfo.feedbacks.length - 2].success_percentage) / 2; 
                    const newFeedback = { 
                        success_percentage: parseInt(newPercentage), 
                        text: `${ACTIVITIES_GLOBAL_FEEDBACK_MESSAGES[newInfo.current_language][1]} ${newPercentage}%` 
                    };
                    newInfo.feedbacks.splice(-1, 0, newFeedback);
                } 
                break;
            case 'delete':
                newInfo.feedbacks.splice(index, 1);
                break;
            case 'edit':
                newInfo.feedbacks[index][keyToEdit] = valueToEdit;
                break;
            default:
                return;
        };

        handleInfoUpdated(newInfo, false)
    };
   
    return (
        <StyledActivityCreationXCard
            isCollapsable={false}
            // backgroundHeader={true}
            title={`Editing default language: ${activityInfo.language_default}`}

            // title='Global feedback'
            tooltip={
                <StyledFontAwesomeIcon icon={['fa', 'circle-info']} title={TOOLTIPS.INSTRUCTIONS.GLOBAL_FEEDBACK} size='1x' isInfo  />
            }
        >
            <StyledMarginContent>
                {activityInfo?.feedbacks && activityInfo?.feedbacks?.length > 0 && activityInfo?.feedbacks.map((feedback, index) => (
                    <StyledFeedbackContainer key={index}> 
                        <XInput
                            label='Success (%)'
                            name='Success (%)'
                            value={feedback?.success_percentage ?? ''}
                            type='number'
                            inputProps={{ min: 0, max: 100 }}
                            size='small'
                            style={{ margin: '8px', minWidth: '92px', width: '20%' }}
                            fullWidth
                            disabled={feedback?.success_percentage === 0 ? true : false}
                            onChange={(e) => modifyFeedbacks('edit', index, 'success_percentage', parseInt(e.target.value))}
                            />
                        <div style={{ width: 'calc(80% - 32px)'}}>
                            <XInput
                                label='Comment'
                                value={feedback?.text || ''}
                                style={{ margin: '8px 8px 8px 8px'  }}
                                type='text'
                                size='small'
                                fullWidth
                                multiline
                                rows={5}
                                onChange={(e) => modifyFeedbacks('edit', index, 'text', e.target.value)}
                                inputProps={{ maxLength: maxChar}}
                                />
                            <ExtraInfoText>
                                {feedback.text?.length || 0} of {maxChar} characters
                                {feedback.success_percentage !== 0 &&
                                    <StyledRedXButton
                                    // style={{margin: '1em'}}
                                    onClick={() => modifyFeedbacks('delete', index)}
                                    title="Delete feedback" 
                                    >
                                        <Trash size={20} />
                                    </StyledRedXButton>
                                }
                            </ExtraInfoText>
                        </div>
                        
                    </StyledFeedbackContainer>
                ))}
            
                <StyledButtonCenterContainer>
                    <XButton onClick={() => modifyFeedbacks('add')}>
                        Add new feedback
                    </XButton>
                </StyledButtonCenterContainer>
            </StyledMarginContent>
        </StyledActivityCreationXCard>
    )
}
