import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAllAssessments, updateAssessmentStatus } from '../../services/xevalApi.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import {
    StyledGrid,
    StyledActivitiesListContainer,
    StyledListMapContainer,
    StyledDivFlexWrap,
} from '../../Styled-Components';
import { PAGINATION_OPTIONS, SORTING_OPTIONS_ASSESSMENTS,UNITS_OPTIONS } from '../../CONSTANTS';
import AssessmentsListHeader from '../../components/AssessmentList/AssessmentsListHeader';
import AssessmentsListMapControls from '../../components/AssessmentList/AssessmentsListMapControls';
import AssessmentsListMap from '../../components/AssessmentList/AssessmentsListMap';
import useModals from '../../hooks/useSweetAlert'
import AssessmentsListSearchColumn from '../../components/AssessmentList/AssessmentsListSearchColumn';

const AssessmentsList = () => {
    const { showSpinner, hideSpinner } = useSpinner();
    const [searchParams, setSearchParams] = useSearchParams()
    const {modal} = useModals();

    const handleValue = (type) => {
        let paramsStorage = JSON.parse(localStorage.getItem("assessments-filters"))
        if(paramsStorage){
            if(type === 'sortBy'){
                let val = SORTING_OPTIONS_ASSESSMENTS.find((option) => option.value === (`${paramsStorage.order_by}&${paramsStorage.sort}`))
                return val
            }else{
                let val = PAGINATION_OPTIONS.find((option) => option.value === paramsStorage.limit)
                return val
            }
        }else{
            if(type === 'sortBy'){
                console.log(SORTING_OPTIONS_ASSESSMENTS)

                let val = SORTING_OPTIONS_ASSESSMENTS.find((option) => option.value === SORTING_OPTIONS_ASSESSMENTS[0].value)
                return val
            }else{
                let val = PAGINATION_OPTIONS.find((option) => option.value === PAGINATION_OPTIONS[0].value)
                return val
            }
        }
    }

    const [assessmentsList, setAssessmentsList] = useState([]);
    const [limitSearchParam, setLimitSearchParam] = useState(() => handleValue('limit'))
    const [sortBySearchParam, setSortBySearchParam] = useState(() => handleValue('sortBy'))
    const [paginationMetaInfo, setPaginationMetaInfo] = useState({
        current_page: parseInt(searchParams.get('page')) || 1,
        last_page: undefined,
        next_page: undefined,
        prev_page: undefined,
        total: undefined,
        isLoading: true,
    })
    const [textBeforeSearch, setTextBeforeSearch] = useState('');
    const [errorData, setErrorData] = useState({
        message: undefined,
        error: undefined
    })
    const [unitsValue, setUnitsValues] = useState(() => {
        let value = []
        if (searchParams.has('units')) {
            const unitsParams = searchParams.get('units').split(',')
            unitsParams.forEach((unitParam) => {
                let optionParam = UNITS_OPTIONS.find(option => option.value === unitParam)
                value.push(optionParam)
            })
        }
        return value
    })

    useEffect(() => {
        if(localStorage.getItem("assessments-filters")){
            let paramsStorage = JSON.parse(localStorage.getItem("assessments-filters"))
            if (!searchParams.has('limit')) searchParams.set('limit', paramsStorage.limit)
            if (!searchParams.has('order_by')) searchParams.set('order_by', paramsStorage.order_by)
            if (!searchParams.has('sort')) searchParams.set('sort', paramsStorage.sort)

        }else{
            if (!searchParams.has('limit')) searchParams.set('limit', 24)
            if (!searchParams.has('order_by')) searchParams.set('order_by', 'id')
            if (!searchParams.has('sort')) searchParams.set('sort', 'desc')

            localStorage.setItem("assessments-filters", JSON.stringify({
                page:paginationMetaInfo.current_page,
                limit: 24,
                order_by: 'id',
                sort:'desc',
            }))
        } 

        setSearchParams(searchParams, {replace: true})
    }, [])

    // Set Page Params
    useEffect(() => {
        if (searchParams.get('page') !== paginationMetaInfo?.current_page) {
            searchParams.set('page', paginationMetaInfo?.current_page )
            setSearchParams(searchParams, {replace: false})
        }
    }, [paginationMetaInfo?.current_page]);

    // Set New Filters to Local Storage and call getAssessments()
    useEffect(() => {
        localStorage.setItem("assessments-filters", JSON.stringify({
            page: searchParams.get('page'),
            limit: searchParams.get('limit'),
            order_by: searchParams.get('order_by'),
            sort: searchParams.get('sort'),
        }))

        getAssessments();
    }, [searchParams])

    // Get All Assessments
    const getAssessments = async () => {
        showSpinner();
        await getAllAssessments(searchParams.toString()).then(data => {
            data.data.map(assessment => {
                assessment.isCollapsed = false;
            })
            setAssessmentsList(data.data);

            let cloneData = Object.assign({}, data);
            delete cloneData.data;
            setPaginationMetaInfo({ ...cloneData, isLoading: false});
            return cloneData.current_page
        }).catch(err => {
            console.error(err);
            setPaginationMetaInfo({ ...paginationMetaInfo, isLoading: false });
            setErrorData({message: 'It looks like something has happened. Try again later.', error: err })
        }).finally(() => {
            hideSpinner();
        });
    }

    const updateSearchParams = (searchOption, value) => {
        if(searchOption === "sortBy"){
            let val = SORTING_OPTIONS_ASSESSMENTS.find((option) => option.label === SORTING_OPTIONS_ASSESSMENTS[value].label)
            setSortBySearchParam(val)
            val = val.value.split('&') 
            searchParams.set('order_by', val[0])
            searchParams.set('sort', val[1])
        }else if (searchOption === 'limit'){
            let val = PAGINATION_OPTIONS.find((option) => option.label === PAGINATION_OPTIONS[value].label)
            setLimitSearchParam(val)
            searchParams.set('limit', val.value)
        }else if(value.toUpperCase() === 'ALL' || !value) {
            searchParams.delete(`${searchOption}`)
        } else{
            searchParams.set(`${searchOption}`, value )       
        }
        setSearchParams(searchParams, {replace: false})
    }

    const handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            updateSearchParams('search', textBeforeSearch)  
        }
    }

    const handleAssessmentStatus = async (id, currentStatus) => {
        const newStatus = currentStatus === 'ARCHIVED' ? 'ACTIVE' : 'ARCHIVED';
        modal(
            `Are you sure you want to change the status of the assessment with id ${id} to ${newStatus}?`,
            `Change assessment status`, 
            () => {
                updateAssessmentStatus(id, newStatus).then((res) => {
                getAssessments();
                showSpinner();
  
                XPopUp({
                    message:`Status changed to ${res?.data?.status}`,
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
                }).catch(err => {
                    console.error(err);
                    XPopUp({
                        message:"There's been an error. Please, try again later.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }).finally(() => {
                    hideSpinner();
                });}
        )  

    }

    return (
        <>
        <AssessmentsListHeader
            textBeforeSearch={textBeforeSearch}
            sortBySearchParam={sortBySearchParam}
            handleKeyPressSearch={handleKeyPressSearch}
            setTextBeforeSearch={setTextBeforeSearch}
            updateSearchParams={updateSearchParams}
            limitSearchParam={limitSearchParam}
        />
        <StyledGrid>
            <AssessmentsListSearchColumn
                searchParams={searchParams}
                updateSearchParams={updateSearchParams}
                unitsValue={unitsValue}
                setUnitsValues={setUnitsValues}
            />
            <StyledActivitiesListContainer>

                <StyledListMapContainer>
                    <AssessmentsListMapControls
                        paginationMetaInfo={paginationMetaInfo}
                        sortBySearchParam={sortBySearchParam}
                        limitSearchParam={limitSearchParam}
                        updateSearchParams={updateSearchParams}
                        setUnitsValues={setUnitsValues}
                        searchParams={searchParams}
                        />
                    <AssessmentsListMap
                        errorData={errorData}
                        setPaginationMetaInfo={setPaginationMetaInfo}
                        paginationMetaInfo={paginationMetaInfo}
                        assessmentsList={assessmentsList}
                        handleAssessmentStatus={handleAssessmentStatus}                    
                        />
                </StyledListMapContainer>
                
            </StyledActivitiesListContainer>
        </StyledGrid>
    </>
    )
}

export default AssessmentsList;
