import React, { useMemo } from 'react';
import { XCard, XInput, XButton } from '@ximdex/xui-react/material';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import {
    ExtraInfoText,
    StyledButtonCenterContainer,
    StyledXRadioMarkAsCorrect,
    StyledXCardOption,
    StyledActivityCreationXCard,
    StyledMarginContent,
    StyledGreenXButton,
    StyledRedXButton,
    StyledCenterParagraph,
} from '../../../Styled-Components';
import { CORRECT_OPTIONS } from '../../../CONSTANTS';
import useMaxCharacters from '../../../hooks/useMaxCharacters';
import { Copy, Trash } from 'lucide-react';

export const CorrespondenceAnswers = ({
    activityInfo,
    setActivityInfo,
    setIsActivityInfoUpdated,
    ACTIVITY_TYPE,
    SETTINGS
}) => {
    const { 
        getMaxCharOptions, 
        getMaxCharTargets
    } = useMaxCharacters(ACTIVITY_TYPE)
    const maxCharOption = getMaxCharOptions()
    const maxCharTarget = getMaxCharTargets()

    const targets = useMemo(() => {
        return activityInfo.targets ? [...activityInfo.targets].sort((a,b) => a.order - b.order) : []
    }, [activityInfo])
    /* FOR CARD */ 
    const modifyTargets = (action, index, keyToEdit, valueToEdit) => {
        let newInfo = { ...activityInfo, targets };
        const activeOrder = index !== undefined ? newInfo.targets[index].order : undefined
        const currentNumOfOrders = Math.max(...targets.map(target => target.order));
        const newOrder = currentNumOfOrders + 1;

        switch (action) {
            case 'add':
                const newTarget = { id: 0, order: newOrder };
                newInfo.targets.push(newTarget);

                const newOptionTrue = { id: 0, target_order: newOrder, is_right: "TRUE" };
                const newOptionFalse = { id: 0, target_order: newOrder, is_right: "FALSE" };
                newInfo.options.push(newOptionTrue, newOptionFalse);
                break;
            case 'copy':
                const copyTarget = { ...newInfo.targets[index], order: newOrder, id: 0 };
                newInfo.targets.push(copyTarget);

                const options = newInfo.options.filter(option => option.target_order === activeOrder);
                const copyOptions = options.map(option => Object.assign({}, option, { target_order: newOrder, id: 0 }));
                const optionsPlusCopyOptions = newInfo.options.concat(copyOptions);
                newInfo.options = optionsPlusCopyOptions;
                break;
            case 'delete':
                newInfo.targets.splice(index, 1);
                newInfo.options = newInfo.options.filter((option) => option.target_order !== activeOrder)
                break;
            case 'edit':
                newInfo.targets[index][keyToEdit] = valueToEdit;
                break;
            default:
                return;
        };

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    }

    /* FOR ANSWER BOX */ 
    const modifyOptions = (action, index, keyToEdit, valueToEdit) => {
        let newInfo = { ...activityInfo, targets };
        const activeOrder = action === 'add' ? newInfo.targets[index]?.order : newInfo.options[index]?.target_order;
        const activeOrderOptionsAmount = newInfo.options.filter(option => option.target_order === activeOrder).length;

        switch (action) {
            case 'add':
                if (activeOrderOptionsAmount < 4) {
                    const newOption = { id: 0, target_order: activeOrder, is_right: "FALSE" };
                    newInfo.options.push(newOption);
                } else {
                    XPopUp({
                        message:"You can't have more than 4 possible answers for each card.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }
                break;
            case 'delete':
                if (activeOrderOptionsAmount > 2) {
                    newInfo.options.splice(index, 1);
                } else {
                    XPopUp({
                        message:"You can't have less than 2 possible answers for each card.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }
                break;
            case 'edit':
                newInfo.options[index][keyToEdit] = valueToEdit;
                break;
            default:
                return;
        };

        setIsActivityInfoUpdated(false)
        setActivityInfo(newInfo);
    }
    
    return (
        <StyledActivityCreationXCard
            isCollapsable={false}
            title={`Editing default language: ${activityInfo.language_default}`}
        >
            <StyledMarginContent>
            {/* CARD */}
            {targets.length > 0 ?targets.map((target, targetIndex) => (
                <StyledXCardOption
                    key={`${target?.id}-targetCardInfo`}
                    title={`${SETTINGS.INDIVIDUAL_TITLE[ACTIVITY_TYPE]} ${(targetIndex + 1).toString()}`}
                    controls={[
                        {
                            component: 
                                <StyledGreenXButton
                                    onClick= {() => modifyTargets('copy', targetIndex)}
                                    title="Duplicate card"
                                >
                                    <Copy size={20}/>
                                </StyledGreenXButton>
                        },
                        {
                            component:
                                <StyledRedXButton
                                    onClick= {() => modifyTargets('delete', targetIndex)}
                                    title="Remove card"
                                >
                                    <Trash size={20}/>
                                </StyledRedXButton>
                        }
                    ]}
                    isCollapsable={SETTINGS.INDIVIDUAL_COLLAPSABLE?.includes(ACTIVITY_TYPE)}
                >
            <StyledMarginContent>
                    <XInput
                        label='Text'
                        style={{ margin: '10px 0', width: '100%', textAlignLast: SETTINGS.ALIGN_CENTER_TEXT?.includes(ACTIVITY_TYPE) ? 'center' : 'unset' }}
                        id={`${target.id}-title`}
                        size='small'
                        fullWidth
                        multiline
                        rows={SETTINGS.ROWS_TEXT[ACTIVITY_TYPE] || 4}
                        value={target?.title}
                        onChange={(e) => modifyTargets('edit', targetIndex, 'title', e.target.value)}
                        inputProps={{ maxLength: maxCharTarget }}
                    />
                    <ExtraInfoText>{target?.title?.length || 0} of {maxCharTarget} characters</ExtraInfoText>

                    {/* ANSWER BOX */}
                    {activityInfo.options.map((option, optionIndex) => (
                        option.target_order === target.order &&
                            <XCard
                                key={option?.id !== 0 ? `option-${option.id}` : `option-${optionIndex}`}
                                style={{
                                    border: '1px solid lightgray',
                                    borderRadius: '0px',
                                    margin: '1.5em 3em'
                                }}
                                title={`Possible answer for card ${targetIndex + 1}`}
                                controls={[
                                    {
                                        component:
                                            <StyledRedXButton
                                                onClick= {() => modifyOptions('delete', optionIndex)}
                                                title="Remove possible answer"
                                            >
                                                <Trash size={20}/>
                                            </StyledRedXButton>
                                    }
                                ]}
                                marginXSize='xxs'
                                marginYSize='xxs'
                            >
                                <StyledMarginContent>
                            <XInput
                                label='Text'
                                style={{ margin: '10px 0 10px 0', width: '100%', textAlignLast: SETTINGS.ALIGN_CENTER_TEXT?.includes(ACTIVITY_TYPE) ? 'center' : 'unset' }}
                                id={`${option?.id}-title`}
                                size='small'
                                fullWidth
                                multiline
                                rows={SETTINGS.ROWS_TEXT[`${ACTIVITY_TYPE}-options`] || 4}
                                value={option?.title}
                                onChange={(e) => modifyOptions('edit', optionIndex, 'title', e.target.value)}
                                inputProps={{ maxLength: maxCharOption}}
                            />
                            <ExtraInfoText>{option?.title?.length || 0} of {maxCharOption} characters</ExtraInfoText>
                            {SETTINGS.IS_RIGHT.includes(ACTIVITY_TYPE) &&
                                <StyledXRadioMarkAsCorrect
                                    label="Mark as correct answer:"
                                    id={`${option.id}-is_right`}
                                    options={CORRECT_OPTIONS}
                                    value={option?.is_right || false}
                                    paddingXSize='s'
                                    onChange={(e) => modifyOptions('edit', optionIndex, 'is_right', e.target.value)}
                                />
                            }
                            </StyledMarginContent>
                        </XCard>
                    ))}
                    {!SETTINGS.EXCLUDE_ADD_ANOTHER_ANSWER?.includes(ACTIVITY_TYPE) &&
                        < StyledButtonCenterContainer >
                            <XButton onClick={() => modifyOptions('add', targetIndex)}>Add another answer for card {target?.order}</XButton>
                        </StyledButtonCenterContainer>
                    }
                    </StyledMarginContent>
                </StyledXCardOption>
                
                )): <StyledCenterParagraph>No cards have been created yet.</StyledCenterParagraph>}
            {SETTINGS.ADD_NEW_OPTION.includes(ACTIVITY_TYPE) &&
                <StyledButtonCenterContainer>
                    <XButton onClick={() => modifyTargets('add')}>Add new card</XButton>
                </StyledButtonCenterContainer>
            }
                </StyledMarginContent>
        </StyledActivityCreationXCard >)
}
