import { useState } from "react"
import { ACTIVITIES_ANSWERS_OPTIONS_SETTINGS } from "../CONSTANTS"

const useMaxCharacters = (limitTextKey) => {
    const activitiesWithOptionAndTargets = ['correspondence']
    const activitiesWithContent = ['fill-the-blanks', 'fit-word', 'input-long']
    

    const getMaxCharOptions = () => {
        const optionKey = activitiesWithOptionAndTargets.includes(limitTextKey) ? `${limitTextKey}-options` : limitTextKey 
        return ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.LIMIT_OPTION_TEXT[optionKey]
    }
    
    const getMaxCharTargets = () => {
        const targetKey = limitTextKey + '-targets' 
        return ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.LIMIT_OPTION_TEXT[targetKey]
    }

    const getMaxCharContent = () => {
        const contentKey = activitiesWithContent.includes(limitTextKey) ? `${limitTextKey}_CONTENT` : 'CONTENT'
        return ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.LIMIT_OPTION_TEXT[contentKey]
    }

    const getMaxCharHeader = () =>{
        return ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.LIMIT_OPTION_TEXT.HEADER
    }

    const getMaxCharGlobalFeedback = () =>{
        return ACTIVITIES_ANSWERS_OPTIONS_SETTINGS.LIMIT_OPTION_TEXT.GLOBAL_FEEDBACK
    }

    return {
        getMaxCharOptions,
        getMaxCharTargets,
        getMaxCharContent,
        getMaxCharHeader,
        getMaxCharGlobalFeedback
    }

}

export default useMaxCharacters;
