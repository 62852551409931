import React from 'react';
import UserContext from './UserContext';
import useAuth from "./../../hooks/useAuth"

const UserProvider = ({ children }) => {
    const { user, handleLogIn, handleLogout, isAuthenticate, isUserLoaded } = useAuth();
    return (
        <UserContext.Provider value={{
            user,
            handleLogIn,
            handleLogout,
            isAuthenticate,
            isUserLoaded
        }}
        >
            {children}
        </UserContext.Provider>
    )
};

export default UserProvider;
