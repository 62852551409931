import { styled } from "@mui/system";
import { XLabel } from "@ximdex/xui-react/material";

export const StyledXLabel = styled(XLabel)`
    width: 100%;
    & label {
        min-width: 85px;
    }
`;

export const StyledXLabelLeft = styled(XLabel)`
    width: 100%;
    & .css-tbfrkj {
        width: 100%;
    }
`


export const StyledLangLi = styled('li')`
    color: black;
    transition: all 0.1s ease-in;
    margin: 0 0.5em;
    font-size: 18px;
    cursor: pointer !important;
    font-weight:  ${({isdefault}) => isdefault ? '500' : '400'};
    border-bottom:  ${({iselected}) => iselected ? '1px solid #43a1a2' : '1px solid transparent'};
    

`
