import React, { useEffect, useState } from 'react';
import { getAllExistingMedia, postMediaToCMS } from '../../../services/xevalApi.service';
import { ACTIVITIES_ANSWERS_OPTIONS_SETTINGS, MEDIA_PREVIEW, XDAM_URI_API } from '../../../CONSTANTS';
import { XGallery } from '@ximdex/xui-react/material';
import { StyledFontAwesomeIcon, ExtraInfoText, StyledXCard, StyledActivityCreationXCard, StyledMarginContent } from '../../../Styled-Components';
import EditorTinyMCE from '../../TinyMCE/EditorTinyMCE';
import useMaxCharacters from '../../../hooks/useMaxCharacters';

const ActivityContent = ({
    setActivityInfo,
    setIsActivityInfoUpdated,
    activityInfo,
    charCount,
    setCharCount,
    SETTINGS,
    TOOLTIPS
}) => {
    const {getMaxCharContent} = useMaxCharacters(activityInfo.type)
    const maxChar = getMaxCharContent()
    const [isOpen, setIsOpen] = useState(false);
    const [activityImg, setActivityImg] = useState(activityInfo?.image_url);
    const [totalChar, setTotalChar] = useState(0);
    const [editorId, setEditorId] = useState(0);

    const editActivityImg = (media) => {
        // console.log(media)
    }

    useEffect(() => {
        let newCharCount = [...charCount]
        let index = charCount.findIndex(item => item.id === editorId.id)
        let data = {id: editorId.id, exceed: totalChar > maxChar}
        if (index !== -1) {
            newCharCount[index] = data
        } else {
            newCharCount.push(data)
        }
        setCharCount(newCharCount)
    }, [totalChar])



    const handleTextChange = (evt, editor) => {
        setEditorId(editor)
        let newInfo = { ...activityInfo }
        newInfo.text = evt
        if (evt.includes('<math xmlns="http://www.w3.org/1998/Math/MathML"')) {
            newInfo.text = editor.getBody().getInnerHTML()
        }
        setActivityInfo(newInfo)
        setIsActivityInfoUpdated(false)
        setTotalChar(editor.getContent({ format: 'text' }).length)
    }

    return (
        <StyledActivityCreationXCard
            isCollapsable={false}
            // backgroundHeader={true}
            isCollapsed={false}
            title={`Editing default language: ${activityInfo.language_default}`}
            tooltip={
                TOOLTIPS.INSTRUCTIONS.CONTENT.hasOwnProperty(activityInfo?.type)
                    ? <StyledFontAwesomeIcon icon={['fa', 'circle-info']} title={TOOLTIPS.INSTRUCTIONS.CONTENT[activityInfo?.type]} size='1x' isInfo />
                    : false
            }
        >
            <StyledMarginContent>
            <EditorTinyMCE
                language={activityInfo.current_language.split('-')[0]}
                value={activityInfo?.text}
                handleEditorChange={(evt, editor) => handleTextChange(evt, editor)}
                config={{
                    max_num_characters: maxChar,
                    wirisformulaeditorlang: activityInfo.current_language.split('-')[0],
                }}
            />
            {/* <XInput
                style={{ margin: '8px', width: '95%' }}
                id='text'
                label='Text'
                size='normal'
                fullWidth
                multiline
                rows={4}
                value={activityText}
                onChange={(e) => editActivityInfo(e)}
                inputProps={{ maxLength: 200 }}
            /> */}

            <ExtraInfoText isError={totalChar > maxChar}>{totalChar} of {maxChar} characters</ExtraInfoText>

            {SETTINGS.MEDIA.includes(activityInfo.type) &&
                <>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            alt=''
                            style={{ cursor: 'pointer' }}
                            src={activityImg ? activityImg : "/assets/images/default_open_gallery.svg"}
                            onClick={() => setIsOpen(true)}
                            onError={(e) => { e.target.onError = null; e.target.src = '/assets/images/default_open_gallery.svg' }}
                        />
                    </div>
                    <XGallery
                        isGalleryOpen={isOpen}
                        setIsGalleryOpen={setIsOpen}
                        fetchMedia={getAllExistingMedia}
                        selectMedia={editActivityImg}
                        addNewMedia={postMediaToCMS}
                        mediaPreview={MEDIA_PREVIEW} 
                        hideAddNewMedia={XDAM_URI_API?.startsWith('http') ? false : true}
                    />
                </>
            }
            </StyledMarginContent>
        </StyledActivityCreationXCard>
    )
}

export default ActivityContent;
