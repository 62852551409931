import React, { useState } from 'react';
import {
    XRadio,
    XInput,
    XDropdown
} from '@ximdex/xui-react/material';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { StyledActivityCreationXCard, StyledMarginContent, StyledXCard, StyledXLabel } from '../../Styled-Components';
import { STATUS_OPTIONS, LANGUAGES_OPTIONS, UNITS_OPTIONS, CLIENT } from '../.././CONSTANTS';
import { updateAssessmentStatus } from '../../services/xevalApi.service';
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import MultipleValueInput from '../../components/MultipleValueInput/MultipleValueInput';

export const IdentifyingData = ({ assessmentInfo, setAssessmentInfo, setUpToDate }) => {
    const { showSpinner, hideSpinner } = useSpinner();

    const [unitsSelected, setUnitsSelected] = useState(() => {
        let unitsCollection = []
        assessmentInfo.units.forEach((unit) => {
            const validOption = UNITS_OPTIONS.find(unitOption => unitOption.value === unit)
            if (validOption) unitsCollection.push(validOption)
        })
        return unitsCollection
    })

    const editAssessmentInfo = (value, keyToEdit, action = 'add', index) => {
        let newAssessmentInfo = { ...assessmentInfo }

        if (keyToEdit !== 'isbn') {
            newAssessmentInfo[keyToEdit] = value
        } else {
            if (action === 'add') {
                newAssessmentInfo[keyToEdit].push(value)
            } else if (action === 'remove') {
                newAssessmentInfo[keyToEdit].splice(index, 1)
            } 
        }

        setUpToDate(false)
        setAssessmentInfo(newAssessmentInfo)
    }

    const editAssessmentStatus = (value) => {
        showSpinner()
        updateAssessmentStatus(assessmentInfo?.id, value)
            .then((res) => {
                setAssessmentInfo(assessmentState => ({ ...assessmentState, status: res.data.status}))
            })
            .catch(err => {
                console.error(err)
                XPopUp({
                    message:"There's been an error. Please, try again later",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            })
            .finally(() => {
                hideSpinner()
            })
    }

    const handleUnitsChange = (e, value) => {
        let newValues = []
        value.forEach(unit => newValues.push(unit.value))
        editAssessmentInfo(newValues, 'units')
        setUnitsSelected(value)
    }

    return (
        <StyledActivityCreationXCard
            isCollapsed={false}
            title='Assessment data'
        >
            <StyledMarginContent>
            {/* STATUS */}
            <XRadio
                label='Status: '
                options={[STATUS_OPTIONS[1], STATUS_OPTIONS[2]]}
                value={assessmentInfo?.status}
                paddingXSize='s'
                id='status'
                onChange={(e) => editAssessmentStatus(e.target.value)}
            />
            {/* TITLE */}
            <StyledXLabel
                label={'Name: '}
                paddingSize='s'
                component=
                {
                    <XInput
                        value={assessmentInfo?.title}
                        id='title'
                        size='small'
                        fullWidth
                        style={{ margin: '0' }}
                        onChange={(e) => editAssessmentInfo(e.target.value, 'title')}
                    />
                }
                htmlFor={'title'}
                labelLocation='left'
            />
            {/* ID */}
            <StyledXLabel
                label={'Id:'}
                paddingSize='s'
                component=
                {
                    <>
                        <XInput
                            disabled
                            value={assessmentInfo?.id}
                            style={{ width: '25%', minWidth: '80px', margin: '0' }}
                            id='id'
                            size='small'
                            fullWidth
                        />
                        <p style={{ margin: '8px', alignItems: 'center' }}>-</p>
                        <XInput
                            id='custom_client_id'
                            value={assessmentInfo?.custom_client_id}
                            placeholder='Optional custom id input.'
                            style={{ margin: '0' }}
                            size='small'
                            fullWidth
                            onChange={(e) => editAssessmentInfo(e.target.value, 'custom_client_id')}
                        />
                    </>
                }
                htmlFor={'id'}
                labelLocation='left'
                style={{ alignItems: 'center' }}
            />
            {/* LANGUAGE */}
            <StyledXLabel
                label={'Language:'}
                paddingSize='s'
                component=
                {
                    <XDropdown
                        value={assessmentInfo.language_default}
                        options={LANGUAGES_OPTIONS}
                        labelOptions='label'
                        disableClearable
                        disabled={false}
                        hasCheckboxes={false}
                        multiple={false}
                        width='95%'
                        bgColor='100'
                        size='small'
                        style={{ margin: '0' }}
                        onChange={(e, languages) => editAssessmentInfo(languages, 'language_default')}
                    />
                }
                htmlFor={'language'}
                labelLocation='left'
            />
            {CLIENT.toUpperCase() === 'MHE' &&
                <>
                    {/* UNITS */}
                    <StyledXLabel
                        label={'Units: '}
                        paddingSize='s'
                        component=
                        {
                            <XDropdown
                                value={unitsSelected}
                                options={UNITS_OPTIONS}
                                labelOptions='label'
                                disableClearable
                                multiple={true}
                                hasCheckboxes={true}
                                width='95%'
                                bgColor='100'
                                size='small'
                                style={{ margin: '0' }}
                                onChange={(e, value) => handleUnitsChange(e, value)}
                            />
                        }
                        htmlFor={'units'}
                        labelLocation='left'
                    />
                    {/* ISBN */}
                    <MultipleValueInput 
                        labelName='ISBNs: '
                        keyToEdit='isbn'
                        data={assessmentInfo}
                        editAssessmentData={editAssessmentInfo}
                    />
                </>
            }
            </StyledMarginContent>
        </StyledActivityCreationXCard>
    )
};
