
import { useEffect, useState } from 'react';
import { XevalAxios } from '../../services/xevalApi.service';

const ErrorHandler = ({children}) => {
    const [error, setError] = useState(null)
    const [loadInterceptor, setLoadInterceptor] = useState(false)

    useEffect(() => {
        const interceptors = XevalAxios.interceptors.response.use(handleSuccess, handleError);
        setLoadInterceptor(true)

        return () => XevalAxios.interceptors.response.eject(interceptors)
    },[])

    const handleError = (error) => setError(error)
    const handleSuccess = (response) => {
        if (response.status === 400) {
            // Do something here with Hooks or something else
            return response;
        }
        return response;
    }

    if (!loadInterceptor) return null;
    if (error) {
        throw error;
    }

    return children
}

export default ErrorHandler