import { styled } from "@mui/system";


export const StyledHeaderList = styled('h1')`
    margin: 0px;
`

export const StyledPageStatusHeader = styled('h1')`
    color: #3f3d56;
`
