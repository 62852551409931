import { useState } from 'react';
import USERSTATUS from '../types/userStatus';
import { COOKIE_NAME } from '../CONSTANTS';

const useAuth = () => {
    const [user, setUser] = useState({ status: USERSTATUS.NOT_LOADED })

    const handleLogOut = (cname = COOKIE_NAME) => {
        unsetCookie(cname);
        sessionStorage.removeItem(cname);
        sessionStorage.removeItem(`${cname}`);
    }

    const isAuthenticate = () => {
        return user.status === USERSTATUS.LOADED;
    }

    const isUserLoaded = () => {
        return user.status !== USERSTATUS.NOT_LOADED;
    }

    const getCookie = (cname = COOKIE_NAME) => {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const inicioJson = decodedCookie.indexOf('{');
        const finJson = decodedCookie.lastIndexOf('}');
        const jsonStr = decodedCookie.slice(inicioJson, finJson + 1);
        if(jsonStr) return JSON.parse(jsonStr);

        return null;
    }

    const setCookie = (cname = COOKIE_NAME, value) => {
        document.cookie = cname + "=" + JSON.stringify(value) + ",domain=.ximdex.net,path=/";
    }

    const unsetCookie = (cname) => {
        document.cookie = cname + '=,domain=.ximdex.net,path=/,Expires=Thu, 01 Jan 1970 00:00:01 GMT,';
    }

    const isAuth = () => {
        const cookie = getCookie(COOKIE_NAME);
        const limitedUser = JSON.parse(sessionStorage.getItem(`${COOKIE_NAME}`))

        return (cookie?.xdam_token || !!limitedUser?.is_connected === true) ? true : false;
    }

    const getXDAMToken = () => {
        const cookie = getCookie(COOKIE_NAME);
        const storage = JSON.parse(sessionStorage.getItem(COOKIE_NAME));

        const token = storage?.xdam_token ?? cookie?.access_token ?? process.env?.REACT_APP_TOKEN_XDAM ?? null;
        return token;
    }

    return {
        handleLogOut,
        isAuthenticate,
        isUserLoaded,
        getCookie,
        setCookie,
        isAuth,
        getXDAMToken
    }
};

export default useAuth;
