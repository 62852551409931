import React, { useEffect, useState } from 'react';
import {
    StyledDivFlexBetween,
    StyledDivFlexStartWrap,
    StyledGreenXButton,
    StyledMapControlsContainer,
} from '../../Styled-Components';
import { XDropdown } from '@ximdex/xui-react/material';
import {
    PAGINATION_OPTIONS,
    SORTING_OPTIONS,
} from '../../CONSTANTS';
import ActivitiesListTags from './ActivitiesListTags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FilterX } from 'lucide-react';

const ActivitiesListMapControls = ({
    sortBySearchParam,
    limitSearchParam,
    updateSearchParams,
    paginationMetaInfo,
    setActivitiesValues,
    setUnitsValues,
    searchParams
}) => {
    const [hasFilters,setHasFilters] = useState(false)

    useEffect(() => {
        const filterFlag = (searchParams.get('search') === null  && 
        searchParams.get('language_default') === null &&
        searchParams.get('isbn') === null  &&
        searchParams.get('status') === null &&
        searchParams.get('units') === null &&
        searchParams.get('type') === null)
        setHasFilters(filterFlag)
    }, [searchParams]);
    

    const handleRemoveFilters = () => {
        const tagsType = ['search','language_default','isbn', 'status', 'units', 'type'] 
        tagsType.forEach(type => {
            updateSearchParams(type, '')
            if(type === 'type' || type === 'units'){
                setUnitsValues([])
                setActivitiesValues([])
            }
            updateSearchParams(type, '')
        });       
    }

    return (
        <StyledMapControlsContainer>

            {/* ACTIVITIES PAGE CONTROLS */}
            <StyledDivFlexBetween style={{width: '100%', margin: '0 0 1em 0', alignItems:'center', justifyContent:'space-between'}}>
                <h4 style={{fontSize:'16px', margin: '0'}}>
                    {`Activities founded: ${(paginationMetaInfo?.total || paginationMetaInfo?.total === 0) ? `${paginationMetaInfo.total}` : 'searching...'}`}
                </h4>
                <div style={{display:'flex'}}>
                <XDropdown
                    value={limitSearchParam}
                    onChange={(e) => updateSearchParams('limit', e.target.dataset.optionIndex)}
                    options={PAGINATION_OPTIONS}
                    labelOptions='label'
                    label='Per page'
                    bgColor='100'
                    width='150px'
                    size="small"
                    style={{ marginLeft: '0.5em'}}
                    hasCheckboxes={false}
                    multiple={false}
                    disableClearable
                />
                <XDropdown
                    value={sortBySearchParam}
                    onChange={(e) => updateSearchParams('sortBy', e.target.dataset.optionIndex)}
                    options={SORTING_OPTIONS}
                    labelOptions='label'
                    label='Sort by'
                    width='250px'
                    size="small"
                    bgColor='100'
                    style={{ marginLeft: '0.5em'}}
                    hasCheckboxes={false}
                    multiple={false}
                    disableClearable
                />
                </div>
            </StyledDivFlexBetween>
            
            {/* ACTIVITIES TAGS */}
            <StyledDivFlexBetween style={{width: '100%', alignItems:'center', justifyContent:'flex-start', borderBottom: `1px solid ${!hasFilters ? 'lightgrey' : 'transparent'}`}}>
                {!hasFilters && 
                <>
                <StyledGreenXButton 
                    style={{margin: '8px'}} 
                    title="Remove all filters" 
                    onClick={() => handleRemoveFilters()}
                >
                    <FilterX size={20}/>
                </StyledGreenXButton>
                <p style={{fontSize:'20px', marginRight: '1em'}}>{`Filters: `}</p>
                </>
                }
                <ActivitiesListTags
                    setActivitiesValues={setActivitiesValues}
                    setUnitsValues={setUnitsValues}
                    searchParams={searchParams}
                    updateSearchParams={updateSearchParams}
                />
            </StyledDivFlexBetween>
        </StyledMapControlsContainer>
    );
}

export default ActivitiesListMapControls;
