import MODES from "./types/modes";

let { REACT_APP_MODE, REACT_APP_CLIENT, REACT_APP_XEVAL_API } = process.env

const config = {
    mode: REACT_APP_MODE ? MODES[REACT_APP_MODE.toUpperCase()] ?? MODES.PRODUCTION : MODES.PRODUCTION,
    xevalUri: REACT_APP_XEVAL_API[REACT_APP_XEVAL_API.length - 1] === "/" ? REACT_APP_XEVAL_API.substring(0, REACT_APP_XEVAL_API.length - 1) : REACT_APP_XEVAL_API
}

export default config;
