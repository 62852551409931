import React,{useState, useEffect} from 'react';
import { StyledXLabel, StyledButtonCenterContainer, StyledContainerPreviewSpinner, StyledFontAwesomeIcon, StyledMarginContent, StyledXCard, StyledGreenXButton } from '../../Styled-Components';
import { XInput, XDropdown, XButton, XSpinner, XPopUp } from '@ximdex/xui-react/material';
import { CLIENT, ACTIVITIES_LIST_ALL_TYPES, UNITS_OPTIONS } from '../../CONSTANTS';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { X, XCircle } from 'lucide-react';

export const AddActvitiesModal = ({
    activitiesToBeAdded,
    setActivitiesToBeAdded, 
    updateAssessmentInfo,
    potentialActivitiesList,
    assessmentInfo,
    activitiesListTotal,
    activitiesParams,
    setActivitiesParams,
    initActivitiesParams,
    getActivities,
    setAddActivities}
    ) => {

    const [newId, setNewId] = useState()
    const [newIsbn, setNewIsbn] = useState()

    useEffect(() => {
        getActivities()
    }, [])

    // Check activityId
    useEffect(() => {
        const existActivity = assessmentInfo.activities.filter(activity => activity.id.toString() === newId)  

        if(existActivity.length > 0){
            XPopUp({
                message:"This activity already exist in the assessment. ",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
            setTimeout(() => {setNewId('')}, 1000);
        }
        
    }, [newId]);

    const editActivitiesParams = (param, value) => { 
        // if(param === 'id' && value)
        let newParams = activitiesParams.split("&")
        // set new param according to the value's type (string or array)
        let newParam = ''
        if (typeof value === 'string') {
            newParam = `${param}=${value}`
        } else if (Array.isArray(value) && value?.length > 0) {
            let values = value.map(v=> v.value)
            newParam = `${param}=${values.join(',')}`
        }

        // modify params array to 1. remove, 2. replace, or 3. add the param being edited
        const indexParam = newParams.findIndex(p => p.startsWith(param))
        if (indexParam > -1 && !value) {
            newParams.splice(indexParam, 1)
        } else if (indexParam > -1) {
            newParams[indexParam] = newParam
        } else {
            newParams.push(newParam)
        }
       
        // turn array back to string and save the new string in the state variable
        newParams = newParams.join("&")
        setActivitiesParams(newParams)
    }

    const addToCurrentActivitiesList = () => {
        setActivitiesParams(initActivitiesParams)
        updateAssessmentInfo()
        setAddActivities(prevState => ({...prevState, status: false}))
    }

    const handleFilterOptions = (options, { inputValue }) => {
        return options.filter((option) =>
          option.name?.toLowerCase()?.includes(inputValue.toLowerCase())
        );
      };
    

    return (<>
            <StyledXCard
                style={{margin: '0'}}
                isCollapsed={false}
                title='ADD ACTIVITIES'
                controls={[{
                    component:                 <StyledGreenXButton 
                    onClick={() => setAddActivities(prevState => ({...prevState, status: false}))} 
                    size='small' 
                    variant='text'  
                    style={{borderRadius: '50%'}}                  
                    >
                        <XCircle size={28} color='#42b883'/>
                </StyledGreenXButton>
                }]}
            >
                <StyledMarginContent>
                <h2 style={{ textAlign: 'justify' }}>Filters <span style={{ fontWeight:'normal', fontSize:'0.4em' }}>(optional)</span></h2>
                {/* ID */}
                <StyledXLabel
                    label={'Id:'}
                    paddingSize='s'
                    component=
                    {<>
                        <XInput
                            value={newId}
                            id='id'
                            size='small'
                            fullWidth
                            style={{ margin: '0 0px 0 0' }}
                            onChange={(e) => setNewId(e.target.value)}
                            onBlur={() => editActivitiesParams('id', newId)}
                        />
                    </>}
                    htmlFor={'id'}
                    labelLocation='left'
                />
                {/* Activity Type */}
                <StyledXLabel
                    label={'Type: '}
                    paddingSize='s'
                    component=
                    {<XDropdown
                        options={ACTIVITIES_LIST_ALL_TYPES.filter(activity => activity.value !== 'input-long')}
                        displayEmpty
                        hasCheckboxes={true}
                        multiple={true}
                        disableClearable
                        labelOptions='label'
                        width='100%'
                        bgColor='100'
                        size='small'
                        style={{ margin: '0' }}
                        onChange={(e, value) => editActivitiesParams('type', value)}
                    />}
                    htmlFor={'type'}
                    labelLocation='left'
                />
                {CLIENT === 'MHE' && 
                    (<>
                        {/* ISBNs */}
                        <StyledXLabel
                            label={'ISBN:'}
                            paddingSize='s'
                            component=
                            {<>
                                <XInput
                                    value={newIsbn}
                                    id='isbn'
                                    size='small'
                                    fullWidth
                                    style={{ margin: '0 0px 0 0' }}
                                    onChange={(e) => setNewIsbn(e.target.value)}
                                    onBlur={() => editActivitiesParams('isbn', newIsbn)}
                                />
                            </>}
                            htmlFor={'isbn'}
                            labelLocation='left'
                        />
                        {/* UNITS */}
                        <StyledXLabel
                            label={'Units:'}
                            paddingSize='s'
                            component=
                            {<XDropdown
                                options={UNITS_OPTIONS}
                                displayEmpty
                                value={activitiesParams.units}
                                hasCheckboxes={true}
                                multiple={true}
                                disableClearable
                                labelOptions='label'
                                width='100%'
                                bgColor='100'
                                size='small'
                                style={{ margin: '0' }}
                                onChange={(e, value) => editActivitiesParams('units', value)}
                            />}
                            htmlFor={'units'}
                            labelLocation='left'
                        />
                    </>)
                }
                <StyledButtonCenterContainer>
                    <XButton 
                        onClick={() => getActivities()}
                        disabled={activitiesListTotal === undefined ? true : false}
                    >
                        {activitiesListTotal === undefined ? 'Applying filters' : 'Apply new filters'}
                    </XButton>
                </StyledButtonCenterContainer>
                <h2 style={{ textAlign: 'justify' }}>Activity selector <span style={{ fontWeight:'normal', fontSize:'0.4em' }}>(Note: If you cannot find the activity you are looking for, you might want to check if its status is set to <i>ACTIVE</i>.)</span></h2>

                {activitiesListTotal !== undefined ? (
                    <p style={{ textAlign: activitiesListTotal === 0 ? 'center' : 'justify' }}>
                        {activitiesListTotal} <i>active</i> activities found in total. {activitiesListTotal > 400 && 'The selector below will display only 400 of these.'}
                    </p>
                ) : (<StyledContainerPreviewSpinner style={{margin: '3.3em 0'}}>
                        < XSpinner
                            width="75px"
                            border="15px"
                            color="#43a1a2"
                            backgroundColor="#f2f2f2"
                        />
                    </StyledContainerPreviewSpinner>
                )}
                {potentialActivitiesList && activitiesListTotal && (
                    <>                    
                        <XDropdown
                            onChange={(e, newSelectedActivities) => setActivitiesToBeAdded(newSelectedActivities)}
                            value={activitiesToBeAdded}
                            options={potentialActivitiesList || potentialActivitiesList.forEach((array, index) => array[index])}
                            hasCheckboxes={true}
                            multiple={true}
                            disableClearable 
                            filterOptions={handleFilterOptions}
                            renderOption={(option) => `Id: ${option?.id} Name: ${option?.name} (${option?.type})`}
                            style={{ margin: '8px' }}
                            width='98%'
                            bgColor='100'
                        />
                        <StyledButtonCenterContainer>
                            <XButton onClick={() => addToCurrentActivitiesList()}>
                                Update current activities list
                            </XButton>
                        </StyledButtonCenterContainer>
                    </>
                )}
                </StyledMarginContent>
            </StyledXCard>
            </>

    );
}

