import React from "react";
import { getActivityById, getAiTranslation, removeIAcache } from "../services/xevalApi.service";
import { XPopUp } from "@ximdex/xui-react/material";
import useSortOptionsTargets from "./useSortOptionsTargets";
import { ACTIVITIES_ANSWERS_OPTIONS_SETTINGS } from "../CONSTANTS";


const iaTemplateTransaledHARD = {
    "assessments": [],
    "available_languages": [],
    "background": null,
    "categories": null,
    "created_at": "2023-10-24T09:44:28",
    "criterion_id": null,
    "current_language": "es-ES",
    "custom_client_id": null,
    "feedbacks": [
        {
            "success_percentage": 0,
            "text": "0%: Sorry, your answer is not correct."
        },
        {
            "success_percentage": 100,
            "text": "100%: Very good!"
        }
    ],
    "id": 320047,
    "image": null,
    "image_url": null,
    "isbn": [],
    "language_default": "es-ES",
    "level": 1,
    "matrix": null,
    "meta_activity": null,
    "meta_data": [],
    "name": "Ximdex Testing Match",
    "options": [
        {
            "area": null,
            "feedback": null,
            "id": 356794,
            "image": null,
            "image_url": null,
            "is_right": "TRUE",
            "order": 4,
            "target_order": 1,
            "text": null,
            "title": "Modern Age",
            "translatable_fields": [
                "title",
                "text",
                "feedback"
            ]
        },
        {
            "area": null,
            "feedback": null,
            "id": 356795,
            "image": null,
            "image_url": null,
            "is_right": "TRUE",
            "order": 5,
            "target_order": 3,
            "text": null,
            "title": "Middle Age",
            "translatable_fields": [
                "title",
                "text",
                "feedback"
            ]
        },
        {
            "area": null,
            "feedback": null,
            "id": 356796,
            "image": null,
            "image_url": null,
            "is_right": "TRUE",
            "order": 6,
            "target_order": 2,
            "text": null,
            "title": "Ancient Age",
            "translatable_fields": [
                "title",
                "text",
                "feedback"
            ]
        }
    ],
    "props": null,
    "status": "ACTIVE",
    "tags": null,
    "targets": [
        {
            "accepted_values": [],
            "area": null,
            "id": 356791,
            "image": null,
            "image_url": null,
            "order": 1,
            "text": null,
            "title": "First",
            "translatable_fields": [
                "title",
                "text",
                "accepted_values",
                "unaccepted_values"
            ],
            "unaccepted_values": []
        },
        {
            "accepted_values": [],
            "area": null,
            "id": 356792,
            "image": null,
            "image_url": null,
            "order": 2,
            "text": null,
            "title": "Second",
            "translatable_fields": [
                "title",
                "text",
                "accepted_values",
                "unaccepted_values"
            ],
            "unaccepted_values": []
        },
        {
            "accepted_values": [],
            "area": null,
            "id": 356793,
            "image": null,
            "image_url": null,
            "order": 3,
            "text": null,
            "title": "Third",
            "translatable_fields": [
                "title",
                "text",
                "accepted_values",
                "unaccepted_values"
            ],
            "unaccepted_values": []
        }
    ],
    "text": "",
    "text_color": null,
    "title": "Match the options as corresponds",
    "translatable_fields": [
        "name",
        "title",
        "text",
        "options",
        "targets",
        "feedbacks"
    ],
    "type": "match",
    "units": [],
    "updated_at": "2023-10-24T09:44:28",
    "version": 1
}


const useTranslations = () => {
    const [, sortOptionsAndTargets] = useSortOptionsTargets();

    /** Creates the object that is used to send  to xOwl to obtain the translation associated to the values */
    const create_IAObject = (activityInfo) => {
        let object = {} 
        activityInfo?.translatable_fields?.forEach(translatableField => {
            if(activityInfo[translatableField] !== null && activityInfo[translatableField] !== '' &&
                (translatableField === "name" || translatableField === "title" || translatableField === "text"))
            {
                object[translatableField] = {
                    id: `${activityInfo.id}${translatableField}`,
                    // id: translatableField === "text" ? activityInfo.id + '#content' : activityInfo.id,
                    [translatableField]: activityInfo[translatableField]
                }
            }
            if(translatableField === 'options' && activityInfo.options?.length > 0 &&
             (activityInfo.image === null || activityInfo.image === '')){
                let IAOptionsArray = []
                activityInfo.options.forEach((option,key) => {
                    if(option?.title){
                        IAOptionsArray.push({
                            id: `${activityInfo.id}option${option.id}`,
                            title: option?.title
                        })
                    }
                })
                object[translatableField] = IAOptionsArray
            }
            if(translatableField === 'targets' && activityInfo.targets?.length > 0){
                let IATargetsArray = []
                activityInfo.targets.forEach((target,key) => {
                    let targetCopy = {
                        id: `${activityInfo.id}target${target.id}` 
                    }
                    if(target?.title){
                        targetCopy = {...targetCopy, title:target?.title}
                    }
                    if(target.accepted_values.length > 0){
                        targetCopy = {...targetCopy, accepted_values: target.accepted_values}
                    }
                    if(target.unaccepted_values.length > 0){
                        targetCopy = {...targetCopy, unaccepted_values: target.unaccepted_values}
                    }
                    IATargetsArray.push(targetCopy)
                })
                object[translatableField] = IATargetsArray
           }
            if(translatableField === 'feedbacks' && activityInfo.feedbacks?.length > 0){
                let IAFeedbackArray = []
                activityInfo.feedbacks.forEach((feedback,key) => {
                    if(feedback?.text !== ""){
                        IAFeedbackArray.push({
                            text:feedback?.text,
                            id: `${activityInfo.id}feedback${key}`
                        })
                    }
                })
                object[translatableField] = IAFeedbackArray
           }
        });
        // return (JSON.stringify(object))
        return (object)
    }

    /** Creates the activity info with values translated by IA 
     *  The returning object is used to display the translated values in the front end.
     * */ 
    const createActivityInfoByIA = (activityInfo, iaTranslations) => {
        let activityInfoByIA = {...activityInfo}
        // Clean available languages
        activityInfoByIA.available_languages = []

        //replace string
        activityInfoByIA.text = iaTranslations.text ?? ""
        activityInfoByIA.title = iaTranslations.title ?? ""
        activityInfoByIA.name = iaTranslations.name ?? ""

        // replace feedbacks
        if(iaTranslations.feedbacks && iaTranslations.feedbacks.length > 0){
            let feedbacks = []
            activityInfo?.feedbacks?.forEach((feedback,index) => {    
                let item_feedback = {...feedback}
                item_feedback.text = iaTranslations?.feedbacks[index].text
                feedbacks.push(item_feedback)
            })
            activityInfoByIA.feedbacks = feedbacks
        }

        // replace options
        if(iaTranslations.options && iaTranslations?.options.length > 0){
            let options = []
            activityInfo?.options?.forEach((option,index) => {    
                let item_option = {...option}
                item_option.title = iaTranslations?.options[index].title
                options.push(item_option)
            })
            activityInfoByIA.options = options
        }

        // replace targets
        if(iaTranslations.targets && iaTranslations?.targets.length > 0){
            let targets = []
            activityInfo?.targets?.forEach((target,index) => {    
                let item_target = {...target}
                item_target.title = iaTranslations?.targets[index].title
                targets.push(item_target)
            })
            activityInfoByIA.targets = targets
        }

        return activityInfoByIA;
    }

    /** Removes quotes from the translated obtain from xOwl */
    const removeQuotesFromResponse = (translation) => {
        if(translation.startsWith('"')){
            translation = translation.slice(1);
        }
        if(translation.endsWith('"')){
            translation = translation.slice(0,-1);
        }
        return translation
    }

    /** Gets and creates the object with the translations obtained from xOwl */
    const handleIATranslation = async (activityInfo, languageSelected) => {
        const iaObject = create_IAObject(activityInfo)
        let iaTranslations = {}

        for (const key in iaObject) {
            if (key === "name" || key === "title" || key === "text"){
                let translation = ""
                try {
                    let objectToTranslate = iaObject[key]
                    translation = await getAiTranslation(languageSelected, JSON.stringify(objectToTranslate[key]), objectToTranslate.id)
                    iaTranslations[key] = removeQuotesFromResponse(translation)
                } catch (error) {
                    iaTranslations[key] = removeQuotesFromResponse(translation)
                }
            }else if(key === "options" || key === "targets" || key === "feedbacks"){
                let translationsArray = []
                try {
                    for (const subKey in iaObject[key]){
                        let objectToTranslate = key === "feedbacks" ? iaObject[key][subKey].text : iaObject[key][subKey].title
                        let translation = await getAiTranslation(languageSelected, JSON.stringify(objectToTranslate), iaObject[key][subKey].id)
                        translationsArray.push({
                            [key === "feedbacks" ? 'text' : 'title']: removeQuotesFromResponse(translation)
                        })
                    }
                    iaTranslations[key] = translationsArray 
                } catch (error) {
                    iaTranslations[key] = translationsArray 
                }
            }
        }
        const activityInfoTranslatedByIA = createActivityInfoByIA(activityInfo, iaTranslations)
        return (activityInfoTranslatedByIA);
    }

    /** Refresh xOwl cache and get new translation */
    const handleRequestUniqueTranslation = async (translatableField, languageSelected, activityInfo, activityInfoTranslatedByIA, position) => {
        let iaObject = create_IAObject(activityInfo)
        let objectToTranslate = {}

        // If position => Is an array of option/target/feedback 
        if(position !== null){
            objectToTranslate = translatableField === "feedbacks" ? iaObject[translatableField][position].text : iaObject[translatableField][position].title
        }else{
            objectToTranslate = iaObject[translatableField][translatableField]
        }

        // Get new translation
        // let translation = "NUEVA TRADUCCION"
        const translationObjectId = position === null ? iaObject[translatableField].id : iaObject[translatableField][position].id
        let translation = ""
        try {
            await removeIAcache(translationObjectId, languageSelected)
            translation = await getAiTranslation(languageSelected, JSON.stringify(objectToTranslate), translationObjectId)
        } catch (error) {
            console.log("Error getting new translation", error)
        }


        // Replace new translation into ActivityInfoTranslatedByIA
        let activityInfoTranslatedCopy = {...activityInfoTranslatedByIA}
        // If position => Is an array of option/target/feedback 
        if(position !== null){
            let itemArrayCopy = activityInfoTranslatedCopy[translatableField][position]
            itemArrayCopy[translatableField === "feedbacks" ? 'text' : 'title'] = translation
            activityInfoTranslatedCopy[translatableField][position] = itemArrayCopy
        }else{
            activityInfoTranslatedCopy[translatableField] = translation
        }

        return activityInfoTranslatedCopy
    }

    /** Gets the title for the xCard component used in translations.  */
    const getCardTitle = (field) => {
        switch (field) {
            case 'title': return 'Header'
            case 'text': return 'Content'
            case 'name': return 'Name'
            case 'feedbacks': return 'Global feedback'
            case 'options': return 'Options'
            case 'targets': return 'Targets'
            }
    }

    /** Create an empty activity info template to fill with translations. Used when creating a new translation */
    const buildTemplateTranslation = async (activityInfo)=>{
        let translationStorage = JSON.parse(localStorage.getItem("create-translation"))
        
        // CREATE TEMPLATE TRANSLATIONS
            let newData = { ...activityInfo }
            if(!activityInfo.translatableField){
                await getActivityById(activityInfo.id).then(resp => {
                    newData = {...newData, resp}
                })
            }
            newData = sortOptionsAndTargets(newData);
            let template = newData
            template.available_languages = []
            newData?.translatable_fields?.forEach(translatableField => {
                if(translatableField === "options" && newData.options !==null && newData.options.length > 0){
                    let options = []
                    if(translationStorage?.options) {
                        options = translationStorage.options
                    } else {
                        newData.options?.forEach((option,i) => {
                            var optionCopy = {...option}
                            option.translatable_fields.forEach(translatableField => {
                                optionCopy = {
                                    ...optionCopy,
                                    [translatableField]: ''
                                }
                            })
                            options.push(optionCopy)
                        })
                    }
                    template[translatableField] = options
                }
                if (translatableField === "targets" && newData.targets !==null && newData.targets.length > 0){
                    let targets = []
                    if(translationStorage?.targets) {
                        targets = translationStorage.targets
                    } else {
                        newData.targets?.forEach((target,i) => {
                            let targetCopy = {...target}
                            target.translatable_fields.forEach(translatable_field => {
                                if(translatable_field === 'unaccepted_values' || translatable_field === 'accepted_values'){
                                    targetCopy = {...targetCopy,[translatable_field]: []}
                                }else{
                                    targetCopy[translatable_field] = ""
                                }
                            })
                            targets.push(targetCopy)
                        })
                    }
                    template[translatableField] = targets
                }
                if (translatableField === "feedbacks" && newData.feedbacks !== null){
                    let arrayFeedbacks = []
                    if(translationStorage?.feedbacks){ 
                        arrayFeedbacks = translationStorage.feedbacks
                    }else{
                        newData.feedbacks?.forEach((feedback) => {
                            let feedbackCopy = {
                                ...feedback,
                                text: ''
                            }
                            arrayFeedbacks.push(feedbackCopy)
                        })
                    }
                    template[translatableField] = arrayFeedbacks;
                }
                if (translatableField === "name" || translatableField === "title" || translatableField === "text") {
                    if(translationStorage[translatableField]) {
                        template[translatableField] = translationStorage[translatableField]
                    }else{
                        template[translatableField] = ""
                    }
                }
            })
            return template
    }

    /** Check for empty fields when saving a translation */
    const checkEmptyInputs = (activityInfo, activityTemplateTranslation) => {
        let emptyFlags = {
            strings: false,
            options: false,
            targets: false,
            feedbacks: false,
        }
        activityInfo?.translatable_fields?.forEach(translatableField => {
            if(activityInfo[translatableField] !== null && activityInfo[translatableField] !== '' &&
                (translatableField === "name" || translatableField === "title" || translatableField === "text"))
            {
                if(activityTemplateTranslation[translatableField] === "") {
                    emptyFlags.strings =  true
                }
            }
            if(translatableField === 'options' && activityInfo.options?.length > 0 &&
             (activityInfo.image === null || activityInfo.image === '')){
                activityInfo.options.forEach((option,key) => {
                    option.translatable_fields.forEach((translatable_field,j) => {
                        if(option[translatable_field] && activityTemplateTranslation.options[key][translatable_field] === ""){
                            emptyFlags.options= true
                        }
                    })
                })
            }
            if(translatableField === 'targets' && activityInfo.targets?.length > 0){
               activityInfo.targets.forEach((target,key) => {
                    target.translatable_fields.forEach((translatable_field,j) => {
                        if(target[translatable_field] && activityTemplateTranslation.targets[key][translatable_field] === ""){
                            emptyFlags.targets = true
                        }
                   })
               })
           }
            if(translatableField === 'feedbacks' && activityInfo.feedbacks?.length > 0){
               activityInfo.feedbacks.forEach((feedback,key) => {
                    if(feedback?.text !== "" && activityTemplateTranslation.feedbacks[key]?.text === ""){
                        emptyFlags.feedbacks = true
                    }
                })
           }
        });
        return emptyFlags
    }

    return {
            handleIATranslation,
            getCardTitle, 
            buildTemplateTranslation,
            checkEmptyInputs,
            handleRequestUniqueTranslation
        };
}

export default useTranslations;
