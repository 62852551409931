import { XBox, XButton, XDropdown } from '@ximdex/xui-react/material';
import React, { useEffect, useState } from 'react';
import { StyledDivCenterY, StyledDivFlexBetween, StyledFontAwesomeIcon } from '../../Styled-Components';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { LANGUAGES_OPTIONS } from '../../CONSTANTS';

const DuplicateActivityModal = ({setOpenModal, activityToDuplicate, handleCloneActivity}) => {
    
    const [availableLanguages, setAvailableLanguages] = useState([...activityToDuplicate.available_languages,activityToDuplicate.language_default]) 
    const [languageDefaultSelected, setLanguageDefaultSelected] = useState(LANGUAGES_OPTIONS.filter(lang => lang.value === activityToDuplicate.language_default)[0])

    const [langsToCloneOptions, setLangsToCloneOptions] = useState([])
    const [langsToCloneSelected, setLangsToCloneSelected] = useState([])

    useEffect(() => {
        setLangsToCloneOptions(LANGUAGES_OPTIONS.filter(lang => availableLanguages.includes(lang.value) && lang.value !== languageDefaultSelected.value))
        setLangsToCloneSelected(availableLanguages.filter(lang => lang !== languageDefaultSelected.value))
    }, [languageDefaultSelected]);


    const handleLangsToClone = (e) => {
        let langsToCloneSelectedCopy = langsToCloneSelected;
        if(langsToCloneSelected.includes(e.target.name)){
            langsToCloneSelectedCopy = langsToCloneSelected.filter(lang => lang !== e.target.name)
        }else{
            langsToCloneSelectedCopy.push(e.target.name)
        }
        setLangsToCloneSelected(langsToCloneSelectedCopy)
    }

    const handleClone = async () => {
        await handleCloneActivity(activityToDuplicate, langsToCloneSelected, languageDefaultSelected.value)
    }

    return (
        <>
            <StyledDivFlexBetween>
                <h2 style={{margin: '0'}}>Duplicate Activity</h2>
                <XButton 
                    onClick={() => setOpenModal(false)} 
                    size='small' 
                    variant='text'                    
                    >
                    <StyledFontAwesomeIcon size='2x' 
                        icon={faXmarkCircle} 
                        title={'Close window'}
                        />
                </XButton>
            </StyledDivFlexBetween>
            <StyledDivCenterY style={{flexDirection:'column'}}>
                <StyledDivCenterY style={{width: '100%', justifyContent: 'center', marginTop: '2em'}}>
                    <p style={{width: '30%'}}>IDIOMA POR DEFECTO</p>
                    <XDropdown
                        value={languageDefaultSelected}
                        onChange={(e) => setLanguageDefaultSelected(LANGUAGES_OPTIONS.filter(lang => availableLanguages.includes(lang.value))[e.target.dataset.optionIndex])}
                        options={LANGUAGES_OPTIONS.filter(lang => availableLanguages.includes(lang.value))}
                        labelOptions='label'
                        label='Select language'
                        width='50%'
                        size="small"
                        hasCheckboxes={false}
                        multiple={false}
                        disableClearable
                    />
                </StyledDivCenterY>
                <StyledDivCenterY style={{width: '100%', justifyContent: 'center', marginTop: '2em'}}>
                    <p style={{width: '30%'}}>IDIOMAS A CLONAR</p>
                    <StyledDivCenterY style={{justifyContent: 'flex-start', width: '50%', flexWrap: 'wrap'}}>
                        {langsToCloneOptions.map((lang, key) => 
                            <StyledDivCenterY key={key} style={{margin: '5px'}}> 
                                <label htmlFor={`${lang?.value}`}>
                                    {lang.label}
                                </label>
                                <input
                                    type="checkbox" 
                                    name={`${lang?.value}`}
                                    value={lang.value}
                                    defaultChecked={langsToCloneSelected.includes(lang.value) ? true : false}
                                    onChange={(e) => handleLangsToClone(e)}
                                    /> 
                            </StyledDivCenterY>
                        )}
                    </StyledDivCenterY>
                </StyledDivCenterY>
                <StyledDivCenterY style={{width: '100%', justifyContent: 'center', marginTop: '1em'}}>
                    <XButton
                        style={{margin: '1em'}}
                        onClick={handleClone}
                    >
                        Clonar
                    </XButton>
                    <XButton
                        style={{margin: '1em'}}
                        onClick={() => setOpenModal(false)}
                    >
                        Cancelar
                    </XButton>
                </StyledDivCenterY>
            </StyledDivCenterY>

        </>
    );
}

export default DuplicateActivityModal;
