import { styled } from "@mui/system";


export const StyledPoweredByImg = styled('img')`
    display: block; 
    width: 100%; 
    margin: 0 auto; 
    cursor: pointer;
`;

export const StyledOptionImg = styled('img')`
    max-height: 50px;
    width: 100%;
    // remove after xui update
    // margin-top: -38px;
`

export const StyledPageStatusImg = styled('img')`
    height: 50vh;
    width: auto;
`

export const StyledXOwlWhiteIcon = styled('img')`
    width: 35px;
    filter: brightness(0) invert(1);
    padding: 5px;
`

export const StyledXOwlBlackIcon = styled('img')`
    width: 35px;
    padding: 5px;
`
