import React from 'react';
import { XThemeProvider } from '@ximdex/xui-react/providers/';
import useBool from '../../hooks/useBool';
import XthemeContext from './XthemeContext';

const XthemeProvider = ({ children }) => {
    const [darkMode, setDarkMode, toggleDarkMode] = useBool(false);

    return (
        <XthemeContext.Provider
            value={{
                darkMode,
                setDarkMode,
                toggleDarkMode,
            }}
        >
            <XThemeProvider>
                {children}
            </XThemeProvider>
        </XthemeContext.Provider>
    )
};

export default XthemeProvider;
