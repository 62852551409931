import React, { useState,useEffect} from 'react'
import { getAllExistingMedia, postMediaToCMS } from '../../../services/xevalApi.service';
import { XDAM_URI_RESOURCE, MEDIA_PREVIEW, XDAM_URI_API } from '../../../CONSTANTS'
import { ContainerSeekAndFind } from '../../../Styled-Components/ContainerSeekAndFind'
import SeekAndFind from '../../SeekAndFind/SeekAndFind'
import { XGallery } from '@ximdex/xui-react/material'
import { StyledActivityCreationXCard, StyledButtonCenterContainer, StyledFontAwesomeIcon, StyledGreenXButton, StyledMarginContent } from '../../../Styled-Components';
import { Image } from 'lucide-react';

const SeekAndFindAnswer = ({ activityInfo, setActivityInfo, userActivityId, saveActivity, setIsActivityInfoUpdated, TOOLTIPS, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [updateImg, setUpdateImg] = useState(false);

    useEffect(() => {
        if (updateImg) {
            setUpdateImg(false);
            saveActivity();
        }
        
    }, [activityInfo.image])

    const editActivityImg = (media) => {
        let newInfo = { ...activityInfo };
        
        if (media.type.startsWith('image')) {
            newInfo.image = media?.path;
            newInfo.image_url = media?.url;
            setIsActivityInfoUpdated(false);
            setUpdateImg(true);
            setActivityInfo(newInfo);
            saveActivity();
            return media;
        };

        return false;
    };

    const onEdit = (data) => {
        let newData = { ...activityInfo }
        newData.targets[0].area = {
            radius: null, x: data.x, y: data.y
        }

        newData.options = newData.options.map(element => {
            if (element.is_right.toUpperCase() === 'TRUE') {
                element.area = {
                    radius: data.firstRatio, x: null, y: null
                }
            } else if (element.is_right.toUpperCase() === 'PARTIALLY') {
                element.area = {
                    radius: data.secondRatio, x: null, y: null
                }
            }
            return element;
        })
        setIsActivityInfoUpdated(false)
        setActivityInfo(newData)
    }

    if (!userActivityId) return null;

    return (
        <StyledActivityCreationXCard
            isCollapsable={false}
            // title='Answers'
            title={`Editing default language: ${activityInfo.language_default}`}
            tooltip={
                TOOLTIPS.INSTRUCTIONS.IMAGES.hasOwnProperty(activityInfo?.type) ?
                <StyledFontAwesomeIcon icon={['fa', 'circle-info']} title={TOOLTIPS.INSTRUCTIONS.IMAGES[activityInfo?.type]} size='1x' isInfo />
                : false
            }
        >
            <StyledMarginContent>
                <ContainerSeekAndFind >
                    <SeekAndFind
                        data={activityInfo}
                        showResult={false}
                        editionMode={true}
                        onedit={onEdit}
                        mediaPreview={XDAM_URI_RESOURCE}
                    />
                    <StyledButtonCenterContainer>
                        <StyledGreenXButton onClick={() => setIsOpen(true)} title='Open gallery.'>
                        <Image size={20} style={{marginRight:'5px'}}/>
                            Change image
                        </StyledGreenXButton>
                    </StyledButtonCenterContainer>
                </ContainerSeekAndFind>
                <XGallery
                    isGalleryOpen={isOpen}
                    setIsGalleryOpen={setIsOpen}
                    fetchMedia={getAllExistingMedia}
                    selectMedia={editActivityImg}
                    addNewMedia={postMediaToCMS}
                    mediaPreview={MEDIA_PREVIEW} 
                    hideAddNewMedia={XDAM_URI_API?.startsWith('http') ? false : true}
                />
            </StyledMarginContent>
        </StyledActivityCreationXCard>
    )
}

export default SeekAndFindAnswer
