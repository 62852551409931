import React from 'react'
import { ACTIVITIES_PREVIEWS } from '../../CONSTANTS'
import { StyledXContainerPreview } from '../../Styled-Components'

const SamplePreview = ({ type, showPreview, ...props }) => {

    const handleError = (e) => {
        e.target.onerror = null
        e.target.src='/assets/images/placeholder-image.jpg'
    }

    return (
        <StyledXContainerPreview>
        {ACTIVITIES_PREVIEWS.SHOW_PREVIEW.includes(type) && 
            <div style={{ margin: '0 auto', padding: '0.8rem 0' }}>
            {showPreview ? (
                <>
                    <h2 style={{ textAlign: 'center' }}>
                        SAMPLE PREVIEW
                    </h2>
                    <p style={{ textAlign: 'center' }}>
                        Area under construction
                    </p>
                    <img 
                        src={`/assets/images/sample-preview/${type}.jpg`} 
                        alt={`Sample preview for a ${type} activity`}
                        style={{ maxWidth: '100%', maxHeight: '70vh' }}
                        onError={(e) => handleError(e)}
                    />
                </>
            ) : (
                <>
                    <h2 style={{ textAlign: 'center' }}>
                        PREVIEW AREA
                    </h2>
                    <p style={{ textAlign: 'center' }}>
                        This type of activity has no preview available.
                    </p>
                </>      
            )}
        </div>}
        </StyledXContainerPreview>
    )
}

export default SamplePreview
