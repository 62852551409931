import React, { useEffect } from 'react';
import { 
    StyledPaginationContainer, 
    StyledPaginationGroupContainer,
} from './StyledPagination';
import { StyledContainerPreviewSpinner, StyledGreenXButton } from '../../Styled-Components'
import { XButton, XSpinner } from '@ximdex/xui-react/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faAngleDoubleLeft, 
    faAngleLeft, 
    faAngleDoubleRight, 
    faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';

const Pagination = ({paginationInfo, setPaginationMetaInfo}) => {

    useEffect(() => {
        if (!paginationInfo.isLoading) window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [paginationInfo.isLoading])

    const handlePageChange = (newPageValue) => {
        const newPage = Number(newPageValue);
        if (newPage && newPage !== paginationInfo?.current_page) {
            setPaginationMetaInfo({...paginationInfo, current_page: newPage, isLoading: true})
        } 
    }

    return(
        <StyledPaginationContainer>
            {(!paginationInfo?.isLoading ) ? 
                (
                    <>
                        <StyledPaginationGroupContainer>
                            <StyledGreenXButton 
                                disabled={paginationInfo.current_page === 1} 
                                size='small' 
                                onClick={() => handlePageChange(1)} 
                                title="Go to first page" 
                                style={{padding: '0 0.5rem', minWidth: 'unset'}}
                            >
                                <ChevronsLeft size={20}/>
                            </StyledGreenXButton>
                            <StyledGreenXButton 
                                disabled={!paginationInfo.prev_page}
                                size='small' 
                                onClick={() => handlePageChange(paginationInfo.prev_page)} 
                                title="Go to previous page"
                                style={{padding: '0 0.5rem', minWidth: 'unset'}}
                            >
                                <ChevronLeft size={20}/>
                            </StyledGreenXButton>
                        </StyledPaginationGroupContainer>
                        <StyledPaginationGroupContainer>
                            <p>Showing page {paginationInfo.current_page} of {paginationInfo.last_page}</p>
                        </StyledPaginationGroupContainer>
                        <StyledPaginationGroupContainer>
                            <StyledGreenXButton 
                                disabled={!paginationInfo.next_page} 
                                size='small' 
                                onClick={() => handlePageChange(paginationInfo.next_page)} 
                                title="Go to next page" 
                                style={{padding: '0 0.5rem', minWidth: 'unset'}}
                            >
                                <ChevronRight size={20}/>
                            </StyledGreenXButton>
                            <StyledGreenXButton 
                                disabled={paginationInfo.last_page === paginationInfo.current_page} 
                                size='small' 
                                onClick={() => handlePageChange(paginationInfo.last_page)}
                                title="Go to last page"
                                style={{padding: '0 0.5rem', minWidth: 'unset'}}
                            >
                                <ChevronsRight size={20}/>
                            </StyledGreenXButton>
                        </StyledPaginationGroupContainer>
                    </>
                ) : (
                    <StyledContainerPreviewSpinner>
                        <XSpinner
                            width="25px"
                            border="5px"
                            color="#43a1a2"
                            backgroundColor="#f2f2f2"
                        />
                    </StyledContainerPreviewSpinner>
                )
            }
        </StyledPaginationContainer>
    )
}

export default Pagination;
