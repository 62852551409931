import HTMLReactParser from 'html-react-parser';
import React, {useEffect, useMemo, useState} from 'react';
import { XInput, XButton, XTag } from '@ximdex/xui-react/material';
import { faArrowDown, faArrowsRotate} from "@fortawesome/free-solid-svg-icons";
import { StyledFontAwesomeIcon, StyledTranslationSpan, StyledTargetsContainer, StyledDivFlexWrap, StyledTranslationp, StyledDivCenterY, StyledXCardOption, ExtraInfoText, StyledActivityCreationXCard, StyledMarginContent, StyledXOwlBlackIcon, StyledGreenXButton, StyledFeedbackContainer} from '../../../Styled-Components'
import EditorTinyMCE from '../../TinyMCE/EditorTinyMCE';
import { ACTIVITY_TRANSLATION_AI, AVAILABLE_TRANSLATION_LANGUAGES_BY_AI, IA_LOADING_TRANSLATION_TEXT, IA_NOT_WORKING_MESSAGE, IA_SUGGESTED_TRANSLATION_TEXT, OPTIONS_WITH_TINY_EDITOR } from '../../../CONSTANTS';
import { CircularProgress } from '@mui/material';
import useMaxCharacters from '../../../hooks/useMaxCharacters';
import { ArrowDown, Plus, RefreshCcw } from 'lucide-react';

const TargetTranslations = ({
    activityInfo, 
    activityTranslated, 
    setActivityTranslated, 
    languageSelected,
    translatableField,
    activityTranslatedByIA,
    localStorageUpdate,
    setlocalStorageUpdate,
    setTranslationUpdated,
    iaLoadingSuggestions,
    newLanguage,
    requestNewTranslation,
    setCharCount,
    charCount
}) => {
    const {getMaxCharTargets} = useMaxCharacters(activityInfo.type)
    const maxChar = getMaxCharTargets()
    const hasTargets =  useMemo(() => {
        let hasvalues = []
        if(activityInfo.targets && activityInfo.targets.length > 0){

            activityInfo?.targets.forEach((element,i) => {
                let hasValuesItem = false;
                element.translatable_fields.forEach(_translatableField => {
                    if(element[_translatableField] !=null && Array.isArray(element[_translatableField])) {
                        hasValuesItem = true;
                    }
                })
                hasvalues.push(hasValuesItem)
            })
        }
        return hasvalues

    }, [activityInfo])
    const [unacceptedValue, setUnacceptedValue] = useState([]);
    const [acceptedValue, setAcceptedValue] = useState([]);
    const [totalChar, setTotalChar] = useState({})
    const [editorIds, setEditorIds] = useState([])
  
    useEffect(() => {
         if (activityInfo.targets && activityInfo.targets.length > 0) {
            const initialValues = Array(activityInfo.targets.length).fill('');
            setUnacceptedValue(initialValues);
            setAcceptedValue(initialValues);
        }
    }, [activityInfo.targets]);

    useEffect(() => {
        if (charCount && editorIds.length > 0) {
            let newCharCount = [...charCount] 
            editorIds.forEach(editorId => {
                let index = charCount.findIndex(item => item.id === editorId)
                let data = {id: editorId, exceed: totalChar[editorId] > (maxChar ?? 9999999)}
                if (index !== -1) {
                    newCharCount[index] = data
                } else {
                    newCharCount.push(data)
                }
            })
            setCharCount(newCharCount)
        }
    }, [totalChar])

    const handleArrayValues = (index, value, fieldType) => {
        if (fieldType === 'accepted_values') {
            const newAcceptedValues = [...acceptedValue];
            newAcceptedValues[index] = value;
            setAcceptedValue(newAcceptedValues);
        } else {
            const newUnacceptedValues = [...unacceptedValue];
            newUnacceptedValues[index] = value;
            setUnacceptedValue(newUnacceptedValues);
        }
        setTranslationUpdated(false)
        if(setlocalStorageUpdate) setlocalStorageUpdate(!localStorageUpdate)
    };

    const handleEditTranslation = (value,fieldOption,position, editor) => {
        if (editor && value.includes('<math xmlns="http://www.w3.org/1998/Math/MathML"')) {
            value = editor.getBody().getInnerHTML()
        }
        // const input = (OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type) && (fieldOption === 'title' || fieldOption === 'text')) ? e : e.target.value
        if(fieldOption === 'title' || fieldOption === 'text'){
            setActivityTranslated(prevState => ({
                ...prevState, 
                [translatableField]: prevState[translatableField].map((item, index) =>
                    index === position ? { ...item, [fieldOption]: value } : item
                )
            }))

        } else if (activityInfo.type === 'input-long' && fieldOption === 'accepted_values') {
             // Copy and push new value to the array
             let activityTranslatedCopy = {...activityTranslated}
            //  let targetToBeEdit = activityTranslatedCopy.targets[position]
             activityTranslatedCopy.targets[position][fieldOption] = [value]
             setAcceptedValue([value])
             setActivityTranslated(activityTranslatedCopy)
        } else if(fieldOption === "accepted_values" || fieldOption === "unaccepted_values"){
            // Copy and push new value to the array
            let activityTranslatedCopy = {...activityTranslated}
            let targetToBeEdit = activityTranslatedCopy.targets[position]
            targetToBeEdit[fieldOption].push(value)
            activityTranslatedCopy.targets[position] = targetToBeEdit
            let acceptedValueCopy = [...acceptedValue]
            acceptedValueCopy[position] = ''
            setAcceptedValue(acceptedValueCopy)
            setActivityTranslated(activityTranslatedCopy) 
        }else{
            setActivityTranslated(prevState => ({
                ...prevState, 
                [translatableField]: prevState[translatableField].map((item, index) =>
                    index === position ? { ...item, [fieldOption]: value } : item
                )
            }))
        }

        setTranslationUpdated(false)
        if(setlocalStorageUpdate) setlocalStorageUpdate(!localStorageUpdate)

        
        let key = fieldOption+position
        setTotalChar({
            ...totalChar,
            [key]: editor ? editor?.getContent({ format: 'text' }).length : value.length
        })
        setEditorIds([...editorIds, key])

        // TODO: FINISH FIX FOR THIS FUNCTION DOWN BELOW 


    }

    const handleApplyIARecomendation = (iaTranslation,fieldOption,position) => {
        handleEditTranslation(iaTranslation,fieldOption,position)
    }


    const checkInputLong = () => {
        if(activityInfo.type === "input-long" || activityInfo.type === "input"){
            return true;
        }else{
            return false;
        }
    }

    const handleKeyDown = (e, fieldOption, positon) => {
        if (e.key === 'Enter') {
            handleEditTranslation(e.target.value, fieldOption, positon)
        }
    };

    const removeValueFromArray = (fieldOption, position, valueIndex) => {
        const activityTranslatedCopy = {...activityTranslated}
        const targetToBeEdit = activityTranslatedCopy.targets[position]
        targetToBeEdit[fieldOption].splice(valueIndex, 1);
        setActivityTranslated(activityTranslatedCopy)
    }

    return (
        <> 
            {hasTargets.includes(true) &&(
                <StyledActivityCreationXCard
                    isCollapsable={false}
                    // backgroundHeader={true}
                    isCollapsed={false}
                    title={`${newLanguage ? 'Creating' : 'Editing'} language: ${languageSelected}`}
                    // title={getTitle(translatableField)}
                >
                    <StyledMarginContent>
                    {activityInfo?.targets?.map((target,key) => (
                        <div key={key}>
                            {hasTargets[key] && (
                                <StyledXCardOption 
                                    isCollapsable={true}
                                    isCollapsed={false}
                                    title={`Target ${key+1}`}
                                >
                                    <StyledMarginContent>
                                        {target.translatable_fields.map((translatable_field,j) => {
                                            return (
                                                <React.Fragment key={j}>
                                                    {/* STRINGS && INPUT-LONG */}
                                                    {(
                                                        (
                                                            target[translatable_field] !== null 
                                                            && typeof target[translatable_field] === 'string' 
                                                            && target[translatable_field] !== ''
                                                        ) || (
                                                            activityInfo.type === 'input-long'
                                                            && translatable_field === 'accepted_values'
                                                            && Array.isArray(target[translatable_field])
                                                        )
                                                    ) && (
                                                        <div>
                                                            {activityInfo.targets[key][translatable_field] && (
                                                                <>
                                                                    <p style={{textAlign:'left', color: 'grey', margin: '0'}}>
                                                                        <StyledTranslationSpan>
                                                                            Default language:
                                                                        </StyledTranslationSpan> 
                                                                        &nbsp;{HTMLReactParser(String(activityInfo.targets[key][translatable_field]))}
                                                                    </p>
                                                                    {ACTIVITY_TRANSLATION_AI && AVAILABLE_TRANSLATION_LANGUAGES_BY_AI.includes(languageSelected) && (
                                                                        <StyledDivCenterY>
                                                                            <StyledXOwlBlackIcon src="./assets/images/xowl-logo4.png" />
                                                                            <p style={{textAlign:'left', color: 'grey'}}>
                                                                                <StyledTranslationp style={{color: '#43a1a2'}}>
                                                                                    <StyledTranslationSpan>{IA_SUGGESTED_TRANSLATION_TEXT}</StyledTranslationSpan>
                                                                                    {iaLoadingSuggestions ? IA_LOADING_TRANSLATION_TEXT :(activityTranslatedByIA?.targets[key][translatable_field] 
                                                                                        ? HTMLReactParser(String(activityTranslatedByIA?.targets[key][translatable_field])) 
                                                                                        : IA_NOT_WORKING_MESSAGE)}
                                                                                </StyledTranslationp>    
                                                                            </p>
                                                                            {iaLoadingSuggestions ? (
                                                                                <CircularProgress size={15}/>
                                                                            ) : (
                                                                                <>
                                                                                    <StyledGreenXButton
                                                                                        onClick={() => handleApplyIARecomendation(activityTranslatedByIA.targets[key][translatable_field],translatable_field,key)} 
                                                                                        size='small' style={{minWidth: 'unset', height:'100%', marginRight:'0.2em'}}
                                                                                        disabled={activityTranslatedByIA?.targets[key][translatable_field] ? false : true}
                                                                                        title={"Use this translation."}
                                                                                    >
                                                                                        <ArrowDown size={15}/>
                                                                                    </StyledGreenXButton>
                                                                                    <StyledGreenXButton
                                                                                        onClick={() => requestNewTranslation(translatableField, key)} 
                                                                                        size='small' style={{minWidth: 'unset', height:'100%'}}
                                                                                        disabled={iaLoadingSuggestions}
                                                                                        title={"Request a different translation."}
                                                                                    >
                                                                                        <RefreshCcw size={15}/>
                                                                                    </StyledGreenXButton>
                                                                                </>
                                                                            )}
                                                                        </StyledDivCenterY>
                                                                    )}
                                                                </>
                                                            )}
                                                            <div style={{display: 'flex', flexDirection:'column'}} key={j}>
                                                                {OPTIONS_WITH_TINY_EDITOR.includes(activityInfo.type)
                                                                ?
                                                                    <EditorTinyMCE 
                                                                        language={languageSelected.split('-')[0]}
                                                                        value={checkInputLong(activityInfo.type) ? String(activityTranslated.targets[key][translatable_field][0] ?? '') : activityTranslated.targets[key][translatable_field]} 
                                                                        handleEditorChange={(e, editor) => handleEditTranslation(e, translatable_field, key, editor)} 
                                                                        config={{
                                                                            max_num_characters: maxChar ?? 9999999,
                                                                            wirisformulaeditorlang: languageSelected.split('-')[0],
                                                                        }}
                                                                    />
                                                                :
                                                                    <XInput
                                                                        placeholder="Enter translation"
                                                                        style={{ margin: '0 0 10px 0', width: '100%' }}
                                                                        size='normal'
                                                                        fullWidth
                                                                        multiline
                                                                        rows={2}
                                                                        value={activityTranslated?.targets[key][translatable_field]}
                                                                        onChange={(e) => handleEditTranslation(e.target.value, translatable_field, key)}
                                                                        inputProps={{ maxLength: maxChar ?? 9999999 }}
                                                                    />
                                                                }
                                                                { maxChar && (
                                                                    <ExtraInfoText isError={totalChar[translatable_field+key] > maxChar} >{totalChar[translatable_field+key]} of {maxChar} characters</ExtraInfoText>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* ARRAYS */}
                                                    {activityInfo.type !== 'input-long' &&                                                    target[translatable_field] !== null &&
                                                    typeof target[translatable_field] === 'object' &&
                                                    target[translatable_field].length > 0  && 
                                                        <StyledTargetsContainer>
                                                            <StyledXCardOption
                                                                isCollapsable={false}
                                                                // title={translatable_field === "accepted_values" ? 'Original correct word collection: ' : 'Original incorrect word collection: '}
                                                                marginXSize='m'
                                                            >
                                                                <StyledMarginContent>
                                                                <p style={{textAlign:'left', color: 'grey', margin: '0'}}>
                                                                    <StyledTranslationSpan>
                                                                        Default language: 
                                                                    </StyledTranslationSpan> 
                                                                    <StyledDivFlexWrap>
                                                                    {activityInfo.type === 'input-long' ? HTMLReactParser(String(activityInfo.targets[key][translatable_field][0])) 
                                                                    : 
                                                                        <>
                                                                            {target[translatable_field].map((value) => 
                                                                                <XTag
                                                                                    key={translatable_field === "accepted_values" ? `correctWordCollectionOriginalTag_${value}` : `incorrectWordCollectionOriginalTag__${value}`}
                                                                                    style={{ marginTop: '8px', color: 'grey', opacity: '0.75'}}
                                                                                    status={translatable_field === "accepted_values" ? "correct" : "incorrect"}
                                                                                    name={value}
                                                                                />   
                                                                            )}
                                                                        </>
                                                                    }
                                                                    </StyledDivFlexWrap>
                                                                </p>

                                                                {/* IA */}
                                                                {ACTIVITY_TRANSLATION_AI && AVAILABLE_TRANSLATION_LANGUAGES_BY_AI.includes(languageSelected) &&
                                                                <StyledDivCenterY>
                                                                    <StyledXOwlBlackIcon src="./assets/images/xowl-logo4.png" />
                                                                    <p style={{textAlign:'left', color: 'grey'}}>
                                                                        <StyledTranslationp style={{color: '#43a1a2'}}>
                                                                            <StyledTranslationSpan>{IA_SUGGESTED_TRANSLATION_TEXT}</StyledTranslationSpan>
                                                                            {iaLoadingSuggestions ? IA_LOADING_TRANSLATION_TEXT : (activityTranslatedByIA?.targets[key][translatable_field] 
                                                                                ? HTMLReactParser(String(activityTranslatedByIA.targets[key][translatable_field])) 
                                                                                : IA_NOT_WORKING_MESSAGE)}</StyledTranslationp>     
                                                                    </p>
                                                                    {iaLoadingSuggestions ?
                                                                        <CircularProgress size={15}/>
                                                                    :
                                                                        <>
                                                                            <XButton
                                                                                onClick={() => handleApplyIARecomendation(activityTranslatedByIA.targets[key][translatable_field],translatable_field,key)} 
                                                                                size='small' style={{minWidth: 'unset', height:'100%', marginRight:'1em'}}
                                                                                disabled={activityTranslatedByIA?.targets[key][translatable_field] ? false : true}
                                                                            >
                                                                                <StyledFontAwesomeIcon size='1x'
                                                                                    icon={faArrowDown} 
                                                                                    title={"Use this translation."}
                                                                                />
                                                                            </XButton>
                                                                            <XButton
                                                                                onClick={() => requestNewTranslation(translatableField, key)} 
                                                                                size='small' style={{minWidth: 'unset', height:'100%', marginRight:'1em'}}
                                                                                disabled={iaLoadingSuggestions}
                                                                                >
                                                                                <StyledFontAwesomeIcon size='1x'
                                                                                    icon={faArrowsRotate} 
                                                                                    title={"Request a different translation."}
                                                                                    />
                                                                            </XButton>
                                                                        </>
                                                                    }
                                                                </StyledDivCenterY>}


                                                                {/* WORD COLLECTIONS */}
                                                                <StyledFeedbackContainer style={{flexDirection:'column', alignItems:'center'}}>
                                                                    <StyledDivCenterY style={{width:'100%'}}>
                                                                    <XInput
                                                                        key={translatable_field === "accepted_values" ? `accepted_value_${target.order}` : `unaccepted_value_${target.order}`}
                                                                        value={translatable_field === "accepted_values" ? acceptedValue[key] : unacceptedValue[key] || ''}
                                                                        style={{ margin: '0 8px 0 8px' }}
                                                                        id={`id_accepted_value${target.order}`}
                                                                        size='small'
                                                                        fullWidth
                                                                        inputProps={{ maxLength: activityInfo?.matrix?.props?.size?.width ?? null }}
                                                                        onChange={(e) => handleArrayValues(key, e.target.value, translatable_field)}
                                                                        onKeyDown={(e) => handleKeyDown(e, translatable_field === "accepted_values" ? 'accepted_values' : 'unaccepted_values', key)}
                                                                    />
                                                                        <StyledGreenXButton onClick={(e) => handleEditTranslation(e.currentTarget.parentElement.querySelector('input').value, translatable_field === "accepted_values" ? 'accepted_values' : 'unaccepted_values', key, null, e)} title='Add word to the collection.'>
                                                                            <Plus size={20}/> 
                                                                        </StyledGreenXButton>
                                                                    </StyledDivCenterY>
                                                                
                                                                <StyledDivCenterY>
                                                                        {activityTranslated.targets[key][translatable_field === "accepted_values" ? "accepted_values" : "unaccepted_values"].map((value, valueIndex) => 
                                                                            <XTag
                                                                                key={translatable_field === "accepted_values" ? `correctWordCollectionOriginalTag_${value}` : `incorrectWordCollectionOriginalTag__${value}`}
                                                                                style={{ marginTop: '8px', color: 'grey', opacity: '0.75'}}
                                                                                status={translatable_field === "accepted_values" ? "correct" : "incorrect"}
                                                                                name={value}
                                                                                onClickRemove={() => removeValueFromArray(translatable_field === "accepted_values" ? 'accepted_values' : 'unaccepted_values', key, valueIndex)}
                                                                                isRemovable

                                                                            />   
                                                                        )}
                                                                </StyledDivCenterY>
                                                                </StyledFeedbackContainer>
                                                                </StyledMarginContent>
                                                            </StyledXCardOption>
                                                        </StyledTargetsContainer>
                                                    }
                                                </React.Fragment>
                                            )
                                        })}
                                    </StyledMarginContent>
                                </StyledXCardOption>
                            )}
                        </div>
                    ))}
                    </StyledMarginContent>
                </StyledActivityCreationXCard>
            )}
       </>
    );
}

export default TargetTranslations;
