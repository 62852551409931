export const hasDamLoaderPlugin = (plugins) => {
  if (typeof plugins === 'string' && plugins.includes('damloader')) {
    return true;
  }

  if (Array.isArray(plugins)) {
    for (let i = 0; i < plugins.length; i++) {
      if (plugins[i].includes('damloader')) {
        return true;
      }
    }
  }
  return false;
}

export const addDamLoaderPlugin = (handleAction, handleEditor) => {
  window.tinyMCE.PluginManager.add('damloader', function(editor, url) {
    // handleEditor(editor)
    editor.ui.registry.addButton('damloader', {
        icon: 'gallery',
        tooltip: 'Insert media',
        onAction: () => {
          handleEditor(editor)
          handleAction()
        }
    });
    /* Adds a menu item, which can then be included in any menu via the menu/menubar configuration */
    editor.ui.registry.addMenuItem('damloader', {
        text: 'XDAM',
        onAction:  () => {
          handleEditor(editor)
          handleAction()
        }
    });
    /* Return the metadata for the help plugin */
    return {
        getMetadata: function () {
            return  {
                name: 'XDam media uploader',
                url: 'https://ximdex.com'
            };
        }
    };
  });
}