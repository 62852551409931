import { createNewActivity, getActivityById, getActivityLanguageById, putActivityLanguage } from "../services/xevalApi.service"
import  XPopUp  from '@ximdex/xui-react/material/XPopUp/XPopUp';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { useNavigate } from 'react-router-dom';


export default function useDuplicateActivity () {
    const { showSpinner, hideSpinner } = useSpinner();
    const navigate = useNavigate()

    const handleCloneActivity = async (activityToDuplicate, langsToAdd, languageDefaultSelected) => {
        let newActivityTemplate = {}

        if(languageDefaultSelected && 
            (languageDefaultSelected !== activityToDuplicate.language_default)){
            newActivityTemplate = await getActivityLanguageById(activityToDuplicate.id,languageDefaultSelected).then(res => {
                let newData = {...res}
                newData.language_default = languageDefaultSelected
                newData["translatable_fields"] = ["name","title","text","options","targets","feedbacks"]
                return newData
            }).catch(err =>  console.log("Err while getting activity language",err));
        }else{
            newActivityTemplate = await getActivityInfo(activityToDuplicate.id)
        }
        
        const filteredOptions = newActivityTemplate?.options ? newActivityTemplate?.options.map((option) => {
            return { ...option, id: 0}
        }) : []

        const filteredTargets = newActivityTemplate?.targets ? newActivityTemplate?.targets.map((target) => {
            return { ...target, id: 0}
        }) : []

        newActivityTemplate.id = 0
        newActivityTemplate.name = `Copy: ${activityToDuplicate?.name || 'Activity #' + activityToDuplicate.id}`
        newActivityTemplate.assessments = []
        newActivityTemplate.status = "DRAFT"
        newActivityTemplate.options = filteredOptions
        newActivityTemplate.targets = filteredTargets
        newActivityTemplate.available_languages = []
        delete newActivityTemplate.current_language
        delete newActivityTemplate.custom_client_id
        delete newActivityTemplate.isCollapsed
        delete newActivityTemplate.criterion_id
        delete newActivityTemplate.created_at
        delete newActivityTemplate.updated_at

        const languageDefault = languageDefaultSelected ?? newActivityTemplate.language_default
        let langsToTranslate = []

        if(langsToAdd){
            langsToTranslate = langsToAdd.filter(lang => lang !== languageDefault) 
        }else{
            langsToTranslate = activityToDuplicate.available_languages.filter(lang => lang !== newActivityTemplate.language_default) 
        }

        try {
            await createActvitiy(newActivityTemplate, langsToTranslate, languageDefault, activityToDuplicate)
        }catch (error) {
            XPopUp({
                message:"There's been an error while duplicating this activity. Please, try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        }
    }

    const getTranslations = async (langsToTranslate, activityToDuplicate, languageDefaultSelected) => {

        let activityTranslations = []
        for (let index = 0; index < langsToTranslate.length; index++) {
            const lang = langsToTranslate[index];
            let activityTranslated = await getActivityLanguageById(activityToDuplicate.id,lang).then(res => {
                let newData = {...res}
                newData.available_languages = []
                newData["translatable_fields"] = ["name","title","text","options","targets","feedbacks"]
                return newData
            }).catch(err =>  console.log("Err while getting activity language",err));
            activityTranslations.push(activityTranslated)
        }

        if(languageDefaultSelected && (languageDefaultSelected !== activityToDuplicate.language_default)){
            let languageDefaultTemplate = await getActivityInfo(activityToDuplicate.id)
            languageDefaultTemplate.available_languages = []
            languageDefaultTemplate["translatable_fields"] = ["name","title","text","options","targets","feedbacks"]
            activityTranslations.push(languageDefaultTemplate)
        }

        return activityTranslations
    }

    const addTranslations = async (activityTranslations, data, defaultLang) => {
        for (let index = 0; index < activityTranslations.length; index++) {
            let activityNewLang = activityTranslations[index];
            const lang = activityNewLang.current_language
            activityNewLang.id = data.id
            activityNewLang.language_default = defaultLang
            activityNewLang.current_language = defaultLang
            activityNewLang.resp = data

            const filteredOptions = activityNewLang?.options ? activityNewLang?.options.map((option,index) => {
                return { ...option, id: data.options[index].id}
            }) : []

            const filteredTargets = activityNewLang?.targets ? activityNewLang?.targets.map((target,index) => {
                return { ...target, id: data.targets[index].id}
            }) : []

            activityNewLang.options = filteredOptions
            activityNewLang.targets = filteredTargets

            await putActivityLanguage(data.id, lang, activityNewLang)
            .then(data => {})
            .catch(error => {
                console.error('There has been an error while duplicating this activity. Try again later.', error)
            })
        }
           
    }

    const createActvitiy = async (newActivityTemplate, langsToTranslate, languageDefault, activityToDuplicate) =>{
            showSpinner()
            let activityTranslations = []
            if(langsToTranslate.length > 0){
                activityTranslations = await getTranslations(langsToTranslate, activityToDuplicate);
            }
            await createNewActivity(null, newActivityTemplate).then( async data => {
                if(activityTranslations.length > 0 ){
                    await addTranslations(activityTranslations,data, languageDefault)
                }
                XPopUp({
                    message:"Activity duplicated correctly.",
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
                navigate(`/edit-activity?activity-id=${data.id}&lang=${data.language_default}`)
            }).catch(err => {
                XPopUp({
                    message:"There's been an error. Please, try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }).finally(() => {
                hideSpinner()
            })
    }
    

    const getActivityInfo = async (activityId) => {
        return await getActivityById(activityId).then(data => {
            return data
        }).catch(err => {
            console.error('There has been an error while duplicating this activity. Try again later.', err)
        })
    }
   
    return {
        handleCloneActivity,
    }
}
