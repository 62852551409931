import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { StyledContainerPreviewSpinner, StyledFontAwesomeIcon, StyledGreenXButton, StyledXContainerPreview } from '../../Styled-Components'
import { XSpinner, XDropdown, XButton } from '@ximdex/xui-react/material'
import { XEVAL_URI_API, PREVIEW_CHANNELS, PREVIEW_THEME_TYPES, CURRENT_THEME } from '../../CONSTANTS'
import { RefreshCcw } from 'lucide-react'

function Preview({
    typeOfPreview,
    getUserId,
    userId,
}) {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    
    const [channel, setChannel] = useState('mobile')
    const [srcOfPreview, setSrcOfPreview] = useState(undefined)
    const [themeType, setThemeType] = useState(CURRENT_THEME)

    useEffect(() => {
        // if (userId) setSrcOfPreview(`http://localhost:3000/evaluation?${typeOfPreview}_user_id=${userId}&channel=${channel}&target=${process.env.REACT_APP_CLIENT.toLowerCase()}${typeOfPreview === 'activity' ? `&lang=${params.get('lang')}` : ''}&theme=${themeType?.value}`)
        if (userId) setSrcOfPreview(`${XEVAL_URI_API}/evaluation?${typeOfPreview}_user_id=${userId}&channel=${channel}&target=${process.env.REACT_APP_CLIENT.toLowerCase()}${typeOfPreview === 'activity' ? `&lang=${params.get('lang')}` : ''}&theme=${themeType?.value}`)        
    }, [userId, channel, params.get('lang'), themeType])

    const handleChangeChannel = (device) => {
        setChannel(device)
    }

    const refreshPreview = () => {
        getUserId(params.get('activity-id'))
    }
    return (
    <StyledXContainerPreview channel={channel}>
        {(srcOfPreview) ? (
            <>
                <div style={{ width: '100%', padding: '1rem 0', display: 'grid', gridTemplateColumns: 'auto auto 2.5rem' , gridGap: '1rem'}}>
                    <XDropdown 
                        options={PREVIEW_CHANNELS}
                        labelOptions='label'
                        label='Select channel'
                        value={PREVIEW_CHANNELS.filter(prev_channel => prev_channel.value === channel)[0]}
                        onChange={(e, selectedInput) => handleChangeChannel(selectedInput?.value ?? '')}
                        width='100%'
                        hasCheckboxes={false}
                        multiple={false}
                        disableClearable
                        size="small"
                    />
                    
                    <XDropdown 
                        options={PREVIEW_THEME_TYPES}
                        labelOptions='label'
                        label='Select theme'
                        value={PREVIEW_THEME_TYPES.filter(prev_theme => prev_theme.value === themeType.value)[0]}
                        onChange={(e, selectedInput) => setThemeType(PREVIEW_THEME_TYPES[e.target.dataset.optionIndex])}
                        width='100%'
                        hasCheckboxes={false}
                        multiple={false}
                        disableClearable
                        disabled={PREVIEW_THEME_TYPES.length === 1}
                        size="small"
                    />
                    
                    <StyledGreenXButton onClick={refreshPreview} style={{ width: '100%', marginLeft: '-5px'}} title='Refresh preview.'>
                        <RefreshCcw size={20}/>
                    </StyledGreenXButton>
                </div>
                <iframe
                    src={srcOfPreview}
                    title="preview"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    id="iframePreview"
                    style={{ border: '1px solid black', marginLeft: '-1px' }}
                />
            </>
        ) : (
            <StyledContainerPreviewSpinner>
                <XSpinner
                    width="75px"
                    border="15px"
                    color="#43a1a2"
                    backgroundColor="#f2f2f2"
                />
            </StyledContainerPreviewSpinner>
        )}
    </StyledXContainerPreview>
  )
}

export default Preview
