import { TINY_MATH_PLUGINS } from '../../CONSTANTS';
import { contentCss, contentUiCss }  from './styles'
import $ from 'jquery';
// Load wiris formula render script.
const jsDemoImagesTransform = document.createElement('script');
jsDemoImagesTransform.type = 'text/javascript';
jsDemoImagesTransform.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
document.head.appendChild(jsDemoImagesTransform);
// This needs to be included before the '@wiris/mathtype-tinymce6' is loaded synchronously
window.$ = $;
window.tinymce = require('tinymce');  // Expose the TinyMCE to the window.
// Load wiris plugin synchronously.
require('@wiris/mathtype-tinymce6');


const DEFAULT_CONFIG_TINYMCE = {
    menubar: false,
    license_key: 'gpl',
    max_num_characters: 100,
    // paste_as_text: true,
    paste_remove_styles_if_webkit: false,
    skin: false,
    content_css: false,
    content_style: [contentCss, contentUiCss].join('\n'),
    height: '15em',
    contextmenu_never_use_native: true,
    draggable_modal: true,
    contextmenu: 'cut copy paste autolink link image',
    plugins: 'advlist autolink link image lists media table wordcount charmap codesample fontselect damloader FMathEditor tiny_mce_wiris_formulaEditor',
    toolbar: [`undo redo bold italic forecolor backcolor fontsizeselect alignleft aligncenter alignright alignjustify bullist numlist outdent indent removeformat`,
     `link damloader list table charmap ${TINY_MATH_PLUGINS?.replace(',',' ') ?? 'FMathEditorButton'} `],
    fontsize_formats: "8pt 10pt 12pt 14pt 18pt",
    font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Cursive=cursive; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;",
    statusbar: [
        "wordcount.characters"
    ],
    external_plugins: {
        // mathjax: '/vendor/@dimakorotkov/tinymce-mathjax/plugin.min.js',
        tiny_mce_wiris: `/vendor/mathtype-tinymce6/plugin.min.js`,
        FMathEditor: '/vendor/FMathEditor/plugin.js',
    },
    htmlAllowedTags:  ['.*'],
    htmlAllowedAttrs: ['.*'],
    extended_valid_elements: '*[.*]',
  // mathjax: {
  //     lib: '/vendor/mathjax/es5/tex-mml-chtml.js'
  // },
  init_instance_callback: function (editor) {
    editor.on('init', function() {
        var wordcountButton = editor.getContainer().querySelector('.tox-statusbar__wordcount');
        if (wordcountButton) {
          wordcountButton.click(); // Simula un clic en el botón
          wordcountButton.disabled = true; // Desactiva el botón
        } else {
          console.error('Wordcount button not found');
        }
      });
  },
  setup: function(editor) {
        editor.on('input SetContent', (event) => {
            var maxNumChars = editor.getParam('max_num_characters');
            var numChars = editor.plugins.wordcount.body.getCharacterCount();
    
            var charactersStatusBar = editor.getContainer().querySelector('button.tox-statusbar__wordcount');
    
            if (numChars >= maxNumChars && charactersStatusBar.style.color !== 'red') {
            charactersStatusBar.style.color = 'red';
            }
            if (numChars < maxNumChars && charactersStatusBar.style.color !== 'black') {
            charactersStatusBar.style.color = 'black'
            }
        })

        editor.on('KeyDown',  (event) => {
            var content = editor?.getContent({ format: 'text' });
            var maxChars = editor.getParam('max_num_characters');
            if(content.length >= maxChars && event.keyCode !== 8){
                // Detener la propagación del evento y evitar que se siga escribiendo
                    event.preventDefault();
                    event.stopPropagation();
            }
        });
    }
}

function getConfigTinyMCE(config = {}) {
  let output = {
    ...DEFAULT_CONFIG_TINYMCE,
    ...config
  };

  if (config.plugins) {
    output.plugins = [
      ...DEFAULT_CONFIG_TINYMCE.plugins,
      ...config.plugins
    ]
  }

  if (config.external_plugins) {
    output.external_plugins = [
      ...DEFAULT_CONFIG_TINYMCE.external_plugins,
      ...config.external_plugins
    ]
  }

  if (config.toolbar) {
    output.toolbar = [
      ...DEFAULT_CONFIG_TINYMCE.toolbar,
      ...config.toolbar
    ]
  }

  if (config.fontselect_formats) {
    output.fontselect_formats = [
      ...DEFAULT_CONFIG_TINYMCE.fontselect_formats,
      ...config.fontselect_formats
    ]
  }

  if (config.content_style) {
    output.content_style += `\n ${config.content_style}`
  }
  return output
}

export { getConfigTinyMCE };
