import React, { useState } from 'react'
import { 
    StyledCenteredXYDiv,
    StyledPageStatusHeader,
    StyledPageStatusImg,
} from '../../Styled-Components'

const Maintenance = ({error}) => {
    const [showError, setShowError] = useState(false)
    const defaultMessageLine1 = 'This website is currently undergoing scheduled maintenance.'
    const defaultMessageLine2 = 'It should be operational shortly.'
    
    const handleShowError = () => setShowError(!showError)

    return (
        <StyledCenteredXYDiv>
            <StyledPageStatusImg src={"/assets/images/undraw-fitting-piece.svg"} />  
            <StyledPageStatusHeader>Website under maintenance</StyledPageStatusHeader>
            <div
                style={{
                    height: showError ? 'initial' : 0,
                    width: '50vw',
                    borderBottom: showError ? '1px solid #cdcdcd' : 'none',
                    borderLeft: showError ? '1px solid #cdcdcd' : 'none',
                    borderRight: showError ? '1px solid #cdcdcd' : 'none',
                    backgroundColor: '#fefefe',
                    borderTop: '5px solid #3d9293',
                    marginBottom: 20
                }}
                onClick={handleShowError}
            >
                {showError && (
                    <>
                        <h3>{error.message}</h3>
                        <pre
                            style={{
                                padding: 10,
                                textAlign: 'left'
                            }}
                        >{error.stack}</pre>
                    </>
                )}

            </div>
            <p style={{ margin: '0'}}>{defaultMessageLine1}</p>
            <p>{process.env.REACT_APP_MAINTENANCE_MESSAGE || defaultMessageLine2}</p>
        </StyledCenteredXYDiv>
    )
}

export default Maintenance