import React, {useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { XContainer, XLogin } from '@ximdex/xui-react/material';
import useAuth from '../../hooks/useAuth';
import { loginXDAM } from '../../services/xevalApi.service';
import { COOKIE_NAME } from '../../CONSTANTS';
import { StyledXLogin } from '../../Styled-Components/Container';

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { isAuth, getCookie, setCookie } = useAuth();

    const navigateToPage = () => {
        const url = location?.state ? location.state.from?.pathname+location?.state?.from?.search : '/list-activities'
        navigate(url);
    }
    
    useEffect(() => {
        const userData = getCookie(COOKIE_NAME);

        if (userData) {
            const isStudent = userData?.user?.roles?.some((role) => role?.title === 'Student') ?? true;

            if (isStudent && userData?.user?.roles?.length < 2) {
                return;
            } else {
                sessionStorage.setItem(`${COOKIE_NAME}`, JSON.stringify({
                    ...userData.data, 
                    is_connected: true,
                    xdam_token: userData?.data?.access_token ?? null
                }));
                navigateToPage();
            };
        } 
    }, []);

    const handleLogin = async (email, password) => {
        try {
            const userData = await loginXDAM(email, password);
            if (userData?.code === 200) {
                const isStudent = userData?.user?.roles?.some((role) => role?.title === 'Student') ?? true;

                if (isStudent && userData?.user?.roles?.length < 2) {
                    return { error: 'Students are unauthorized from using this tool.'};
                }

                sessionStorage.setItem(`${COOKIE_NAME}`, JSON.stringify({
                    ...userData.data, 
                    is_connected: true,
                    xdam_token: userData?.data?.access_token ?? null
                }));
                setCookie(COOKIE_NAME, userData);
                navigate('/list-activities');
            };
            return {error: 'Check your credentials and try again later.'};
        } catch(error) {
            console.error(error);
            return {error: 'Check your credentials and try again later.'};
        };
    };

    return (
        <XContainer
            style={{
                width: "100%",
                minHeight: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StyledXLogin 
                hasLogo 
                srcLogo="/assets/logotipo_ximdex-EVAL-small.png"
                handleLogin={handleLogin}
            />
        </XContainer>
    )
}

export default Login
