import React from 'react';
import { StyledLink } from '../../Styled-Components/Links';
import { StyledDivCenterY, StyledHeaderListDiv, StyledXInput } from '../../Styled-Components';
import { XButton } from '@ximdex/xui-react/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { FileText, Files, Plus } from 'lucide-react';

const ActivitesListHeader = ({
    textBeforeSearch,
    setTextBeforeSearch,
    handleKeyPressSearch,
    title="Activities"
}) => {
    return (
        <StyledHeaderListDiv>
            <StyledDivCenterY>
                <FileText size={40} style={{marginRight: '10px', color:'#214F61'}}/>
                <h1>{title}</h1>
            </StyledDivCenterY>
            <StyledXInput
                value={textBeforeSearch}
                onChange={(e) => setTextBeforeSearch(e.target.value)}
                onKeyDown={(e) => handleKeyPressSearch(e)}
                type='search'
                size="small"
                placeholder='Search in activity list'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <StyledLink to={'/create-activity'}>
                <XButton>
                    <Plus size={20} style={{marginRight: '8px'}}/>
                    new activity
                </XButton>
            </StyledLink>
        </StyledHeaderListDiv>
    );
}

export default ActivitesListHeader;
