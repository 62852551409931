import React from 'react';
import { XLabel } from '@ximdex/xui-react/material';
import { StyledDivFlexAroundWrap, StyledDivFlexWrap, StyledHomeXBox } from '../../Styled-Components/index';
import { StyledHomeItem } from '../../Styled-Components/Links';
import { BookOpenText, BookPlus, ClipboardList, ClipboardPlus, FilePlus, FileText, Files } from 'lucide-react';
import { PoweredBy } from '../../components/PoweredBy/PoweredBy';


const Home = (props) => {
    const activityElements = [
        {
            icon: <FilePlus size={80}/>,
            id: 'create-activity',
            name: 'Create new activity'
        },
        {
            icon: <FileText size={80}/>,
            id: 'list-activities',
            name: 'List activities'
        }
    ];
    const assessmentElements = [
        {
            icon: <ClipboardPlus size={80}/>,
            id: 'create-assessment',
            name: 'Create assessment'
        },
        {
            icon: <ClipboardList size={80}/>,
            id: 'list-assessments',
            name: 'List assessments'
        }
    ];
    return (
        <>
            <StyledDivFlexWrap style={{width: '100%', height:'auto', justifyContent:'center', alignItems:'center', height: '90%'}}>
                <StyledDivFlexAroundWrap >
                    {activityElements.map((element, index) => (
                        <StyledHomeItem
                            key={'regularElement' + index}
                            to={'/' + element.id}
                            style={{ color: '#214F61' }}>
                            <XLabel
                                style={{ width: '100%' }}
                                label={element.name}
                                paddingSize='s'
                                component=
                                {<StyledHomeXBox 
                                    width={'200'}
                                    minHeigthContent={'200'}
                                    id={element.id} className="xboxItem"
                                >
                                    {element.icon}
                                    {/* <FontAwesomeIcon icon={element.icon} size='6x' /> */}
                                </StyledHomeXBox>}
                                htmlFor={element.name}
                                labelLocation='bottom'
                            />
                        </StyledHomeItem>
                    ))}

                </StyledDivFlexAroundWrap>
                <StyledDivFlexAroundWrap>
                    {assessmentElements.map((element, index) => (
                        <StyledHomeItem
                            key={'solidElement' + index}
                            to={'/' + element.id}
                            style={{ color: '#214F61' }}>
                            <XLabel
                                label={element.name}
                                isCentered
                                paddingSize='s'
                                component=
                                {<StyledHomeXBox 
                                    width={'200'}
                                    minHeigthContent={'200'}
                                    id={element.id} className="xboxItem"
                                >
                                    {element.icon}
                                </StyledHomeXBox>}
                                htmlFor={element.name}
                                labelLocation='bottom'
                            />
                        </StyledHomeItem>
                    ))}
                </StyledDivFlexAroundWrap>
            </StyledDivFlexWrap>
            <PoweredBy />
        </>
    )
}

export default Home;
