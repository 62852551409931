import React from 'react';
import { XInput } from '@ximdex/xui-react/material';
import { ExtraInfoText, StyledActivityCreationXCard, StyledMarginContent } from '../../../Styled-Components';
import useMaxCharacters from '../../../hooks/useMaxCharacters';

const ActivityHeader = ({
    activityTitle,
    editActivityInfo,
    activityInfo
}) => {
    const {getMaxCharHeader} = useMaxCharacters()
    const maxChar = getMaxCharHeader()

    return (
        <StyledActivityCreationXCard
            isCollapsable={false}
            isCollapsed={false}
            // backgroundHeader={true}
            // title='Header'
            title={`Editing default language: ${activityInfo?.language_default}`}

        >
            <StyledMarginContent>
            <XInput
                style={{ width: '100%' }}
                id='title'
                size='small'
                fullWidth
                multiline
                rows={4}
                value={activityTitle}
                onChange={(e) => editActivityInfo(e)}
                inputProps={{ maxLength: maxChar }}
            />
            <ExtraInfoText>{`${activityTitle?.length || 0} of ${maxChar} characters`}</ExtraInfoText>
            </StyledMarginContent>
        </StyledActivityCreationXCard>
    )
}

export default ActivityHeader;
