import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from '../pages/Home/Home';
import ActivitySelector from '../pages/ActivitySelector/ActivitySelector';
import ActivityCreation from '../pages/ActivityCreation/ActivityCreation';
import AssessmentCreation from '../pages/AssessmentCreation/AssessmentCreation';
import AssessmentsList from '../pages/AssessmentsList/AssessmentsList';
import ActivitiesList from '../pages/ActivitiesList/ActivitiesList';
import AssessmentSelector from '../pages/AssessmentSelector/AssessmentSelector';
import NotFound from '../pages/Fallback/NotFound';
import Login from '../pages/Login/Login';

const Routes = () => {
    return (
        <ReactRoutes >
            <Route path="/login" element={<Login />} />
            <Route
                exact path={'/'}
                element={<PrivateRoute component={<Home />} />} />
            <Route
                path={'/list-assessments'}
                element={<PrivateRoute component={<AssessmentsList />} />} />
            <Route
                path={'/create-assessment'}
                element={<PrivateRoute component={<AssessmentSelector />} />} />
            <Route
                path={'/duplicate-assessment'}
                element={<PrivateRoute component={<AssessmentSelector />} />} />
            <Route
                path={'/edit-assessment'}
                element={<PrivateRoute component={<AssessmentCreation />} />} />
            <Route
                path={'/list-activities'}
                element={<PrivateRoute component={<ActivitiesList />} />} />
            <Route
                path={'/create-activity'}
                element={<PrivateRoute component={<ActivitySelector />} />} />
            <Route
                path={'/duplicate-activity'}
                element={<PrivateRoute component={<ActivitySelector />} />} />
            <Route path={'/edit-activity'}
                element={<PrivateRoute component={<ActivityCreation />} />} />
            <Route path="*" element={<NotFound />} />
        </ReactRoutes>
    )
};

export default Routes;
