import React, { useState } from 'react';
import { StyledDivCenterY, StyledFontAwesomeIcon, StyledGreenXButton, StyledHeaderContainer, StyledLangLi, StyledMarginContent, StyledRedXButton, StyledXOwlWhiteIcon } from '../../Styled-Components';
import { ACTIVITY_TRANSLATION_AI, AVAILABLE_TRANSLATION_LANGUAGES_BY_AI } from '../../CONSTANTS';
import { XButton, XDropdown, XLabel, XPopUp } from '@ximdex/xui-react/material';
import { faAddressBook, faArrowDown, faArrowLeft, faBookOpen, faBookmark, faSwatchbook, faXmark } from '@fortawesome/free-solid-svg-icons';
import { deleteTranslation, putActivityLanguage } from '../../services/xevalApi.service';
import useModals from '../../hooks/useSweetAlert';
import { useSpinner } from '@ximdex/xui-react/hooks';
import useTranslations from '../../hooks/useTranslations';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeedbackTranslations from './ActivityTranslations/FeedbackTranslations';
import { faContactBook } from '@fortawesome/free-regular-svg-icons';
import { ArrowDown, ArrowLeft, BookText, Save, Trash, X } from 'lucide-react';

const ActivityHeaderCreation = ({
    activityInfo,
    languageSelected,
    setIsActivityTranslationUpdated,
    setLanguageSelected,
    activityTranslation,
    saveActivity,
    reload,
    setReload,
    activityTemplateTranslation,
    setActivityTemplateTranslation,
    assessmentId,
    activityTranslatedByIA,
    iaLoadingSuggestions,
    iaButtonDisabled,
    isActivityTranslationUpdated,
    isActivityInfoUpdated,
    languagesAvaliables,
    setAddNewTranslation,
    addNewTranslation,
    setNewLanguageSelected,
    languagesPendingToAdd,
    charCount
}) => {
    const { modal } = useModals();
    const { showSpinner, hideSpinner } = useSpinner();
    const { checkEmptyInputs } = useTranslations();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const deleteTranslationModal = (id,dataType,lang) => {
        modal(`Are you sure you want to delete the ${lang} translation of the activity with id ${id}?`, 'Delete translation', () => deleteActivityTranslation(id,dataType,lang))
    }

    const deleteActivityTranslation = async (id,dataType,lang) => {
        showSpinner()
        await deleteTranslation(id,dataType,lang)
        .then(res => {
            if (res?.status === 200 || res?.statusText === 'ok') {
                setReload(!reload)
                setLanguageSelected(languagesAvaliables.filter(lang => lang.value === activityInfo.language_default)[0])
                localStorage.setItem("create-translation", JSON.stringify({
                    lang: ''
                }))
                XPopUp({
                    message:"Translation successfully deleted",
                    iconType:'success',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'ligthgreen',
                })
            } else {
                XPopUp({
                    message:"Looks like an error may have occured while deleting this translation. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }
        })
        .catch(error => {
            console.error('There has been an error while deleting this translation. Try again later.', error)
            XPopUp({
                message:"Looks like an error may have occured while deleting this translation. Try again later.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
        })
        .finally(() => {
            hideSpinner();
        });
    };

    const handleCancelTranslation = () => {
        modal(`Are you sure you want to cancel this translation?`, "Cancel translation", () => {
            localStorage.setItem("create-translation", JSON.stringify({
                lang: ''
            }))
            setLanguageSelected(languagesAvaliables.filter(lang => lang.value === activityInfo.language_default)[0])
            setNewLanguageSelected(undefined)
            setIsActivityTranslationUpdated(true)
            setAddNewTranslation(false)
        })
    }

    const saveActivityTranslate = async () => {
        if (charCount && charCount.some(char => char.exceed)) {
            XPopUp({
                message:"You have exceeded the allowed character limit.",
                iconType:'error',
                timer:'3000',
                popUpPosition:'top',
                iconColor: 'red',
            })
            return
        }else{
            showSpinner()
            await putActivityLanguage(activityInfo.id, params.get('lang'), activityTranslation).then(data => {
                if (data?.id) {
                    XPopUp({
                        message:"Translation saved successfully",
                        iconType:'success',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'ligthgreen',
                    })
                } else {
                    XPopUp({
                        message:"Looks like an error may have occured while updating this activity's translation. Try again later.",
                        iconType:'error',
                        timer:'3000',
                        popUpPosition:'top',
                        iconColor: 'red',
                    })
                }
            }).catch(error => {
                console.error('There has been an error while updating this translation. Try again later.', error)
                XPopUp({
                    message:"Looks like an error may have occured while updating this activity's translation. Try again later.",
                    iconType:'error',
                    timer:'3000',
                    popUpPosition:'top',
                    iconColor: 'red',
                })
            }).finally(() => {
                hideSpinner();
            });
            setIsActivityTranslationUpdated(true)
            localStorage.setItem("create-translation", JSON.stringify({
                lang: ''
            }))
            setReload(!reload)
            setAddNewTranslation(false)
            setNewLanguageSelected(undefined)
        }
        
    }

    const addNewActivityLanguage = () => {
        const emptyInputs = checkEmptyInputs(activityInfo, activityTemplateTranslation)
        if(emptyInputs.strings || emptyInputs.options || emptyInputs.feedbacks || emptyInputs.targets) {
            modal(`It seems you have incomplete content. Do you want to proceed anyway?`, "Missing content", () => saveActivityTranslate())
        }else{
            saveActivityTranslate()
        }
    }

    const IATranslationModal = () => {
        modal(`Are you sure you want to apply all the suggested translations?`, "Translate activity", () => {
            setActivityTemplateTranslation(activityTranslatedByIA)
            setIsActivityTranslationUpdated(false)
        })
    }

    const handleLanguagesNav = (e) => {
        let language = languagesAvaliables[e]
        setLanguageSelected(language)
    }

    const handleNewLanguageDropdown = (e) => {
        let language = languagesPendingToAdd[e]
        localStorage.setItem("create-translation", JSON.stringify({
            lang: language.value
        }))
        setNewLanguageSelected(language)
    }

    const CancelTranslationButton = () => {
        return (
            <>
                {addNewTranslation &&
                    <StyledGreenXButton
                        onClick={handleCancelTranslation} 
                        size='small' style={{minWidth: 'unset', height:'40px'}}
                        title={"Cancel translation"}
                    >
                        <X size={20}/>
                    </StyledGreenXButton>
                }
            </>
        )
    }
    const GoBackToAssessmentButton = () => {

        return (
            <>
                <>
                    {assessmentId && 
                        <StyledGreenXButton
                            onClick={() => navigate(`/edit-assessment?assessment-id=${assessmentId}`)} 
                            size='small' style={{minWidth: 'unset', height:'40px', marginRight:'1em'}}
                            title='Go back to the assessment.'
                        >
                            <ArrowLeft size={20}/>
                        </StyledGreenXButton>
                    }
                </>
            </>
        )
    }
    const IaTranslationButton = () => {
        return(
            <>
                {((ACTIVITY_TRANSLATION_AI && AVAILABLE_TRANSLATION_LANGUAGES_BY_AI.includes(params.get('lang'))) &&
                (activityInfo?.language_default !== params.get('lang'))) && 
                    <StyledGreenXButton
                        onClick={IATranslationModal}
                        size='small' style={{minWidth: 'unset', height:'40px', marginRight: '8px'}}
                        disabled={iaLoadingSuggestions || iaButtonDisabled}
                        title="Apply all translations recommended by XOwl"
                    >   
                        <StyledXOwlWhiteIcon src="./assets/images/xowl-logo4.png" />
                        <ArrowDown size={20} style={{marginLeft: '8px'}} />
                    </StyledGreenXButton>
                }
            </>
        )
    }
    const DeleteTranslationButton = () => {
        return (
            <>
                {languageSelected.value !== activityInfo.language_default && 
                !addNewTranslation &&
                    <StyledRedXButton
                        onClick={() => deleteTranslationModal(activityInfo.id,'ACTIVITY', languageSelected.value)} 
                        size='small' style={{minWidth: 'unset', height:'40px'}}
                        title={`Delete ${languageSelected.label} translation.`}
                    >
                        <Trash size={20}/>
                    </StyledRedXButton>
                }
            </>
        )
    }
    const SaveButton = () => {
        return(
            <>
                {languageSelected.value !== activityInfo?.language_default || 
                addNewTranslation 
                ?
                    <XButton 
                        onClick={() => addNewActivityLanguage()} 
                        size='small' style={{minWidth: 'unset', height:'40px'}}
                        disabled={isActivityTranslationUpdated}
                        title={'Save language'}
                    >
                        <Save 
                            size={20}
                            style={{marginRight: '8px'}}
                        />
                        Save
                    </XButton>
                :
                    <XButton
                        onClick={() => saveActivity()} 
                        size='small' style={{minWidth: 'unset', height: '40px'}}
                        disabled={isActivityInfoUpdated}
                        title={isActivityInfoUpdated ? 'Activity is upToDate' : 'Save activity'}
                    >
                        <Save 
                            size={20}
                            style={{marginRight: '8px'}}
                        />
                        Save
                    </XButton>
                }
            </>
        )
    }
    const AddTranslationButton = () => {

        return (
            <>
                {!addNewTranslation && <XButton 
                    style={{marginLeft: '8px', borderRadius: '6px'}}
                    title="Add new translation"
                    onClick={() => setAddNewTranslation("true")}
                    >
                    <BookText size={20} style={{marginRight: '8px'}}/>
                    Add translation
                </XButton>}
            </>
        )
    }

    return (
    <StyledHeaderContainer
        title={`${activityInfo?.type?.replace(/-/g, ' ').toUpperCase()} EDITOR`}
        controls={[
            {
                component: <GoBackToAssessmentButton/>
            },
            {
                component: <SaveButton/>
            }
        ]}
    >
        <StyledMarginContent style={{margin:'1em 1em 0 1em', paddingBottom:'0.5em'}}>
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between' }}>
            <StyledDivCenterY style={{justifyContent:'flex-start', flexDirection:'row', alignItems:'center'}}>
                {!addNewTranslation ? 
                <>
                    <p style={{margin: '10px 0 10px 0', fontWeight:'500', fontSize: '18px'}}>Select language:</p>
                    <ul style={{ listStyle: 'none', display: 'flex', padding: '0'}}>
                        {languagesAvaliables.map((lang, index) => 
                            <StyledLangLi
                            iselected={languageSelected.value === lang.value}
                            isdefault={activityInfo.language_default === lang.value}
                            key={'regularElement' + index}
                            style={{ color: '#214F61' }}
                            onClick={() => handleLanguagesNav(index)}
                            >
                                {lang.label}
                            </StyledLangLi>)
                        }
                    </ul>
                </>
                :
                    <>
                        <p style={{margin: '10px 0 10px 0'}}>Create language:</p>
                        {languagesPendingToAdd.length > 0 ? <>
                            <XDropdown
                                style={{margin:'0 1em 0 1em'}}
                                onChange={(e) => handleNewLanguageDropdown(e.target.dataset.optionIndex)}
                                options={languagesPendingToAdd}
                                labelOptions='label'
                                label='Select new language'
                                width='200px'
                                hasCheckboxes={false}
                                multiple={false}
                                disableClearable
                                size="small"
                                />
                            </>
                            :
                            <p>0 languages available to translate to.</p>
                        }
                    </>
                }
                
            </StyledDivCenterY>
            {/* IA ACTIONS BUTTONS */}
            <div>
                <IaTranslationButton/>
                <AddTranslationButton/>
                <CancelTranslationButton/>
                <DeleteTranslationButton/>
            </div>
        </div>
        </StyledMarginContent>
    </StyledHeaderContainer>
    );
}

export default ActivityHeaderCreation;
